
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.front_royal_form = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.front_royal_form, ...{
    "choose_your_top_n": "choose your top {{n}}",
    "choose_up_to_n": "choose up to {{n}}"
}
}
    