import cacheAngularTemplate from 'cacheAngularTemplate';

import arrowUpBeigeRounded from 'vectors/arrow_up_beige_rounded.svg';
import arrowDownBeigeRounded from 'vectors/arrow_down_beige_rounded.svg';

import dropdownHelper from 'DropdownHelper';
import angularModule from '../front_royal_form_module';
import template from '../../views/inputs/multi_select.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('multiSelect', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const $timeout = $injector.get('$timeout');
        const md5 = $injector.get('md5');
        const HasLocation = $injector.get('HasLocation');
        const $translate = $injector.get('$translate');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                placeholderText: '<?',
                min: '<?',
                max: '<?',
                showEmpty: '<?',
                allowCreate: '<?',
                inputType: '@?',
                wrap: '<?',
                /*
                        Determines what happens when an item is selected.
                        Valid behaviors are 'remove' and 'disable'.
                        'remove' removes the item from the available options (default)
                        'disable' leaves the item in the available options but disables it.
                        Note that 'disable' is currently only supported for <select>.
                        Selectize support would require a custom view for the options.
                    */
                selectedBehavior: '<?',
                /*
                        Determines how the selected items should be displayed.
                        Valid display modes are 'itemView' and 'tableView'.
                        'itemView' displays the selected items as a queue of entries
                        with labels. 'tableView' displays the selected items in
                        a sortable table.
                    */
                displayMode: '<?',

                /*
                        Required if displayMode is 'tableView'. Should be an array
                        of objects where each element corresponds with a table column.
                    */
                columns: '<?',
                options: '<',
                skipModelOrdering: '<?',
                disableOrdering: '<',
                defaultOrdering: '<?',
                sortDirection: '<?',
                optionValue: '&',
                optionLabel: '&',
                optionDisabled: '&',
                modelOrderBy: '&',
                editOption: '&',
                disableRemove: '&',
                optionOrderBy: '&',
                displaySelectedOptions: '<?',
                shouldDisable: '<?',
                sorted: '<?',
                grouped: '<?',
            },
            require: '?^ngModel',
            templateUrl,

            link(scope, elem, attrs, modelController) {
                scope.arrowUpBeigeRounded = arrowUpBeigeRounded;
                scope.arrowDownBeigeRounded = arrowDownBeigeRounded;

                // set the display mode viewing selected items (default is 'itemView')
                scope.displayMode = scope.displayMode || 'itemView';

                // in some cases, options are displayed elsewhere
                scope.displaySelectedOptions = angular.isDefined(scope.displaySelectedOptions)
                    ? scope.displaySelectedOptions
                    : true;

                // set the selectedBehavior (default is 'remove')
                scope.selectedBehavior = scope.selectedBehavior || 'remove';

                scope.optionDisabled = scope.optionDisabled || (() => false);

                if (scope.displayMode === 'tableView') {
                    $injector.get('HasSortableColumnsMixin').onLink(scope);
                    scope.sortDirection = angular.isUndefined(scope.sortDirection) ? true : scope.sortDirection;
                    scope.sort = {
                        column: scope.defaultOrdering || '',
                        descending: scope.sortDirection,
                    };
                }
                scope.convertOptionToValue = option =>
                    scope.optionValue({
                        $option: option,
                    });

                scope.convertOptionToLabel = (option, listItem) => {
                    // Not sure why we need the null check
                    // https://sentry.io/pedago/front-royal/issues/678665131/?query=is:unresolved
                    if (!option) {
                        return '';
                    }
                    return scope.optionLabel({
                        $option: option,
                        $listItem: listItem,
                    });
                };

                scope.convertOptionToSelectedLabel = option => {
                    if (!option) return '';

                    const optionsWithOutGrouping = scope.grouped
                        ? scope.availableOptionsMap?.flatMap(([_grouping, options]) => options)
                        : scope.availableOptionsMap;

                    return optionsWithOutGrouping?.find(opt => opt.value === option)?.selectedLabel ?? '';
                };

                let valueToOptionMap = {};
                const labelToOptionMap = {};
                let optionValues = [];
                let optionLabels = [];
                let userCreatedValues = [];

                scope.convertValueToLabel = (value, listItem, selected) => {
                    if (scope.inputType === 'location-autocomplete') {
                        return HasLocation.locationString(value);
                    }

                    if (selected) {
                        const selectedLabel = scope.convertOptionToSelectedLabel(value);
                        if (selectedLabel) return selectedLabel;
                    }

                    // If it's a known option value, look it up to get its label.
                    // Note: For objects we check a $$hashKey property that is set above
                    const hashKey = scope.convertValueToHashKey(value);
                    const optionValue = _.find(optionValues, opt => scope.convertValueToHashKey(opt) === hashKey);
                    if (optionValue) {
                        return scope.convertOptionToLabel(convertValueToOption(value), listItem);
                    }
                    if (_.includes(userCreatedValues, value)) {
                        // else if it's a known user created value, it is its own label
                        return value;
                    }
                    // otherwise we're being asked to get a label for a value we've never seen before

                    // In the past, this happened when there was a mistake in the logic such that
                    // convertValueToLabel was called from the view for an option that had been removed
                    // from the ngModel and userCreatedValues, but wasn't yet removed from the view.
                    // Rather than throw an error, try to be resilient and return an empty value.
                    // throw new Error('Unknown value sent to convertValueToLabel: ' + value);
                    return undefined;
                };

                function convertValueToOption(value) {
                    const key = scope.convertValueToHashKey(value);
                    return valueToOptionMap[key];
                }

                scope.convertValueToHashKey = value => {
                    if (!value) return '';
                    if (typeof value === 'string' || typeof value === 'number') {
                        return value;
                    }

                    // Iguana objects with embedded things cannot be stringified
                    // due to circular reference errors, so we cannot allow them
                    // to fall through to the next block that uses md5.  Fortunately,
                    // they have ids.
                    if (value.id) {
                        return value.id;
                    }

                    // It is possible that an existing value could be equal but not
                    // identical to one of the options in the list. In that case, we need
                    // to reliably get the same hash key.

                    const hash = md5.createHash(JSON.stringify(value));
                    value.$$hashKey = value.$$hashKey || hash;
                    return value.$$hashKey;
                };

                function convertOptionToHashKey(option) {
                    const value = scope.convertOptionToValue(option);
                    return scope.convertValueToHashKey(value);
                }

                /*
                        If an already available option includes the hashKey, convert the existing
                        option into a value. Otherwise, return undefined.
                        @param hashKey - the value of the user created item
                        @return the value of the existing available option, otherwise undefined
                    */
                function convertHashKeyToValue(hashKey) {
                    const option = _.find(scope.availableOptions, opt => {
                        const hashKeyForOption = convertOptionToHashKey(opt);
                        return hashKeyForOption === hashKey;
                    });
                    return scope.convertOptionToValue(option);
                }

                function addOptionToMap(option) {
                    const value = scope.convertOptionToValue(option);
                    const key = scope.convertValueToHashKey(value);
                    const label = scope.convertOptionToLabel(option);

                    valueToOptionMap[key] = option;
                    labelToOptionMap[label] = option;

                    optionValues.push(value);
                    optionLabels.push(label);
                }

                function addValuesToUserCreatedValues(values) {
                    const remainingValues = _.difference(values, optionValues);
                    userCreatedValues = _.union(userCreatedValues, remainingValues);
                }

                function addOptionsToMap(options) {
                    valueToOptionMap = {};
                    optionValues = [];
                    optionLabels = [];
                    _.forEach(options || [], option => {
                        addOptionToMap(option);
                    });

                    addValuesToUserCreatedValues(scope.ngModel || []);
                }

                scope.$watchCollection('options', addOptionsToMap);
                scope.$watchGroup(['inputType', 'allowCreate'], () => {
                    // For now, allowCreate is synonymous with selectize
                    if (scope.allowCreate) {
                        scope.inputType = 'selectize';
                    } else if (!scope.inputType) {
                        scope.inputType = 'select';
                    }
                });

                // for the selected option value
                scope.proxy = {};

                // normalize model / requirements
                scope.ngModel = scope.ngModel || [];
                scope.min = scope.min || 0;

                // if we don't have a max, we can't show-empty
                scope.showEmpty = scope.max ? !!scope.showEmpty : false;

                // Add support for custom form validation and dirtying
                FormHelper.supportDirtyState(scope, modelController);
                FormHelper.supportCollectionSizeValidation(scope, attrs, modelController);
                FormHelper.supportsOrderableItems(scope);

                scope.$watch('proxy.itemToBeAdded', onItemAddCallback);
                scope.$watch('proxy.hashKeyToBeAdded', onHashKeyAddCallback);
                scope.$watchCollection('ngModel', addUnknownValue);
                scope.$watchCollection('ngModel', setAvailableOptions);
                scope.$watchCollection('ngModel', setSortedNgModel);
                scope.$watchCollection('options', setAvailableOptions);

                // if a new value is added to the ngModel from outside of this directive,
                // we need to treat it as though it's a new value added via selectize.
                // For example, in network-map-filter-box, you can click on a student_network_interest
                // that adds the clicked interest, but this might not be in the list of available options
                // if it had been a custom text entry
                function addUnknownValue(newList, oldList) {
                    const newValues = _.difference(newList, oldList);
                    const valuesToAdd = _.filter(newValues, value => !convertValueToOption(value));
                    addValuesToUserCreatedValues(valuesToAdd);
                }

                function setSortedNgModel() {
                    if (scope.skipModelOrdering || !scope.modelOrderBy) {
                        scope.sortedNgModel = scope.ngModel;
                    } else {
                        scope.sortedNgModel = _.sortBy(scope.ngModel, value => {
                            const option = convertValueToOption(value);
                            return scope.modelOrderBy({
                                $option: option,
                            });
                        });
                    }
                }

                function setAvailableSelectizeOptions() {
                    const options = _.map(scope.availableOptions, option => {
                        const entry = {};
                        entry.title = scope.convertOptionToLabel(option);
                        entry.hashKey = convertOptionToHashKey(option);
                        return entry;
                    }).sort((a, b) => a.title.localeCompare(b.title, $translate.use(false)));
                    scope.proxy.availableSelectizeOptions = options;
                }

                function setAvailableOptions() {
                    if (scope.selectedBehavior === 'remove') {
                        scope.availableOptions = _.filter(
                            scope.options,
                            option => !_.includes(scope.ngModel, scope.convertOptionToValue(option)),
                        );
                    } else {
                        scope.availableOptions = scope.options;
                    }

                    if (!scope.skipModelOrdering && scope.optionOrderBy) {
                        scope.availableOptions = _.sortBy(scope.availableOptions, option =>
                            scope.optionOrderBy({
                                option,
                            }),
                        );
                    }

                    const availableOptions = scope.grouped
                        ? scope.availableOptions.filter(Array.isArray).map(([label, options]) => [
                              label,
                              options.map(option => ({
                                  ...option,
                                  disabled: scope.ngModel.includes(option.value),
                              })),
                          ])
                        : scope.availableOptions.map(option => ({
                              value: scope.convertOptionToValue(option),
                              label: scope.convertOptionToLabel(option),
                              disabled:
                                  _.includes(scope.ngModel, scope.convertOptionToValue(option)) ||
                                  scope.optionDisabled({
                                      $option: option,
                                  }),
                          }));

                    if (scope.grouped) {
                        scope.unGroupedOptions = scope.availableOptions
                            .filter(v => !Array.isArray(v))
                            .map(option => ({
                                ...option,
                                disabled: scope.ngModel.includes(option.value),
                            }));
                    }

                    scope.availableOptionsMap = scope.sorted
                        ? availableOptions
                        : availableOptions.sort((a, b) => a.label.localeCompare(b.label, $translate.use(false)));

                    dropdownHelper.moveOtherToBottom(scope.availableOptionsMap);

                    if (scope.inputType === 'selectize' || scope.allowCreate) {
                        setAvailableSelectizeOptions();
                    }
                }

                //---------------------------
                // Input Handling
                //---------------------------

                function computeNumEmptyItems() {
                    if (!scope.showEmpty) {
                        return;
                    }
                    const empties = scope.max - scope.ngModel.length;
                    // We need this check because scope.ndModel.length can be > scope.max
                    // if database has more than max items
                    scope.numEmptyItems = empties >= 0 ? empties : 0;
                    // For the ng-repeat - http://stackoverflow.com/a/16824944/1747491
                    scope.numEmptyItemsArray = new Array(scope.numEmptyItems);
                }
                computeNumEmptyItems();

                function refreshSelectize() {
                    // no need to do this is if we're not in selectize mode.
                    if (scope.inputType !== 'selectize') {
                        return;
                    }

                    // regenerate available selectize options
                    setAvailableSelectizeOptions();

                    // Now the hack. Angular-selectize doesn't expose the API necessary to force a refresh
                    // and rebuild of the internal userOptions data where user-added items are stored.
                    // So, after tiny delay, hide and show the selectize to force a refresh of its userOptions.
                    //
                    // We have to delay here because some internal selectize triggers need a frame to fire
                    // otherwise, there's a non-user facing null error triggered by the onChange.
                    $timeout(() => {
                        // hide the selectize
                        scope.refreshSelectize = true;

                        // show the selectize after a tiny delay
                        $timeout(() => {
                            scope.refreshSelectize = false;
                        });
                    });
                }

                /*
                        Handles adding a value to the list of selected options.
                        @param addedValue - the value to be added to the list of selected options
                        @return undefined if addedValue param is not present or if one of the 6 cases
                        listed below occurs
                    */
                function onItemAddCallback(addedValue) {
                    function clearUIState() {
                        // clear out any lingering UI state
                        if (scope.inputType === 'select') {
                            elem.find('select').blur();
                        }

                        if (scope.inputType === 'selectize') {
                            refreshSelectize();
                        }

                        scope.proxy.hashKeyToBeAdded = undefined;
                        scope.proxy.itemToBeAdded = undefined;

                        // placeIdToBeAdded is used for nothing, since we only care
                        // about the place details.  But we have to set it to undefined to clear out the input
                        scope.proxy.placeIdToBeAdded = undefined;
                    }

                    // ignore undefined values
                    if (!addedValue) {
                        return;
                    }

                    // Checking for duplicates is tricky in a world where you can type your own options.
                    // Because user typed options act as a label and a value, we have to be careful to not allow
                    // user typed options into the ngModel that would be duplicates of the label or value of
                    // any of the selectable options.
                    //
                    // Cases to handle:
                    //  (1) Someone selects an option. This option was previously selected (hypothetical, shouldn't be possible).
                    //  (2) Someone selects an option. This option's label was already typed in manually.
                    //  (3) Someone selects an option. This option's value was already typed in manually.
                    //  (4) Someone types a value. This value was typed previously and is in the list.
                    //  (5) Someone types a value. This value matches the label of previously selected option.
                    //  (6) Someone types a value. This value matches the value of a previously selected option.
                    //
                    // When a duplicate is found, we need to clear out the UI state and manually refresh
                    // the selectize to ensure the entry doesn't stick around in its internal state.
                    const previouslySelectedLabels = _.map(scope.ngModel, scope.convertValueToLabel);
                    if (
                        _.includes(scope.ngModel, addedValue) || // (1), (6)
                        _.chain(scope.ngModel)
                            .map(val => scope.convertValueToHashKey(val))
                            .includes(scope.convertValueToHashKey(addedValue))
                            .value() || // (1) when addedValue is an object
                        _.includes(userCreatedValues, addedValue) || // (2), (3), (4)
                        _.includes(previouslySelectedLabels, addedValue)
                    ) {
                        // (5)
                        clearUIState();
                        return;
                    }

                    // There's one more tricky case we need to handle. A user can type in a value that matches the
                    // label of a option that has not yet been selected. Rather than create a new user created value,
                    // we should match this user typed value to the label of the existing option, then get the value
                    // of *that* option as the value to add. This will properly remove the existing option from the list.
                    //
                    // This logic works here because we've already searched for the value in the previouslySelectedLabels.
                    // If it didn't match there, but does match here, it must be an unselected option.
                    if (_.includes(optionLabels, addedValue)) {
                        addedValue = scope.convertOptionToValue(labelToOptionMap[addedValue]);
                    }

                    // If this is a user-created option (i.e.: not in the original options)...
                    if (!_.includes(optionValues, addedValue)) {
                        addValuesToUserCreatedValues([addedValue]);
                    }

                    // add the new value to the ngModel
                    scope.ngModel.push(addedValue);
                    computeNumEmptyItems();

                    clearUIState();
                    scope.updateDirty();
                    scope.updateValidity();
                }

                /*
                        Add the user created value to the list of selected options.
                        @param hashKey - the value of the user created item
                    */
                function onHashKeyAddCallback(hashKey) {
                    // Check if an option already exists with this hashKey to prevent
                    // duplicate entries. If a value can't be derived from the hashKey,
                    // meaning there is no existing available option that has this hashKey,
                    // set value to hashKey.
                    const value = convertHashKeyToValue(hashKey) || hashKey;
                    onItemAddCallback(value);
                }

                scope.removeItem = valueToRemove => {
                    // Remove the removed value from the model list
                    scope.ngModel = _.without(scope.ngModel, valueToRemove);

                    // Remove the value from userCreatedValues if it's there. This allows a user to type a custom
                    // entry, remove it, and then later re-type the same entry and have it be accepted as a non-duplicate.
                    //
                    // Delay so that there's time for the view to remove the element; otherwise,
                    // the view might try to render the removed value one more time and not find it in userCreatedValues,
                    // which isn't a well-defined state.
                    $timeout(() => {
                        userCreatedValues = _.without(userCreatedValues, valueToRemove);
                    });

                    computeNumEmptyItems();
                    scope.updateDirty();
                    scope.updateValidity();
                };

                //-------------------------
                // Selectize Config
                //-------------------------

                scope.selectizeConfig = {
                    create: scope.allowCreate,
                    valueField: 'hashKey',
                    labelField: 'title',
                    maxItems: 1,

                    // We have to turn off 'sortField' here because selectize does not support
                    // Chinese. See comment near `Selectize.defaults.sortField in
                    // FrontRoyal/angularModuleAngular/index.js
                    //
                    // The options are sorted above using localeCompare
                    sortField: '$order',
                    searchField: ['title'],
                };
            },
        };
    },
]);
