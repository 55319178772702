
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.disconnected = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.disconnected || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.disconnected = {...window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.disconnected, ...{
    "reconnect": "Reconnect",
    "we_will_try_again_when_you_are_online": "We will retry once you are back online.",
    "a_request_failed": "A message failed to reach the server.",
    "request_has_been_sent": "We are sending the message again.",
    "reconnecting": "Reconnecting...",
    "retry_now": "Retry now",
    "cancel": "Cancel",
    "will_retry_at": "We will retry in {{seconds}} seconds.",
    "will_retry_at_react": "We will retry in {seconds} seconds."
}
}
    