const optionsDefault: Intl.NumberFormatOptions = {
    style: 'currency',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
};

const localesDefaults = {
    en: 'en-US', // English (default)
    es: 'es-ES', // Spanish
    it: 'it-IT', // Italian
    zh: 'zh-CN', // Chinese
    ar: 'ar-SA', // Arabic
    am: 'am-ET', // Amharic
};

type Locales = keyof typeof localesDefaults;

const currencyDefaults: Record<Locales, string> = {
    en: 'USD', // (default)
    es: 'EUR',
    it: 'EUR',
    zh: 'CNY',
    ar: 'AED',
    am: 'ETB',
};

function isValidLocale(locale: string | Locales): locale is Locales {
    if (locale in localesDefaults) {
        return true;
    }
    return false;
}

// By default, this will hide decimals for an even unit amount, and show two decimal
// places otherwise.  For example:
// 8600 -> '$8,600'
// 27.5 -> '$27.50'
//
// If you want to force the display of cents, pass { maximumFractionDigits: 2, minimumFractionDigits: 2 }
//
// If you want to force rounding to dollars, pass { maximumFractionDigits: 0, minimumFractionDigits: 0 }
export function formatMoney(value: number, locale?: string, options?: Intl.NumberFormatOptions): string {
    // Using locale 'en-US' & currency 'USD' as default
    let bcp47Locale = localesDefaults.en;
    let currency = currencyDefaults.en;

    if (locale && isValidLocale(locale)) {
        bcp47Locale = localesDefaults[locale];
        currency = currencyDefaults[locale];
    }

    // display 2 decimal digits for non-integer currency values
    const decimalOptions: Intl.NumberFormatOptions = {};
    if (Math.round(value) !== value) {
        decimalOptions.maximumFractionDigits = 2;
        decimalOptions.minimumFractionDigits = 2;
    }

    return new Intl.NumberFormat(bcp47Locale, { ...optionsDefault, ...decimalOptions, currency, ...options }).format(
        value,
    );
}
