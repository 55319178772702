
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.choose_a_date = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.choose_a_date || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.choose_a_date = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.choose_a_date, ...{
    "month": "Month",
    "year": "Year",
    "current": "Current?",
    "maxdate_error": "End date must be after start date"
}
}
    