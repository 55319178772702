import NetworkConnection from 'NetworkConnection';
import cacheAngularTemplate from 'cacheAngularTemplate';
import template from '../views/disconnected.html';
import angularModule from './front_royal_api_error_handler';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('apiErrorDisconnected', [
    '$injector',

    /*

            When the user is online, the request will be retried
            after a delay (see more below about the length of delay).
            At any point during the delay, the user can click to
            retry immediately.

            When the retry request is in flight, the user can click
            to cancel, at which point the directive goes idle and
            there is not another request until the user clicks to retry.

            When the user is offline, the button is disabled and the
            message says that the request will be retried once the user
            is back online.

            If the user is online initially, or after a failed retry, there
            is a 10 second delay before the next retry.  If the user
            switches from being online to being offline, then the delay
            before the next retry is 2 seconds.
        */

    $injector => {
        const HttpQueue = $injector.get('HttpQueue');
        const $timeout = $injector.get('$timeout');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $window = $injector.get('$window');
        const $interval = $injector.get('$interval');
        const safeDigest = $injector.get('safeDigest');
        const $q = $injector.get('$q');
        const EventLogger = $injector.get('EventLogger');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                resolve: '<',
                response: '<',
                reject: '<',

                // this is mapped to explanation below, but has to be called message
                // here because 'message' is supported explicitly in front_royal_api_error_handler
                message: '<?',
            },
            link(scope) {
                window.scope = scope;

                scope.appInitialized = $window.CORDOVA ? $rootScope.networkBootstrapCompleted : true;

                const translationHelper = new TranslationHelper('front_royal_api_error_handler.disconnected');
                let retryPromise;
                let cancelRetryRequest;

                Object.defineProperty(scope, 'online', {
                    get() {
                        return NetworkConnection.online;
                    },
                });

                Object.defineProperty(scope, 'buttonText', {
                    get() {
                        // while a retry request is in flight, the
                        // button says cancel
                        if (scope.sending) {
                            return translationHelper.get('cancel');
                        }

                        // when the user is online, the button says
                        // retry
                        if (scope.online) {
                            return translationHelper.get('retry_now');
                        }

                        // when the user is offline, the buttons says it
                        // is reconnecting

                        return translationHelper.get('reconnecting');
                    },
                });

                Object.defineProperty(scope, 'showExplanation', {
                    get() {
                        // hide the explanation when there is an active
                        // retry request in flight.
                        return !scope.sending;
                    },
                });

                // Returns 1 if only the status or explanation is
                // visible, 2 if they are both visible.  This is necessary
                // because we have to put a break in between the lines.
                Object.defineProperty(scope, 'lineCount', {
                    get() {
                        let count = 0;
                        if (scope.showExplanation) {
                            count += 1;
                        }
                        if (scope.status) {
                            count += 1;
                        }
                        return count;
                    },
                });

                Object.defineProperty(scope, 'status', {
                    // eslint-disable-next-line getter-return, consistent-return
                    get() {
                        if (!scope.online) {
                            return translationHelper.get('we_will_try_again_when_you_are_online');
                        }
                        if (scope.sending) {
                            return translationHelper.get('request_has_been_sent');
                        }
                        if (retryPromise) {
                            const retryIn = Math.round((retryPromise.at - new Date()) / 1000);
                            return translationHelper.get('will_retry_at', {
                                seconds: retryIn,
                            });
                        }
                    },
                });

                Object.defineProperty(scope, 'sending', {
                    get() {
                        return !!cancelRetryRequest;
                    },
                });

                // just put on the scope for testing purposes
                Object.defineProperty(scope, 'cancelRetryRequest', {
                    get() {
                        return cancelRetryRequest;
                    },
                    set(val) {
                        cancelRetryRequest = val;
                    },
                    configurable: true,
                });

                Object.defineProperty(scope, 'doingSomething', {
                    get() {
                        return !scope.online || scope.sending || !!retryPromise;
                    },
                });

                scope.explanation = scope.message || translationHelper.get('a_request_failed');

                function deleteRetryTimeout() {
                    $timeout.cancel(retryPromise);
                    retryPromise = undefined;
                }

                function setRetryTimeout(delaySeconds) {
                    // retry the request automatically in 10s
                    const delay = delaySeconds * 1000;
                    retryPromise = $timeout(() => {
                        retry();
                    }, delay);
                    retryPromise.at = new Date().getTime() + delay;
                }

                function cancel() {
                    if (cancelRetryRequest) {
                        cancelRetryRequest();
                        cancelRetryRequest = undefined;
                    }
                }

                function retry() {
                    deleteRetryTimeout();

                    const config = scope.response.config;
                    _.extend(config, {
                        timeout: $q(resolve => {
                            cancelRetryRequest = resolve;
                        }),

                        // If the retry fails, we just want to skip
                        // the normal error handling and leave this
                        // modal open
                        'FrontRoyal.ApiErrorHandler': {
                            skip: true,
                        },
                    });

                    HttpQueue.retry(config).then(
                        response => {
                            EventLogger.log('disconnected_modal:retry_succeeded', {
                                label: response.config.url,
                            });
                            scope.resolve(response);
                        },
                        response => {
                            cancel();

                            if (!scope.appInitialized) {
                                setRetryTimeout(10);
                            } else {
                                // Occasionally, we get to this point and see response.config undefined.
                                // In this case, we still want to log the Event but can't really do much
                                // about the label as we have no pertinent data.
                                const payload = {
                                    label: (response && response.config && response.config.url) || 'UNKNOWN_URL',
                                };

                                // If the request was aborted by clicking the
                                // cancel button, then it will have a -1 status
                                // code.  In that case we don't want to set a timeout
                                // to retry, we just wait for the user to click
                                // the button again.
                                if (response.status !== -1) {
                                    EventLogger.log('disconnected_modal:retry_failed', payload);
                                    setRetryTimeout(10);
                                } else {
                                    EventLogger.log('disconnected_modal:retry_aborted', payload);
                                }
                            }
                        },
                    );
                }

                scope.$watch('online', (currentValue, previousValue) => {
                    // If we are already online initially, wait 10 seconds before
                    // retrying.  Maybe the issue is on the server, maybe
                    // something funky is going on, who knows.
                    if (currentValue === true && previousValue === true) {
                        setRetryTimeout(10);
                    }

                    // If we just came back online, just wait 2 seconds.  The
                    // problem is hopefully already resolved, but a brief delay
                    // might make sure that the connection is really ready to go
                    else if (currentValue && !previousValue) {
                        setRetryTimeout(2);
                    }

                    // If we are offline, delete the timeout and wait to come
                    // back online before recreating it
                    else {
                        cancel();
                        deleteRetryTimeout();
                    }
                });

                // Make sure the countdown works by forcing a
                // regular digest.  This is a bit inefficient,
                // but it should be such a small amount of work
                // that it should be fine.
                const cancelDigestInterval = $interval(safeDigest.bind(safeDigest, scope), 100, false);

                scope.onButtonClicked = evt => {
                    evt.stopPropagation(); // prevent the click from bubbling up to the modal
                    if (cancelRetryRequest) {
                        cancel();
                    } else {
                        retry();
                    }
                };

                scope.$on('$destroy', () => {
                    $timeout.cancel(retryPromise);
                    $interval.cancel(cancelDigestInterval);
                });
            },
        };
    },
]);
