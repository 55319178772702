
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.upload_avatar = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.upload_avatar || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.upload_avatar = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.upload_avatar, ...{
    "saved_automatically": "Photo is saved automatically after upload",
    "upload": "Upload",
    "x_mb_limit": "({{size}} max size)",
    "sync_from_linked_in": "Sync From LinkedIn",
    "picture_source_1": "Take Photo",
    "picture_source_0": "Choose Photo",
    "picture_access_tip": "Please enable camera or library access to use this feature.",
    "linkedin_error": "Error syncing from LinkedIn",
    "linkedin_no_picture": "LinkedIn was unable to find a profile picture"
}
}
    