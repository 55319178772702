import { type FC } from 'react';
import { type navigationHelper } from 'navigationHelper';
import { StudentSpotlight } from './StudentSpotlight';
import { MeetYourClass } from './MeetYourClass';

type FeaturedStudentsProps = {
    inStudentNetwork: boolean;
    loadRoute: ReturnType<typeof navigationHelper>['loadRoute'];
};

export const FeaturedStudents: FC<FeaturedStudentsProps> = ({ inStudentNetwork, ...rest }) =>
    inStudentNetwork ? <MeetYourClass {...rest} /> : <StudentSpotlight {...rest} />;
