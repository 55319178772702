import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/pills_select.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('pillsSelect', [
    '$injector',
    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const Locale = $injector.get('Locale');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=', // ngModel's reference can change because the sortable directive in selected-pills changes it under the hood
                localeKey: '@',
                maxPills: '<?',
                simpleInstructions: '<?',
                disabledCharacters: '<?',
            },
            templateUrl,
            link(scope) {
                const localeLookup = {
                    interests: {
                        label: 'student_network.student_network.add_an_interest',
                        options: 'student_network.field_options',
                        searchConstant: 'STUDENT_NETWORK_INTEREST_KEYS',
                    },
                };

                scope.localeKeys = localeLookup[scope.localeKey];

                const translationHelper = new TranslationHelper(scope.localeKeys.options);

                scope.pills = _.chain($injector.get(scope.localeKeys.searchConstant)).value();

                scope.togglePill = pill => {
                    const index = scope.indexOfPillTranslation(pill);
                    const max = scope.maxPills || 999;

                    if (index !== -1) {
                        scope.ngModel.splice(index, 1);
                    } else if (scope.ngModel.length < max) {
                        scope.ngModel.push({
                            text: pillTranslation(pill),
                            locale: Locale.activeCode,
                        });
                    }
                };

                function pillTranslation(pill) {
                    return translationHelper.get(pill);
                }

                scope.indexOfPillTranslation = pill =>
                    _.chain(scope.ngModel).map('text').indexOf(pillTranslation(pill)).value();
            },
        };
    },
]);
