import { useLocation, type createBrowserRouter, type createHashRouter } from 'react-router-dom';
import { useEffect } from 'react';
import { useRootScope } from './useRootScope';

// Observe route changes kicked off by angular and update the react router accordingly
export function useRespondToAngularRouteChanges(
    router: ReturnType<typeof createBrowserRouter> | ReturnType<typeof createHashRouter>,
) {
    const rootScope = useRootScope();

    useEffect(() => {
        function onRouteChangeSuccess() {
            let currentPathname = window.location.pathname;
            const searchParams = window.location.search;
            if (window.CORDOVA) {
                const hash = window.location.hash;
                currentPathname = hash && hash.match('#') ? hash.split('#')[1] : '/';
            }
            const target = `${currentPathname}${searchParams}`;
            router.navigate(target, { replace: true });
        }

        const off = rootScope.$on('$routeChangeSuccess', onRouteChangeSuccess);

        return off;
    }, [router, rootScope]);
}

// Let angular know whenever the react route changes (for event logging)
export function useEmitRouteChangeSuccessOnReactRouteChange() {
    const { pathname } = useLocation();
    const rootScope = useRootScope();

    useEffect(() => {
        rootScope.$emit('reactRouteChangeSuccess');
    }, [rootScope, pathname]);
}
