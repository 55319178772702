
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.feedback = window.Smartly.locales.modules.en.back_royal.feedback || {};
window.Smartly.locales.modules.en.back_royal.feedback.feedback_modal = window.Smartly.locales.modules.en.back_royal.feedback.feedback_modal || {};
window.Smartly.locales.modules.en.back_royal.feedback.feedback_modal = {...window.Smartly.locales.modules.en.back_royal.feedback.feedback_modal, ...{
    "give_feedback": "Give Feedback"
}
}
    