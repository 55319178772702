import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/inputs/upload_avatar.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('uploadAvatar', [
    '$injector',
    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const upload = $injector.get('frontRoyalUpload').upload;
        const ErrorLogService = $injector.get('ErrorLogService');
        const safeApply = $injector.get('safeApply');
        const $window = $injector.get('$window');
        const LinkedinOauthService = $injector.get('LinkedinOauthService');
        const Capabilities = $injector.get('Capabilities');

        return {
            restrict: 'E',
            templateUrl,
            require: '^?form',
            scope: {
                mainInstructions: '<?',
                model: '=',
                isRequired: '<?',
                user: '<',
                disableLinkedin: '<?',
                subTextKey: '<?',
            },
            link(scope, elem, attrs, formController) {
                /* global Camera, Uint8Array */

                if (angular.isUndefined(scope.isRequired)) {
                    scope.isRequired = true;
                }

                scope.subTextKey = scope.subTextKey || 'front_royal_form.inputs.upload_avatar.saved_automatically';

                scope.formController = formController;

                const translationHelper = new TranslationHelper('front_royal_form.inputs.upload_avatar');
                scope.uploadString = translationHelper.get('upload');
                scope.syncFromLinkedInString = translationHelper.get('sync_from_linked_in');

                //---------------------------
                // Upload Service Support
                //---------------------------

                function setNewValueOnModel(url) {
                    scope.model = scope.user.avatar_url = url;

                    // Since avatar_url is saved both on the currentUser
                    // and on currentUser.career_profile, we need to update
                    // both.  See https://trello.com/c/lJmT3mWR
                    if (scope.user.career_profile) {
                        scope.user.career_profile.avatar_url = url;
                    }
                }

                function uploadFileToServer(file) {
                    // See https://trello.com/c/u5KtFC2L.  We want to make
                    // sure that the old avatar does not get saved up to the server
                    // if the current user is saved before the upload request returns
                    const origValue = scope.model;
                    setNewValueOnModel(null);
                    scope.loading = true;

                    // We use frontRoyalUpload.upload() directly here instead of using handleNgfSelect
                    // since this is called from different places, not just from  ngf-select
                    upload({
                        url: scope.user.avatarUploadUrl(),
                        data: {
                            avatar_image: file,
                        },
                        supportedFormatsForErrorMessage: '.jpg, .png, .svg, .gif',
                    })
                        .then(response => {
                            if (response.status === 200) {
                                let url;
                                try {
                                    url = response.data.contents.avatar.url;
                                } catch (e) {}
                                if (url) {
                                    setNewValueOnModel(url);
                                } else {
                                    $injector.get('ErrorLogService').notifyInProd('No url in avatar response', null, {
                                        responseData: response.data,
                                    });
                                }
                            } else {
                                // It failed.  Reset the original value
                                setNewValueOnModel(origValue);
                            }
                            scope.loading = false;
                        })
                        .catch(() => {
                            setNewValueOnModel(origValue);
                            scope.loading = false;
                        });
                }

                //---------------------------
                // Cordova Upload Support
                //---------------------------

                scope.pictureSupported = $window.CORDOVA && navigator.camera;

                let destinationType;
                if (scope.pictureSupported) {
                    scope.pictureSourceTypes = [Camera.PictureSourceType.CAMERA, Camera.PictureSourceType.PHOTOLIBRARY];
                    scope.pictureSourceLabels = {};
                    _.forEach(scope.pictureSourceTypes, type => {
                        scope.pictureSourceLabels[type] = translationHelper.get(`picture_source_${type}`);
                    });
                    destinationType = Camera.DestinationType.FILE_URI;
                }

                function onDevicePictureSuccess(filePath) {
                    uploadDevicePicture(filePath);
                }

                function onDevicePictureFailure(error) {
                    const normalizedError = error && error.toString().toLowerCase(); // may be int in android

                    if (normalizedError === 'no camera available') {
                        safeApply(scope, () => {
                            // if we don't get the camera the first time, remove it from the available options
                            scope.pictureSourceTypes = _.without(
                                scope.pictureSourceTypes,
                                Camera.PictureSourceType.CAMERA,
                            );

                            // attempt to use the same upload service
                            scope.captureDevicePicture(scope.pictureSourceTypes[0]);
                        });
                    } else if (normalizedError === 'has no access to camera' || normalizedError === '20') {
                        safeApply(scope, () => {
                            // show a helpful message about enabling camera / library access
                            scope.showAccessTip = true;
                        });
                    } else if (
                        !_.includes(
                            ['no image selected', 'has no access to assets', 'unable to retrieve path to picture!'],
                            normalizedError,
                        )
                    ) {
                        // user has dismissed the library selection or cancelled camera action
                        ErrorLogService.notify('Failed to capture a camera image in Cordova', undefined, {
                            error,
                        });
                    }
                }

                function uploadDevicePicture(filePath) {
                    window.resolveLocalFileSystemURL(
                        filePath,
                        fileEntry => {
                            fileEntry.file(file => {
                                // Handle reader completion
                                const reader = new FileReader();
                                reader.onloadend = function () {
                                    // generate a proper File API blob for ng-file-upload
                                    const blob = new Blob([new Uint8Array(this.result)], {
                                        type: file.type,
                                    });

                                    // attempt to use the same upload service
                                    safeApply(scope, () => {
                                        uploadFileToServer(blob);
                                    });
                                };

                                // Read the binary data
                                reader.readAsArrayBuffer(file);
                            });
                        },
                        err => {
                            ErrorLogService.notify('Failed to open requested file', undefined, {
                                error: err,
                                file: filePath,
                            });
                        },
                    );
                }

                scope.captureDevicePicture = sourceType => {
                    scope.showAccessTip = false;
                    navigator.camera.getPicture(onDevicePictureSuccess, onDevicePictureFailure, {
                        quality: 50,
                        targetWidth: 512,
                        targetHeight: 512,
                        sourceType,
                        destinationType,
                        correctOrientation: true,
                    });
                };

                //---------------------------
                // Web Upload Support
                //---------------------------

                scope.onFileSelect = (file, errFiles) => {
                    const errFile = errFiles && errFiles[0];

                    if (file && !errFile) {
                        uploadFileToServer(file);
                    } else if (errFile && errFile.$error === 'maxSize') {
                        // File was over the limit specified in ngf-max-size
                    }
                };

                //---------------------------
                // LinkedIn Integration
                //---------------------------

                // We hide the ability to sync your avatar from LinkedIn if:
                // 1) On Cordova
                // 2) Explicitly asked to with the attribute option
                // 3) We detect that cookie storage isn't working (cookies are required for the
                //    CSRF checking, see linkedin_controller.rb)
                scope.hideSyncFromLinkedIn = $window.CORDOVA || !!scope.disableLinkedin || !Capabilities.cookies;

                scope.syncLinkedInAvatar = $event => {
                    const origValue = scope.model;
                    setNewValueOnModel(null);

                    // dirty the form so that the user can't navigate away without unsaved_changes_confirm
                    // modal firing no matter what happens with the LinkedIn popup. Especially in the case
                    // of closing the popup without logging in to LinkedIn and syncing. See: https://trello.com/c/n57UNi1w
                    if (scope.formController) {
                        scope.formController.$setDirty();
                    }

                    // This prevents the screen from scrolling down when
                    // the button is pressed
                    $event.preventDefault();

                    LinkedinOauthService.getProfile()
                        .then(profile => {
                            const profilePictureUrl = profile.pictureUrl;
                            if (profilePictureUrl) {
                                setNewValueOnModel(profilePictureUrl);
                                scope.user.save();
                                scope.linkedinMessage = undefined;
                            } else {
                                setNewValueOnModel(origValue);
                                scope.linkedinMessage = translationHelper.get('linkedin_no_picture');
                            }
                        })
                        .catch(() => {
                            setNewValueOnModel(origValue);
                            scope.linkedinMessage = translationHelper.get('linkedin_error');
                        });
                };
            },
        };
    },
]);
