/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NetworkConnection from 'NetworkConnection';
import { useTranslation } from 'react-i18next';
import isChromatic from 'chromatic';
import { createPortal } from 'react-dom';
import { FlatButton } from 'FlatButton';
import { ModalBackdrop } from 'FrontRoyalModal';
import { type ErrorHandlingComponent, type RetryFunction } from '../../FrontRoyalErrorBoundary.types';

type Props = {
    retry: RetryFunction;
    closeModal: () => void;
};

const BASE_LOCALE = 'front_royal_api_error_handler.disconnected';
const timerSeconds = 10;

function useNetworkConnectionOnline() {
    const [online, setOnline] = useState(NetworkConnection.online);

    useEffect(() => {
        const id = setInterval(() => {
            setOnline(NetworkConnection.online);
        }, 500);

        return () => {
            clearInterval(id);
        };
    }, []);

    return online;
}

// I'm not sure this is going to work well with the kind of retry
// function we're going to get from DataLoader, for example, but we'll
// see
function useCloseModalOnRetrySuccess({
    closeModal,
    retrySuccess,
}: {
    closeModal: Props['closeModal'];
    retrySuccess: boolean;
}) {
    useEffect(() => {
        if (retrySuccess) {
            closeModal();
        }
    }, [closeModal, retrySuccess]);
}

function useRetryTimer({
    online,
    isRetrying,
    setIsRetrying,
    setRetrySuccess,
    retry,
}: {
    online: boolean;
    retry: RetryFunction;
    setIsRetrying: (isRetrying: boolean) => void;
    setRetrySuccess: (success: boolean) => void;
    isRetrying: boolean;
}) {
    const [timeUntilNextRetry, setTimeUntilNextRetry] = useState<number | null>(null);
    const previousOnline = useRef(NetworkConnection.online);

    useEffect(() => {
        const justCameOnline = !previousOnline.current && online;
        previousOnline.current = online;

        // When the timer hits 0, retry
        if (online && timeUntilNextRetry === 0 && !isRetrying) {
            retryAndSetRetrySuccess({ retry, setIsRetrying, setRetrySuccess });

            // As long as you are offline, there is no timer
            // When a retry is in flight, there is no timer
        } else if (!online || isRetrying) {
            setTimeUntilNextRetry(null);

            // When coming online, trigger an (almost) immediate retry.
            // Wait a second because maybe that makes it more likely to work
        } else if (justCameOnline) {
            setTimeUntilNextRetry(1);

            // If you are online, not retrying, and there is no timer, then start the timer
        } else if (online && !isRetrying && !timeUntilNextRetry) {
            setTimeUntilNextRetry(timerSeconds);
        }
    }, [online, timeUntilNextRetry, isRetrying, retry, setIsRetrying, setRetrySuccess]);

    // Countdown the timer
    useEffect(() => {
        const id = setTimeout(() => {
            if (timeUntilNextRetry) setTimeUntilNextRetry(timeUntilNextRetry - 1);
        }, 1000);

        return () => clearTimeout(id);
    }, [timeUntilNextRetry, setTimeUntilNextRetry]);

    return { setTimeUntilNextRetry, timeUntilNextRetry };
}

async function retryAndSetRetrySuccess({
    retry,
    setIsRetrying,
    setRetrySuccess,
}: {
    retry: RetryFunction;
    setIsRetrying: (isRetrying: boolean) => void;
    setRetrySuccess: (retrySuccess: boolean) => void;
}) {
    setIsRetrying(true);
    try {
        await retry();
        setRetrySuccess(true);
    } catch (err) {
        setIsRetrying(false);
    }
}

function useButtonText({ online, isRetrying }: { online: boolean; isRetrying: boolean }) {
    const { t } = useTranslation('back_royal');
    return useMemo(() => {
        if (!online) return t(`${BASE_LOCALE}.reconnecting`);
        if (isRetrying) return t(`${BASE_LOCALE}.cancel`);

        return t(`${BASE_LOCALE}.retry_now`);
    }, [t, isRetrying, online]);
}

function useOnClick({
    isRetrying,
    setIsRetrying,
    setTimeUntilNextRetry,
}: {
    isRetrying: boolean;
    setIsRetrying: (isRetrying: boolean) => void;
    setTimeUntilNextRetry: (time: number) => void;
}) {
    return useCallback(() => {
        // Note that nothing can actually stop the retry that is in flight. If someone
        // cancels a retry and starts another, those will both be in flight at the same time.
        // It's possible that both will finish and update the UI. But, without knowing what's going
        // on inside the retry function, we can't prevent that, and it's preferable to giving no
        // way to cancel a hanging request and trigger a new one.
        if (isRetrying) {
            setIsRetrying(false);
        } else {
            setTimeUntilNextRetry(0);
        }
    }, [isRetrying, setTimeUntilNextRetry, setIsRetrying]);
}

function useStatusText({
    online,
    isRetrying,
    timeUntilNextRetry,
}: {
    online: boolean;
    isRetrying: boolean;
    timeUntilNextRetry: number | null;
}) {
    const { t } = useTranslation('back_royal');
    return useMemo(() => {
        if (!online) return t(`${BASE_LOCALE}.we_will_try_again_when_you_are_online`);

        if (isRetrying) return t(`${BASE_LOCALE}.request_has_been_sent`);

        if (timeUntilNextRetry !== null) {
            return t(`${BASE_LOCALE}.will_retry_at_react`, { seconds: isChromatic() ? 10 : timeUntilNextRetry });
        }

        return null;
    }, [isRetrying, online, timeUntilNextRetry, t]);
}

const DisconnectedErrorModal = ({ retry, closeModal }: Props) => {
    const online = useNetworkConnectionOnline();
    const [isRetrying, setIsRetrying] = useState(false);
    const [retrySuccess, setRetrySuccess] = useState(false);
    const { setTimeUntilNextRetry, timeUntilNextRetry } = useRetryTimer({
        online,
        isRetrying,
        setIsRetrying,
        setRetrySuccess,
        retry,
    });
    const buttonText = useButtonText({ online, isRetrying });
    const onClick = useOnClick({ isRetrying, setIsRetrying, setTimeUntilNextRetry });
    const status = useStatusText({ online, isRetrying, timeUntilNextRetry });
    const { t } = useTranslation('back_royal');
    useCloseModalOnRetrySuccess({ closeModal, retrySuccess });

    return (
        <ModalBackdrop>
            <div className="tw-absolute tw-left-0 tw-top-0 tw-z-[10100] tw-flex tw-h-[75px] tw-w-full tw-bg-white tw-font-semibold tw-text-eggplant tw-shadow-[0_3px_9px_rgba(0,0,0,0.5)] sm:tw-left-1/2 sm:tw-h-[50px] sm:tw-w-[600px] sm:tw-translate-x-[-50%] sm:tw-transform sm:tw-rounded-bl-[5px] sm:tw-rounded-br-[5px]">
                <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-p-1">
                    <span className="tw-text-center tw-text-[13px] xs:tw-text-[16px]">
                        {!isRetrying && <span>{t(`${BASE_LOCALE}.a_request_failed`)}</span>}{' '}
                        {!!status && <span>{status}</span>}
                    </span>
                </div>

                <FlatButton
                    onClick={onClick}
                    className="tw-flex-none tw-basis-[110px] tw-rounded-none tw-bg-[rgba(0,0,0,0.1)] tw-px-[5px] tw-py-[10px] tw-text-[13px] tw-font-semibold tw-text-eggplant"
                    disabled={NetworkConnection.offline}
                >
                    {buttonText}
                </FlatButton>
                <div className="tw-flex tw-flex-none tw-basis-[35px] tw-items-center tw-justify-center">
                    {(isRetrying || !online || timeUntilNextRetry !== null) && (
                        <i className="fa fa-refresh fa-spin loading-spinner tw-text-lg" />
                    )}
                </div>
            </div>
        </ModalBackdrop>
    );
};

export function ShowDisconnectedModal({
    retry,
    resetErrorBoundary,
}: {
    retry: Parameters<ErrorHandlingComponent>[0]['retry'];
    resetErrorBoundary: Parameters<ErrorHandlingComponent>[0]['resetErrorBoundary'];
}) {
    if (!retry) throw new Error('Retry function is required');

    return <>{createPortal(<DisconnectedErrorModal retry={retry} closeModal={resetErrorBoundary} />, document.body)}</>;
}
