import { ShowFatalModal } from './errorHandlingComponents/ShowFatalModal';
import { HandleFatalDisconnectedError } from './errorHandlingComponents/HandleFatalDisconnectedError';
import { RedirectToSignIn } from './errorHandlingComponents/RedirectToSignIn';
import { ShowNotPermittedModalAndRedirectToSignIn } from './errorHandlingComponents/ShowNotPermittedModalAndRedirectToSignIn';
import { SomethingWentWrong } from './errorHandlingComponents/SomethingWentWrong';
import { getShowRedirectToSignInButtonComponent } from './errorHandlingComponents/getShowRedirectToSignInButtonComponent';
import { ShowValidationErrors } from './errorHandlingComponents/ShowValidationErrors';

/*
    This file holds default configurations that can be passed
    to FrontRoyalErrorBoundary to define how we want to handle
    particular error types. In any particular situation, any of these
    keys can be overridden to provide custom error handling.
*/

// These defaults are used at the top of the UI when an error prevents the main page from loading
export const errorHandlingComponentsForTopLevelComponents = {
    disconnected: HandleFatalDisconnectedError,
    notLoggedIn: RedirectToSignIn,
    notPermitted: ShowNotPermittedModalAndRedirectToSignIn,
    other: ShowFatalModal,
}; // satisfies ErrorHandlingOptions; FIXME: see https://trello.com/c/PrHJNvAE

// These defaults are used when an ErrorBoundary is at a lower level on the page
export const errorHandlingComponentsForSubComponents = {
    notLoggedIn: getShowRedirectToSignInButtonComponent('notLoggedIn'),
    notPermitted: getShowRedirectToSignInButtonComponent('notPermitted'),
    notAcceptable: ShowValidationErrors,
    other: SomethingWentWrong,
}; // satisfies ErrorHandlingOptions; FIXME: see https://trello.com/c/PrHJNvAE
