import 'angular-md5';
import 'AMap/angularModule';
import 'Careers/angularModule';
import 'FrontRoyalUpload/angularModule';
import 'FrontRoyalLinkedIn/angularModule';
import 'Pagination/angularModule';
import 'SafeApply/angularModule';
import 'ScrollHelper/angularModule';
import 'SearchEngineOptimization/angularModule';
import 'SortableColumns/angularModule';
import 'TimeoutHelpers/angularModule';
import 'Translation/angularModule';
import Selectize from 'selectize';

export default angular
    .module('FrontRoyal.Form', [
        'Translation',
        'timeoutHelpers',
        'scrollHelper',
        'safeApply',
        'angular-md5',
        'FrontRoyal.AMap',
        'FrontRoyal.Careers',
        'SortableColumns', // multi-select requires HasLocation
        'SiteMetadata',
        'FrontRoyal.Linkedin',
        'FrontRoyal.Upload',
        'Pagination',
    ])
    .run([
        '$injector',
        () => {
            if (window.RUNNING_IN_TEST_MODE && (!Selectize || !Selectize.define)) {
                return;
            }

            // usage (config) -- `{ plugins: ['inputMaxlength'], inputMaxlength: 20 }`
            Selectize.define('inputMaxlength', function () {
                const self = this;
                this.setup = (() => {
                    const original = self.setup;
                    return function (...args) {
                        original.apply(this, args);
                        this.$control_input.attr('maxlength', this.settings.inputMaxlength);
                    };
                })();
            });
        },
    ]);
