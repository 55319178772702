import 'angular-sanitize';
import { markdown } from 'Markdown';

export default angular.module('FormatsText', ['ngSanitize']).factory('FormatsText', [
    '$injector',

    $injector => {
        const $window = $injector.get('$window');
        const $sce = $injector.get('$sce');
        const $sanitize = $injector.get('$sanitize');

        return {
            // helper method to search and replace Math blocks
            findMathjaxAndReplace(text, cb) {
                text = text.replace(/%%([^]*?)%%/g, (_match, contents) => {
                    if (cb) {
                        return `%%${cb(contents)}%%`;
                    }
                    return contents;
                });
                text = text.replace(/\$\$([^]*?)\$\$/g, (_match, contents) => {
                    if (cb) {
                        return `$$${cb(contents)}$$`;
                    }
                    return contents;
                });
                return text;
            },

            // To prevent markdown from screwing with Mathjax expressions, temporarily excise Mathjax blocks
            removeMathjaxBeforeMarkdown(text, context) {
                context._tempMathBlocks = [];

                text = this.findMathjaxAndReplace(text, dangerText => {
                    context._tempMathBlocks.push(dangerText);
                    return context._tempMathBlocks.length;
                });

                return text;
            },

            // Re-instate Mathjax expressions by popping them off the temp stack, in order
            addMathjaxAfterMarkdown(text, context) {
                text = this.findMathjaxAndReplace(text, () => context._tempMathBlocks.shift());

                return text;
            },

            // processes Markdown into HTML with additional rulesets
            processMarkdown(text, returnHtmlSafe) {
                // Add support for numbered lists that start at whatever number content dev wants
                //
                // REFERENCE: regexes from gruber.js that find lists
                // var any_list = "[*+-]|\\d+\\.",
                //     bullet_list = /[*+-]/,
                //     // Capture leading indent as it matters for determining nested lists.
                //     is_list_re = new RegExp( "^( {0,3})(" + any_list + ")[ \t]+" ),
                //     indent_re = "(?: {0,3}\\t| {4})";

                // First, define the regex to find ordered lists.
                // Since we search for this across the entire text block all at once,
                // we have to search for either 2 preceding newlines (plus optional whitespace)
                // OR a list that occurs at the very beginning of the text block.
                // We don't worry about lists that are preceeded by tabs instead of spaces,
                // since you can't type tabs in our editor.
                const numbered_list_re = /(^|\ns*\n) {0,3}(\d+\.)[ \t]+/g;

                // Next, search and replace ordered lists with some saved data so we can start the list at the right number
                text = text.replace(numbered_list_re, m => {
                    const numbers = m.match(/\d+/); // grab the numbers out (should only be one)
                    const newlines = m.match(/\n\s*\n/) ? '\n\n' : ''; // grab the preceding newlines and whitespace, if any
                    if (numbers !== null) {
                        const start = numbers[0]; // grab the start number
                        if (!Number.isNaN(start)) {
                            return `${newlines + start}. ~~~${start}~~~`; // use this nasty syntax, since it means nothing in markdown and will never be used in real text
                        }
                    }
                    return m;
                });

                // Run the markdown library on the text to handle out-of-the-box syntax
                // We add that first line so that we can skip maruku meta-parsing. See https://trello.com/c/C2mRH6zb
                let marked = markdown.toHTML(`IGNORETHISLINE\n\n${text}`, 'Maruku');
                marked = marked.replace(/<p>IGNORETHISLINE<\/p>\s*/, '');

                // Now, insert starting numbers for ordered lists using the saved data.
                // Watch for an optional <p> tag if this is a block list (formed by putting empty lines between the list entries)
                marked = marked.replace(/<ol><li>(<p>){0,1}~~~\d+~~~/g, m => {
                    const numbers = m.match(/\d+/);
                    const ptag = m.match(/<p>/) ? '<p>' : '';
                    return `<ol start="${numbers}"><li>${ptag}`;
                });

                // Finally, remove any left-over fake data for ordered list numbering
                marked = marked.replace(/~~~\d+~~~/g, '');

                // handle centering conventions:  -> centered text <-
                // NOTE: markedown will have already url-encoded brackets by this point
                marked = marked.replace(
                    /-&gt;(.*?)&lt;-/g,
                    (_match, contents) => `<span class="center">${contents}</span>`,
                );

                // handle coloring of text content via custom markdown conventions: {red:some red text goes here}
                // also handle the edge case of wanting to include an ending curly bracket in the coloring, e.g.: {red:json looks like {foo: bar\}}
                // NOTE: see also: _colors.scss for class definitions
                marked = marked.replace(
                    /({(purple|plum|yellow|blue|green|pink|grey|orange|red|white|coral|turquoise|darkturquoise|darkpurple|darkyellow|darkblue|darkgreen|darkcoral|darkorange|darkred|eggplant):)(.*?[^\\])(\})/g,
                    // eslint-disable-next-line arrow-body-style
                    (_match, _contents, color, _text) => {
                        return `<span class="${color}">${_text.replace(/\\}/g, '}')}</span>`;
                    },
                );

                // handle double curly braces {{}}, so that angular does not try to interpolate
                // text between curly braces as a variable i.e. {{ I am text and not defined on scope :) }}
                marked = marked.replace(/\{\{.*\}\}/g, match => {
                    const charArray = match.split('');
                    // insert a zero width space character to prevent interpolation
                    charArray.splice(1, 0, '\u200b');

                    return charArray.join('');
                });

                // NOTE: sanitization must be performed prior to any link rewriting, to get past
                // aggressive $sanitize rules
                if (returnHtmlSafe === true) {
                    $sce.trustAsHtml(marked);
                } else {
                    marked = $sanitize(marked).split('&#10;').join('\n');
                }

                // update external links to allow special handling
                // NOTE: The GetLessonExternalLinksMetadataJob relies on this link structure. See app/models/lesson/content/frame_list/frame/componentized.rb#external_link_urls before changing.
                marked = marked.replace(
                    /<a href="(.*?)"(\stitle="(.*?)")?>(.*?)<\/a>/g,
                    (_match, url, _titleAttr, titleAttrValue, textContent) => {
                        const link = `<a class="external-link" ng-click="openExternalLink('${$sanitize(url)}')"${
                            titleAttrValue ? ` title="${titleAttrValue}"` : ''
                        }>${textContent}</a>`;
                        return link;
                    },
                );

                return marked;
            },

            // processes Markdown into HTML, wraps in an angular elem, extracts text
            stripFormatting(text, returnHtmlSafe) {
                // strip mathjax
                let strippedText = this.findMathjaxAndReplace(text);
                // strip modals
                strippedText = strippedText.replace(/\[\[/g, '');
                strippedText = strippedText.replace(/\]\]/g, '');
                // process markdown into html
                const html = this.processMarkdown(strippedText, returnHtmlSafe);
                // extract text out of the resulting html
                const elem = angular.element(html);
                return elem.text();
            },

            getCachedOrFormatted(text, cache) {
                // once a text has been formatted once, cache it for later
                if (!cache[text]) {
                    // process with markdown
                    // don't strip outer <p> so we can support multiple paragraphs
                    const html = this.processMarkdown(text);
                    const el = $($window.document.createElement('div')).append(html);
                    cache[text] = el.html();
                }
                return cache[text];
            },

            withMarkdown(scope) {
                scope.formattedTexts = scope.formattedTexts || {};
                scope.formatText = text => this.getCachedOrFormatted(text, scope.formattedTexts);
            },
        };
    },
]);
