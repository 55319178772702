import { useCallback, useMemo } from 'react';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { useTranslation } from 'react-i18next';
import { createModal, MessageAndButtonModal } from 'FrontRoyalModal';
import { type ErrorHandlingComponent } from '../FrontRoyalErrorBoundary.types';

const ShowFatalModal: ErrorHandlingComponent = ({ resetErrorBoundary }) => {
    const { t } = useTranslation('back_royal');

    const gotoHome = useNavigationHelper().goHome;

    const { message, continueText } = useMemo(
        () => ({
            message: t('front_royal_api_error_handler.api_error_handler.something_went_wrong'),
            continueText: t('front_royal_api_error_handler.fatal.continue'),
        }),
        [t],
    );

    const onClick = useCallback(() => {
        gotoHome();
        resetErrorBoundary();
    }, [gotoHome, resetErrorBoundary]);

    return createModal(<MessageAndButtonModal message={message} buttonText={continueText} onClick={() => onClick()} />);
};

export { ShowFatalModal };
