import { type ButtonHTMLAttributes, memo } from 'react';
import { twMerge } from 'Utils/customTwMerge';

const styleClassNames = `
    tw-flex tw-items-center tw-justify-center tw-gap-2
    tw-border tw-border-transparent
    tw-bg-beige-darkest
    hover:tw-brightness-[97%]
    tw-rounded-[1.5em]
    tw-py-2 tw-px-10
    tw-text-white
    disabled:tw-opacity-60
`;

const FlatButtonComponent: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ className, children, ...rest }) => (
    <button type="button" className={twMerge(styleClassNames, className)} {...rest}>
        {children}
    </button>
);

export const FlatButton = memo(FlatButtonComponent) as typeof FlatButtonComponent;
