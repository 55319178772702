
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.feedback = window.Smartly.locales.modules.en.back_royal.feedback || {};
window.Smartly.locales.modules.en.back_royal.feedback.feedback_form = window.Smartly.locales.modules.en.back_royal.feedback.feedback_form || {};
window.Smartly.locales.modules.en.back_royal.feedback.feedback_form = {...window.Smartly.locales.modules.en.back_royal.feedback.feedback_form, ...{
    "contact_us_link": "You can also contact us directly at <a href=\"mailto:{{emailAddress}}\">{{emailAddress}}</a> or check out our <a ng-click=\"openFAQ()\">FAQ</a>.",
    "just_wanted_to_say": "Hi, I just wanted to say...",
    "message_received": "Message received. Thanks!",
    "submit": "Submit",
    "submit_feedback_failure": "Sorry, there was a problem submitting your feedback!"
}
}
    