/*
    createFormFieldConversions is a function that generates to other
    functions, `toForm` and `fromForm`.

    `toForm` takes a model and converts it into an object that can be passed into `initialValues`
    on a form.

    `fromForm` takes `form.values` and converts it to attributes that can be
    passed into a model.

    `createFormFieldConversions` is called with a list of conversion functions
    that show how to convert the particular fields in your model.  For an
    example, see BillingTransaction/formFieldConversions.js

    To see why toForm/fromForm might need to be async, and why it might need to take an injector,
    see StudentNetworkEvent/formFieldConversions.js -> accessGroups
*/

import { uniq } from 'lodash/fp';

// This function just exists because we don't know if conversion returns
// a promise or not
async function callConversion(conversion, values, { injector }) {
    const value = await conversion(values, { injector });
    return value;
}

function convert(values, conversions, { injector }, meth) {
    const clone = {
        ...values,
    };

    // It's possible that the conversion might create new fields
    // that only exist in the form, so, we need to look for keys in
    // both places;
    const allKeys = uniq(Object.keys(clone).concat(Object.keys(conversions)));

    const promises = allKeys.map(key => {
        const conversion = conversions[key] && conversions[key][meth];
        if (conversion)
            return callConversion(conversion, values, { injector }).then(value => {
                clone[key] = value;
            });
        return null;
    });

    return Promise.all(promises).then(() => clone);
}

export default function createFormFieldConversions(conversions) {
    function toForm(values, { injector }) {
        return convert(values, conversions, { injector }, 'toForm');
    }

    function fromForm(values, { injector }) {
        return convert(values, conversions, { injector }, 'fromForm');
    }

    return [toForm, fromForm];
}
