import { useTranslation } from 'react-i18next';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { memo, useCallback, useMemo } from 'react';
import { createModal, MessageAndButtonModal } from 'FrontRoyalModal';

const ShowEnterOfflineModeModalComponent: React.FC<{
    afterContinueClicked: () => void;
}> = ({ afterContinueClicked }) => {
    const { t } = useTranslation('back_royal');
    const gotoHome = useNavigationHelper().goHome;

    const { message, continueText, title } = useMemo(
        () => ({
            message: t('OfflineMode.showOfflineModal.click_to_continue_to_dashboard'),
            continueText: t('OfflineMode.showOfflineModal.continue'),
            title: t('OfflineMode.showOfflineModal.you_are_offline'),
        }),
        [t],
    );
    const onClick = useCallback(() => {
        gotoHome();
        afterContinueClicked();
    }, [gotoHome, afterContinueClicked]);

    return createModal(
        title,
        <MessageAndButtonModal message={message} buttonText={continueText} onClick={() => onClick()} />,
    );
};

export const ShowEnterOfflineModeModal = memo(
    ShowEnterOfflineModeModalComponent,
) as typeof ShowEnterOfflineModeModalComponent;
