import { useState, useEffect } from 'react';
import { useAngularContext } from 'AngularContext';
import { type Config, type ConfigFactory as ConfigFactoryType } from './FrontRoyalConfig.types';

// Use `useConfig` when it is possible the config has not yet been loaded. This will return
// `null` until the config has been loaded.
export function useConfig() {
    const $injector = useAngularContext();
    const ConfigFactory = $injector.get('ConfigFactory') as ConfigFactoryType;
    const [config, setConfig] = useState<Config | null>(() => ConfigFactory.getSync(true));

    useEffect(() => {
        ConfigFactory.getConfig().then(setConfig);
    }, [$injector, ConfigFactory]);

    return config;
}

// use `useSyncConfig` when you know the config is already available. It will
// throw if the config is not available
export function useSyncConfig(): Config {
    const $injector = useAngularContext();
    const ConfigFactory = $injector.get('ConfigFactory') as ConfigFactoryType;
    return ConfigFactory.getSync(true)!;
}
