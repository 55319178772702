import { useEffect } from 'react';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { useRedirectToSignIn } from './helpers/useRedirectToSignIn';
import { type ErrorHandlingComponent } from '../FrontRoyalErrorBoundary.types';

/*
    When this component is rendered, the user will be automatically logged out
    and redirected to the sign in page.
*/
const RedirectToSignIn: ErrorHandlingComponent = () => {
    const { unsetCurrentUserAndRedirectToSignIn } = useRedirectToSignIn();

    useEffect(() => {
        unsetCurrentUserAndRedirectToSignIn();
    }, [unsetCurrentUserAndRedirectToSignIn]);

    // Show a spinner while the redirect happens
    return <FrontRoyalSpinner />;
};

export { RedirectToSignIn };
