import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
// Use a full path in the import here to prevent from importing
// all of GooglePlaces
import attachGooglePlacesToInput from 'GooglePlaces/attachGooglePlacesToInput';
import attachAMapAutoCompleteToInput from 'AMap/attachAMapAutoCompleteToInput';
import template from 'FrontRoyalForm/angularModule/views/inputs/location_autocomplete.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('locationValidation', () => ({
    require: 'ngModel',

    link(scope, elm, attrs, ctrl) {
        ctrl.$validators.validPlace = modelValue => {
            // consider empty models to be valid
            if (ctrl.$isEmpty(modelValue)) {
                return true;
            }

            if (
                scope.formattedAddress &&
                scope.ngModel &&
                scope.detailsModel &&
                scope.formattedAddress === scope.detailsModel.formatted_address
            ) {
                return true;
            }

            return false;
        };

        scope.$watchGroup(['ngModel', 'formattedAddress', 'detailsModel'], () => {
            ctrl.$validate();
        });
    },
}));

angularModule.directive('locationAutocomplete', [
    '$injector',
    function factory($injector) {
        const safeApply = $injector.get('safeApply');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                detailsModel: '=', // Two-way binding - marketingSignUpForm needs access to any changes made in this directive
                placeholderText: '<?',
                shouldDisable: '<?',
                doNotRequire: '<?',
                selectTextOnFocus: '<?',
                options: '<?',
                onPlaceChange: '<?',
            },
            templateUrl,

            link(scope, elem) {
                const ConfigFactory = $injector.get('ConfigFactory');
                const chinaRegionMode = ConfigFactory.getSync(true).chinaRegionMode();

                scope.selectTextOnFocus = scope.selectTextOnFocus || false;
                scope.doNotRequire = scope.doNotRequire ?? false;

                const translationHelper = new TranslationHelper('front_royal_form.inputs.location_autocomplete');
                if (!scope.placeholderText) {
                    scope.placeholderText = translationHelper.get('search_for_city');
                }

                // on scope for testing
                scope.locationInput = elem.find('input[name="location"]');

                let addListener;
                let cancelMapBindings;
                if (chinaRegionMode) {
                    cancelMapBindings = attachAMapAutoCompleteToInput({
                        $injector,
                        input: scope.locationInput[0],
                        options: scope.options,
                        onAMapAutoCompleteInitialized: () => {
                            if (scope.detailsModel) {
                                // Initialize the places input with the career_profile.places_object
                                scope.formattedAddress = scope.detailsModel.formatted_address;
                            }

                            // FIXME: This logic to clear out the `ngModel` and `detailsModel` values when the location
                            // input value has been completely cleared out should probably be integrated into our React
                            // code at some point.
                            scope.$watch(
                                () => scope.locationInput.val(),
                                (newValue, oldValue) => {
                                    if (oldValue && !newValue) {
                                        scope.ngModel = undefined;
                                        scope.detailsModel = undefined;
                                    }
                                },
                            );
                        },
                        onPlaceChanged: (place, placeDetails) => {
                            if (scope.onPlaceChange) {
                                scope.onPlaceChange(place, placeDetails);
                            }
                            // update the careerProfile info
                            safeApply(scope, () => {
                                // if the selected city is invalid, the ngModel will not be updated to avoid triggering the ngModel's watch.
                                if (placeDetails?.invalidCity) {
                                    scope.formattedAddress = placeDetails.formatted_address;
                                } else {
                                    scope.ngModel = place.place_id;
                                    scope.detailsModel = placeDetails;
                                    // update the view to match, triggering the validation one more time
                                    if (scope.detailsModel) {
                                        scope.formattedAddress = scope.detailsModel.formatted_address;
                                    }
                                }

                                scope.errorText = placeDetails?.invalidCity
                                    ? translationHelper.get('choose_a_city')
                                    : '';
                            });
                        },
                    });
                } else {
                    addListener = attachGooglePlacesToInput({
                        $injector,
                        input: scope.locationInput[0],
                        options: scope.options,
                        onGooglePlacesInitialized: () => {
                            if (scope.detailsModel) {
                                // Initialize the places input with the career_profile.places_object
                                scope.formattedAddress = scope.detailsModel.formatted_address;
                            }

                            // FIXME: This logic to clear out the `ngModel` and `detailsModel` values when the location
                            // input value has been completely cleared out should probably be integrated into our React
                            // code at some point.
                            scope.$watch(
                                () => scope.locationInput.val(),
                                (newValue, oldValue) => {
                                    if (oldValue && !newValue) {
                                        scope.ngModel = undefined;
                                        scope.detailsModel = undefined;
                                    }
                                },
                            );
                        },
                        onGooglePlacesFailedToLoad: () => {
                            scope.shouldDisable = true;
                            scope.errorText = translationHelper.get('unable_to_load');
                        },
                    });

                    cancelMapBindings = addListener((place, placeDetails) => {
                        if (scope.onPlaceChange) {
                            scope.onPlaceChange(place, placeDetails);
                        }
                        // update the careerProfile info
                        safeApply(scope, () => {
                            scope.ngModel = place.place_id;
                            scope.detailsModel = placeDetails;
                            // update the view to match, triggering the validation one more time
                            if (scope.detailsModel) {
                                scope.formattedAddress = scope.detailsModel.formatted_address;
                            }
                        });
                    });
                }

                scope.onFocus = () => {
                    if (scope.selectTextOnFocus && scope.ngModel) {
                        scope.locationInput.select();
                    }
                };

                // NOTE: If we need to expand on this scheme, we might want to use https://github.com/mathiasbynens/he (~12k)
                function decodeHtml(html) {
                    const txt = document.createElement('textarea');
                    txt.innerHTML = html;
                    return txt.value;
                }

                scope.$watch('detailsModel', (newDetailsModel, oldDetailsModel) => {
                    // Google has hi-jacked the input, so changing scope.locationString is not reflected
                    // in there.  As far as I can tell, the Autocomplete api gives no way to clear
                    // it out, but just doing it directly seems to work. (This is needed for the
                    // integration with mult-select, which sets the placeDetails to null from
                    // outside of this directive)
                    //
                    // We might still not support setting detailsModel to a real value from outside of here
                    if (oldDetailsModel && !newDetailsModel) {
                        scope.locationInput.val('');
                    } else if (scope.detailsModel) {
                        // NOTE: in several instances (eg - &#39;), entity-escaped strings can blow up ApiCrudControllerBase::decode_json_params
                        scope.detailsModel.adr_address = decodeHtml(scope.detailsModel.adr_address);
                    }
                });

                scope.$on('$destroy', () => {
                    cancelMapBindings();
                });
            },
        };
    },
]);
