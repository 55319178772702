import { useTranslation } from 'react-i18next';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { useMemo, useCallback } from 'react';
import { createModal, MessageAndButtonModal } from 'FrontRoyalModal';
import { type ErrorHandlingComponent } from '../FrontRoyalErrorBoundary.types';

/*
    This component shows a modal explaining to the user that they do not have access to the data they are trying to access.
    Upon clicking the continue button, the user will be redirected to the home page.
*/
const ShowNotPermittedModalAndRedirectToSignIn: ErrorHandlingComponent = ({ resetErrorBoundary }) => {
    const { t } = useTranslation('back_royal');
    const gotoHome = useNavigationHelper().goHome;

    const { message, continueText } = useMemo(
        () => ({
            message: t('front_royal_api_error_handler.api_error_handler.you_do_not_have_content_permissions'),
            continueText: t('front_royal_api_error_handler.fatal.continue'),
        }),
        [t],
    );

    const onClick = useCallback(() => {
        gotoHome();
        resetErrorBoundary();
    }, [gotoHome, resetErrorBoundary]);

    return createModal(<MessageAndButtonModal message={message} buttonText={continueText} onClick={() => onClick()} />);
};

export { ShowNotPermittedModalAndRedirectToSignIn };
