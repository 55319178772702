
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.disconnected_mobile_init = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.disconnected_mobile_init || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.disconnected_mobile_init = {...window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.disconnected_mobile_init, ...{
    "network_offline": "Your network appears to be offline. Please enable a Wi-Fi or cellular connection to continue.",
    "connection_detected": "Connection detected!",
    "loading": "Loading ..."
}
}
    