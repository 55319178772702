
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.share_buttons = window.Smartly.locales.modules.en.back_royal.front_royal_form.share_buttons || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.share_buttons = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.share_buttons, ...{
    "share": "Share:",
    "facebook": "Facebook",
    "linkedin": "LinkedIn",
    "twitter": "Twitter",
    "email": "Email",
    "referral_title": "Smartly Talent",
    "referral_description": "I applied to Smartly Talent! Get $500 if you get hired in the network. 🚀 #DreamJob #Careers",
    "referral_email_title": "My new network",
    "referral_email_description": "Hi,\n\nI just signed up with Smartly Talent and thought you might want to join too!\n\nIt’s a new career service that pays you $500 when you get hired by any company in their network.\n\nClick here to get started: {{canonicalUrl}}\n\n{{signature}}"
}
}
    