import { type ErrorHandlingComponent } from '../FrontRoyalErrorBoundary.types';
import { useRedirectToSignIn } from './helpers/useRedirectToSignIn';

/*
    This component gives the user a message explaining that they cannot access the given data,
    either because they are not logged in or because the user they are logged in as does not have
    access.

    There is a button that the user can click to go to the sign in page.
*/
function ShowRedirectToSignInButtonComponent({ header, buttonLabel }: { header: string; buttonLabel: string }) {
    const { unsetCurrentUserAndRedirectToSignIn } = useRedirectToSignIn();

    return (
        <>
            {header}{' '}
            <button type="button" onClick={unsetCurrentUserAndRedirectToSignIn}>
                {buttonLabel}
            </button>
        </>
    );
}

// This is a factory function that creates an ErrorHandlingComponent for the given errorType
export function getShowRedirectToSignInButtonComponent(
    errorType: 'notLoggedIn' | 'notPermitted',
): ErrorHandlingComponent {
    return () => (
        <ShowRedirectToSignInButtonComponent
            header={errorType === 'notLoggedIn' ? 'You are not logged in.' : 'You are not authorized to do that.'}
            buttonLabel={errorType === 'notLoggedIn' ? 'Sign In' : 'Sign in as someone else'}
        />
    );
}
