import 'DialogModal/angularModule';
import 'OfflineMode/angularModule';
import 'Translation/angularModule';
import 'EventLogger/angularModule';
import 'Navigation/angularModule';

export default angular.module('FrontRoyal.Feedback', [
    'Translation',
    'DialogModal',
    'EventLogger',
    'FrontRoyal.Navigation',
    'OfflineMode',
]);
