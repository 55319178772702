
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.validate_number = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.validate_number || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.validate_number = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.validate_number, ...{
    "enter_a_number": "Enter a number from {{min}} to {{max}}"
}
}
    