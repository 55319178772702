import cacheAngularTemplate from 'cacheAngularTemplate';
import angularModule from './front_royal_api_error_handler';
import template from '../views/disconnected_mobile_init.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

// Displayed during application bootstrapping deferral due to lack of internet connection,
// or due to failure to connect with an allegedly valid network connection during this phase.

angularModule.directive('disconnectedMobileInit', [
    '$injector',

    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                disconnectedMessages: '<',
            },
            link(scope) {
                const translationHelper = new TranslationHelper(
                    'front_royal_api_error_handler.disconnected_mobile_init',
                );

                scope.disconnectedMessages = scope.disconnectedMessages || [translationHelper.get('network_offline')];

                // see also: `FrontRoyal/angularModuleAngular/index.js` bootstrapping
                const stopListeningForConnection = $rootScope.$watch('networkBootstrapStarted', val => {
                    if (val) {
                        scope.disconnectedMessages = [
                            translationHelper.get('connection_detected'),
                            translationHelper.get('loading'),
                        ];
                    }
                });

                scope.$on('$destroy', () => {
                    stopListeningForConnection();
                });
            },
        };
    },
]);
