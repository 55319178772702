import cacheAngularTemplate from 'cacheAngularTemplate';
import dropdownHelper from 'DropdownHelper';
import template from '../../views/inputs/choose_an_item.html';
import angularModule from '../front_royal_form_module';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('chooseAnItem', [
    '$injector',
    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('front_royal_form.inputs.choose_an_item');
        const locationPlaceDetails = $injector.get('LOCATION_PLACE_DETAILS');

        return {
            restrict: 'E',
            scope: {
                // the multi-select directive replaces the ngModel when
                // an item is removed, thus two-way binding
                ngModel: '=',
                itemKey: '@',
                placeholderText: '<?',
                mode: '<?',
                min: '<?',
                max: '<?',
                disableOrdering: '<?',
                allowCreate: '<?',
                allOption: '<?',
                displaySelectedOptions: '<?',
                shouldDisable: '<?',
                useLabelAsValue: '<?',
                options: '<?',
                wrap: '<?',
                sorted: '<?',
                skipModelOrdering: '<?',
                grouped: '<?',
            },
            require: '?^ngModel',
            templateUrl,

            link(scope, _elem, attrs) {
                let fieldOptionsTranslationHelper;
                let optionKeys;

                if (_.includes(['student_network_looking_for', 'student_network_interest'], scope.itemKey)) {
                    fieldOptionsTranslationHelper = new TranslationHelper('student_network.field_options');
                } else if (_.includes(['industry', 'location', 'place'], scope.itemKey)) {
                    fieldOptionsTranslationHelper = new TranslationHelper('careers.field_options');
                } else if (scope.itemKey !== 'class') {
                    throw new Error('Unsupported item key');
                }

                if (scope.itemKey === 'student_network_looking_for') {
                    optionKeys = $injector.get('STUDENT_NETWORK_LOOKING_FOR_KEYS');
                } else if (scope.itemKey === 'student_network_interest') {
                    optionKeys = $injector.get('STUDENT_NETWORK_INTEREST_KEYS').popular_interests;
                } else if (scope.itemKey === 'industry') {
                    optionKeys = $injector.get('CAREERS_INDUSTRY_KEYS');
                } else if (scope.itemKey === 'location') {
                    optionKeys = _.without(
                        $injector.get('CAREERS_LOCATIONS_OF_INTEREST_KEYS'),
                        'flexible',
                        'show_all_locations',
                    );
                } else if (scope.itemKey === 'place') {
                    optionKeys = _.without(
                        $injector.get('CAREERS_LOCATIONS_OF_INTEREST_KEYS'),
                        'flexible',
                        'show_all_locations',
                    );
                } else if (scope.itemKey !== 'class') {
                    throw new Error('Unsupported item key');
                }

                //-------------------------------
                // Global Config
                //-------------------------------

                scope.isRequired = angular.isDefined(attrs.required);
                scope.placeholderText = scope.placeholderText || translationHelper.get(scope.itemKey);
                scope.mode = attrs.mode || 'select';
                scope.displaySelectedOptions = angular.isDefined(scope.displaySelectedOptions)
                    ? scope.displaySelectedOptions
                    : true;

                //-------------------------------
                // Multi-Select Config
                //-------------------------------

                scope.min = scope.min || 0;
                scope.disableOrdering = scope.disableOrdering || false;
                scope.allowCreate = scope.allowCreate || false;

                scope.getOptionLabel = option => {
                    if (scope.useLabelAsValue) return option;

                    if (scope.itemKey === 'class') return option.label;

                    return fieldOptionsTranslationHelper.get(option);
                };

                scope.getOptionValue = option => {
                    if (scope.itemKey === 'place') return locationPlaceDetails[option];

                    if (scope.itemKey === 'class' && option) return option.value;

                    return option;
                };

                scope.orderByLabel = option => option.label;

                //-------------------------------
                // Initialization
                //-------------------------------

                const values = scope.useLabelAsValue
                    ? _.map(optionKeys, key => fieldOptionsTranslationHelper.get(key))
                    : optionKeys;

                if (!scope.options) {
                    scope.options =
                        scope.mode === 'multi'
                            ? values
                            : _.map(optionKeys, key => ({
                                  value: key,
                                  label: fieldOptionsTranslationHelper.get(key),
                              })).sort((a, b) =>
                                  a.label.localeCompare(b.label, fieldOptionsTranslationHelper.getCurrentLanguage()),
                              );
                }
                dropdownHelper.moveOtherToBottom(scope.options);
            },
        };
    },
]);
