/**
 * *************************************************************
 * *** IMPORTANT: DO NOT USE THIS FUNCTION FOR CALCULATIONS! ***
 * *************************************************************
 *
 * This module is used to standardize the formatting of scores for display purposes.
 * It is used in places where we show a grade to a user and consistency / formatting matters.
 * We use a truncation strategy to ensure that we don't round up a failing score.
 *
 * @param baseScore - This should be a number from 0 -> 1
 * @param  allowTrailingZero - If true, the grade will always have two decimal places unless it is an integer
 */
export const formatScore = (score: number, allowTrailingZero = true): string => {
    // Why multiply by 10000 and then divide by 100?
    //  - Multiply by 100 to convert the score to a percentage (0.95555 -> 95.555)
    //  - Multiply by 100 again to move the decimal point two places to the right (95.555 -> 9555.5)
    //  - Truncate the decimal part (9555.5 -> 9555)
    //  - Divide by 100 to move the decimal point two places to the left (9555 -> 95.55)
    const truncatedScore = Math.trunc(score * 10000) / 100;

    // At this point, truncatedScore is either an integer or has a 1-2 digit decimal part
    // without a trailing zero (e.g. 95.5 instead of 95.50, 95 instead of 95.00, or 95.55).
    // If we aren't allowing a trailing zero, we can return the truncatedScore as a string now.
    if (Number.isInteger(truncatedScore) || !allowTrailingZero) {
        return truncatedScore.toString();
    }

    // We'll only reach this point if we are allowing a trailing zero and the score has a decimal part.
    // In this case, we just need to ensure a string with two decimal places is returned.
    return truncatedScore.toFixed(2);
};
