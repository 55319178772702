import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/inputs/write_text_about.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('writeTextAbout', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const TranslationHelper = $injector.get('TranslationHelper');
        const writeTextAboutTranslationHelper = new TranslationHelper('front_royal_form.inputs.write_text_about');

        return {
            restrict: 'E',
            scope: {
                placeholder: '<?',
                minChars: '<?',
                maxChars: '<?',
                disabled: '<',
                shrinkToInput: '<',
                textareaClass: '@?',
            },
            require: '?^ngModel',
            templateUrl,

            link(scope, elem, attrs, modelController) {
                //---------------------------
                // Initialization
                //---------------------------

                if (FormHelper.isNonEnglish() && !scope.placeholder) {
                    scope.placeholder = writeTextAboutTranslationHelper.get('please_fill_in_english');
                }

                // Keep in mind - http://stackoverflow.com/a/17076323/1747491
                scope.maxChars = scope.maxChars || 150;

                scope.isRequired = angular.isDefined(attrs.required);

                Object.defineProperty(scope, 'inputtedText', {
                    get() {
                        return modelController.$modelValue;
                    },
                    set(val) {
                        modelController.$setViewValue(val);
                        modelController.$render();
                    },
                });

                scope.$watch('inputtedText', newVal => {
                    if (scope.minChars && newVal && newVal.length < scope.minChars) {
                        modelController.$setValidity('minlength', false);
                    } else {
                        modelController.$setValidity('minlength', true);
                    }

                    if (newVal && newVal.length > scope.maxChars) {
                        modelController.$setValidity('maxlength', false);
                    } else {
                        modelController.$setValidity('maxlength', true);
                    }

                    if (FormHelper.invalidLanguage(newVal)) {
                        modelController.$setValidity('language', false);
                    } else {
                        modelController.$setValidity('language', true);
                    }
                });
            },
        };
    },
]);
