import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
// NOTE: modeled after https://stackoverflow.com/questions/17470790/how-to-use-a-keypress-event-in-angularjs
angularModule.directive('onKeypressEnter', () => (scope, element, attrs) => {
    element.bind('keydown keypress', event => {
        if (event.which === 13 && attrs.onKeypressEnter) {
            scope.$apply(() => {
                scope.$eval(attrs.onKeypressEnter);
            });

            event.preventDefault();
        }
    });
});
