import angularModule from 'FormatsText/angularModule/scripts/formats_text';

angularModule.factory('LineBreakPrevention', [
    () => ({
        preventLineBreaks(text) {
            // We are using unicodes to check for special symbols below, but this doesn't work on
            // characters / symbols that were converted to an HTML code (such as ¢ = &#162;). So, let's just
            // convert HTML character codes to their unicode equivelant to avoid mixing and matching.
            // See: http://stackoverflow.com/a/10253928/1747491
            text = text.replace(/&#(\d{0,4});/, (fullStr, str) => String.fromCharCode(str));

            return text.replace(this._createLineBreakPreventionRegex(), (match, p1, p2, p3, p4, p5, p6, p7) => {
                if (p2.trim().length > 0 || p7.trim().length > 0) {
                    return `<span style="white-space: nowrap">${match}</span>`;
                }
                return match;
            });
        },

        /* eslint-disable */
        _nonWordBoundary: '\\B',

        // according to http://www.unicode.org/charts/PDF/U20A0.pdf
        _currencySymbols: [
            '\\\u0024', // $  dollar sign
            '\\\u00A2', // ¢  cent sign
            '\\\u00A3', // £  pound sign
            '\\\u00A4', // ¤  currency sign
            '\\\u00A5', // ¥  yen sign
            '\\\u0192', // ƒ  latin small letter f with hook
            '\\\u060B', //   afghani sign
            '\\\u09F2', // ৲  bengali rupee mark
            '\\\u09F3', // ৳  bengali rupee sign
            '\\\u0AF1', // ૱  gujarati rupee sign
            '\\\u0BF9', // ௹  tamil rupee sign
            '\\\u0E3F', // ฿  thai currency symbol baht
            '\\\u17DB', // ¤  khmer currency symbol riel
            '\\\u2133', // ℳ  script capital m
            '\\\u5143', // 元  cjk unified ideograph-5143
            '\\\u5186', // 円  cjk unified ideograph-5186
            '\\\u5706', // 圆  cjk unified ideograph-5706
            '\\\u5713', // 圓  cjk unified ideograph-5713
            '\\\uFDFC', //   rial sign
            '\\\u20A0', // ₠ EURO-CURRENCY SIGN
            '\\\u20AC', // €  euro sign
            '\\\u20A1', // ₡ COLON SIGN
            '\\\u20A2', // ₢ CRUZEIRO SIGN
            '\\\u20A3', // ₣ FRENCH FRANC SIGN
            '\\\u20A4', // ₤ LIRA SIGN
            '\\\u20A5', // ₥ MILL SIGN
            '\\\u20A6', // ₦ NAIRA SIGN
            '\\\u20A7', // ₧ PESETA SIGN
            '\\\u20B1', // ₱  peso sign
            '\\\u20A8', // ₨ RUPEE SIGN
            '\\\u20B9', // ₹  indian rupee sign
            '\\\u20A9', // ₩ WON SIGN
            '\\\u20AA', // ₪ NEW SHEQEL SIGN
            '\\\u20AB', // ₫ DONG SIGN
            '\\\u20AD', // ₭ KIP SIGN
            '\\\u20B3', // ₳ AUSTRAL SIGN
            '\\\u20B4', // ₴ HRYVNIA SIGN
            '\\\u20B5', // ₵ CEDI SIGN
            '\\\u023B', // Ȼ  latin capital letter c with stroke
            '\\\u20B6', // ₶ LIVRE TOURNOIS SIGN
            '\\\u20B7', // ₷ SPESMILO SIGN
            '\\\u20B8', // ₸ TENGE SIGN
            '\\\u2351', // ⍑  apl functional symbol up tack
            '\\\u2564', // ╤  box drawings down single and
            '\\\u3012', // 〒  postal mark
            '\\\u0930', // र  devanagari letter ra
            '\\\u20BA', // ₺ TURKISH LIRA SIGN
            '\\\u20BB', // ₻ NORDIC MARK SIGN
            '\\\u20BC', // ₼ MANAT SIGN
            '\\\u20BD', // ₽ RUBLE SIGN
            '\\\u20AE', // ₮ TUGRIK SIGN
            '\\\u20AF', // ₯ DRACHMA SIGN
            '\\\u20B0', // ₰ GERMAN PENNY SIGN
            '\\\u20B2', // ₲ GUARANI SIGN
        ].join(''),

        _specialCharacters: ['\\-', '\\\u2013', '\\\u2014', '\\:', '\\(', '\\)'].join(''),

        // Percent sign is handled differently due to mathjax -- https://trello.com/c/RVuW6WsS
        _specialAfterCharacters: ['\\;', '\\,', '\\.', '\\!', '\\?'].join(''),

        /* eslint-enable */

        _createLineBreakPreventionRegex() {
            /* eslint-disable */

            return new RegExp(
                '((' + // group 1: whole match
                    this._nonWordBoundary + // group 2: spans (including data) OR alphanumeric + select punctuation OR whitespace
                    '|<\\s*span[^>]*>(.*?)<\\s*\\/\\s*span>' + // group 3: data between spans
                    '|[A-Za-z0-9' +
                    this._currencySymbols +
                    this._specialCharacters +
                    ']+' +
                    ')' +
                    '(' + // group 4: challenge blank (including data) OR mathjax span
                    '<(cf-challenge-blank.*?<\\/cf-challenge-blank>{1})' + // group 5: "cf-challenge-blank.*?</cf-challenge-blank>"
                    '|(%%.*?%%)' + // group 6: mathjax surrounded by %%
                    ')' +
                    '(' + // group 7: spans including data OR alphanumeric or punctuation
                    '<\\s*span[^>]*>(.*?)<\\s*\\/\\s*span>' +
                    '|[A-Za-z0-9' +
                    this._currencySymbols +
                    this._specialCharacters +
                    this._specialAfterCharacters +
                    ']' +
                    '|' +
                    '%(?!%)' + // negative lookahead to check if mathjax syntax
                    '|' +
                    this._nonWordBoundary +
                    '))',
                'g',
            );

            /* eslint-enable */
        },
    }),
]);
