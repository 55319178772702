import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';

angularModule.directive('contenteditable', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const $sce = $injector.get('$sce');

        return {
            restrict: 'A',
            require: '?ngModel',

            link(scope, elem, attrs, modelController) {
                if (!modelController) {
                    return;
                }

                //---------------------------
                // Initialization
                //---------------------------

                FormHelper.supportsFauxInput(scope, attrs, modelController);

                //---------------------------
                // modelController "Binding"
                //---------------------------

                modelController.$render = () => {
                    elem.html($sce.getTrustedHtml(modelController.$viewValue || ''));
                };

                function read() {
                    const val = elem.html();

                    if (attrs.maxlength) {
                        const maxlength = parseInt(attrs.maxlength, 10);
                        if (val && val.length >= maxlength) {
                            // nope, ensure we rollback if we're enforcing maxlength
                            modelController.$rollbackViewValue();
                            setCursorAtEnd();
                            return;
                        }
                    }

                    modelController.$setViewValue(val);
                }

                //---------------------------
                // Event Management
                //---------------------------

                let focused;

                elem.on('keyup change', () => {
                    scope.$evalAsync(read);
                });

                elem.on('focus', () => {
                    if (!focused) {
                        setCursorAtEnd();
                        focused = true;
                    }
                });

                elem.on('blur', () => {
                    focused = false;
                });

                scope.$on('$destroy', () => {
                    elem.off();
                });

                //---------------------------
                // Cursor Management
                //---------------------------

                function setCursorAtEnd() {
                    const contentEditableElement = elem.get(0);

                    const range = document.createRange(); // create a range
                    range.selectNodeContents(contentEditableElement); // select the entire contents of the element
                    range.collapse(false); // collapse the range to the end point

                    const selection = window.getSelection(); // get the selection object
                    selection.removeAllRanges(); // remove any selections already made
                    selection.addRange(range); // make the range you have just created the visible selection
                }
            },
        };
    },
]);
