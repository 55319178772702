import { angularInjectorProvider } from 'Injector';
import { type OfflineModeManager } from 'OfflineMode';
import { useEffect, useState } from 'react';
import { type ErrorHandlingComponent } from '../FrontRoyalErrorBoundary.types';
import { ShowEnterOfflineModeModal } from './HandleFatalDisconnectedError/ShowEnterOfflineModeModal';
import { ShowDisconnectedModal } from './HandleFatalDisconnectedError/ShowDisconnectedModal';

/*
    This component is intended to handle disconnected errors or gateway errors at the top level of the app,
    indicating that the user cannot navigate to this page without a network connection.

    The particular behavior that we implement is described below. If there is ever a case where we don't want
    to follow this behavior in response to a top-level disconnected error, then we should rename this component.

    Behavior:

        * Try to enter offline mode. If we can enter offline mode, then we inform the user and send
            them back to the dashboard. If this is a page that can be rendered in offline mode, then there
            never should have been a disconnected error that bubbled up to the top level anyway.
        * If we can't enter offline mode, show the disconnected modal, and leave it on the screen until there
            is a successful retry. Once there is a successful retry, the modal will be hidden and the page
            will be rendered.
*/
const HandleFatalDisconnectedError: ErrorHandlingComponent = ({ retry, resetErrorBoundary }) => {
    const [status, setStatus] = useState<'checkingOfflineMode' | 'inOfflineMode' | 'notInOfflineMode'>(
        'checkingOfflineMode',
    );

    useEffect(() => {
        const offlineModeManager = angularInjectorProvider.get<OfflineModeManager>('offlineModeManager');
        offlineModeManager.updateInOfflineMode().then(inOfflineMode => {
            setStatus(inOfflineMode ? 'inOfflineMode' : 'notInOfflineMode');
        });
    }, []);

    return (
        <>
            {status === 'inOfflineMode' && <ShowEnterOfflineModeModal afterContinueClicked={resetErrorBoundary} />}
            {status === 'notInOfflineMode' && (
                <ShowDisconnectedModal retry={retry} resetErrorBoundary={resetErrorBoundary} />
            )}
        </>
    );
};

export { HandleFatalDisconnectedError };
