
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.salary = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.salary || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.salary = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.salary, ...{
    "open_to_discussion": "Open to Discussion"
}
}
    