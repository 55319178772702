
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.invalid_fields_links = window.Smartly.locales.modules.en.back_royal.front_royal_form.invalid_fields_links || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.invalid_fields_links = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.invalid_fields_links, ...{
    "please_complete_fields": "Please complete all required fields *",
    "please_complete_fields_in_english": "Please answer the following fields in English"
}
}
    