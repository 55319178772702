import { useFormContext } from 'FrontRoyalReactHookForm';
import { type AnyObject } from '@Types';

const ErrorMessage = ({ name = '', className = '' }) => {
    const {
        formState: { errors },
    } = useFormContext<AnyObject>();

    const errorMessage = errors[name]?.message;

    return errorMessage ? <div className={`error-message ${className}`}>{errorMessage}</div> : null;
};

export default ErrorMessage;
