
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.choose_an_item = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.choose_an_item || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.choose_an_item = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.choose_an_item, ...{
    "industry": "Industry",
    "student_network_looking_for": "Looking For",
    "student_network_interest": "Interests…",
    "class": "Programs..."
}
}
    