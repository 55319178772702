import angularModule from 'FrontRoyalApiErrorHandler/angularModule/scripts/front_royal_api_error_handler';
import template from 'FrontRoyalApiErrorHandler/angularModule/views/logged_out.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('apiErrorLoggedOut', [
    '$injector',

    /*
            This error modal is shown if a user is somehow signed out
            during a session and an api call fails with a 401 not_logged_in
            error.

            In the normal case, we show a sign-in form so the user can log
            back in and move on. For institutional users with a subdomain, we
            just give a link that will take them to their institutional login.

            If the user logs in with a username and password, then
            the request will be retried once
            they are logged in and they can continue on without reloading the page.  If
            they login with omniauth (oAuth or SAML), then they will be redirected to
            another page to login and then come back to this page.
        */

    $injector => {
        const $rootScope = $injector.get('$rootScope');
        const HttpQueue = $injector.get('HttpQueue');
        const institutionalSubdomain = $injector.get('institutionalSubdomain');
        const omniauth = $injector.get('omniauth');
        const $location = $injector.get('$location');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                resolve: '<',
                response: '<',
                reject: '<',
            },
            link(scope) {
                scope.institutionalSubdomain = institutionalSubdomain.id;

                // set the target so that after login we are forwarded back to the same place
                $location.search('target', 'NONE');

                const stopListeningForLoginSuccess = $rootScope.$on('validation-responder:login-success', () => {
                    stopListeningForLoginSuccess();
                    HttpQueue.retry(scope.response.config).then(
                        response => {
                            scope.resolve(response);
                        },
                        response => {
                            scope.reject(response);
                        },
                    );
                });

                scope.$on('$destroy', () => {
                    stopListeningForLoginSuccess();
                });

                scope.loginWithSaml = () => {
                    omniauth.loginWithProvider(institutionalSubdomain.id);
                };
            },
        };
    },
]);
