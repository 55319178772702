import moize from 'moize';
import { memo } from 'react';
import { FrontRoyalErrorBoundary } from './FrontRoyalErrorBoundary';
import { type ResetErrorBoundaryFunction, type FrontRoyalErrorBoundaryProps } from './FrontRoyalErrorBoundary.types';

/*
    This component is a bit hard to make sense of, but it creates the component that should be passed to
    FallbackComponent in the ErrorBoundary component from react-error-boundary.

    <ErrorBoundary FallbackComponent={getErrorBoundary({ ...errorActions, retry })}>

    In this case, we need create a function that will
        * accept the `error` and `resetErrorBoundary` parameters that are provided by react-error-boundary,
        * combine them with the provided
            *  `retry` function
            *  error configuration options (as described by the ErrorHandlingOptions type)
        * and then pass all of that stuff to the FrontRoyalErrorBoundary component.
*/
function componentFactory(opts: Omit<FrontRoyalErrorBoundaryProps, 'error' | 'resetErrorBoundary'>) {
    function ErrorBoundaryComponent({
        error,
        resetErrorBoundary,
    }: {
        error: unknown;
        resetErrorBoundary: ResetErrorBoundaryFunction;
    }) {
        return <FrontRoyalErrorBoundary error={error} resetErrorBoundary={resetErrorBoundary} {...opts} />;
    }

    return memo(ErrorBoundaryComponent) as typeof ErrorBoundaryComponent;
}

export const getErrorBoundary = moize(componentFactory) as typeof componentFactory;
