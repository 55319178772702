import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/progress_nav.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('progressNav', [
    '$injector',
    function factory() {
        return {
            restrict: 'E',
            scope: {
                steps: '<',
                onClick: '<',
                currentStepName: '<',
                stepsProgressMap: '<',
                submitStep: '@',
                percentComplete: '<',
                localeFile: '@',
            },
            templateUrl,
            link() {},
        };
    },
]);
