export default angular.module('FileTypeIcon', []).factory('FileTypeIcon', [
    () => {
        function getFontAwesomeIconClass(downloadType) {
            const type = angular.isDefined(downloadType) ? downloadType.toLowerCase() : '';
            switch (type) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'bmp':
                case 'svg':
                case 'gif':
                case 'psd':
                case 'sketch':
                case 'ai':
                    return 'fa-file-image fa-normal';
                case 'pdf':
                    return 'fa-file-pdf fa-normal';
                case 'zip':
                case 'rar':
                    return 'fa-file-archive fa-normal';
                case 'avi':
                case 'wmv':
                case 'mpg':
                case 'wma':
                case 'mov':
                case 'mkv':
                case 'mpeg':
                    return 'fa-file-video fa-normal';
                case 'ppt':
                case 'pptx':
                    return 'fa-file-powerpoint fa-normal';
                case 'xls':
                case 'xlsx':
                    return 'fa-file-excel fa-normal';
                case 'doc':
                case 'docx':
                    return 'fa-file-word fa-normal';
                case 'txt':
                case 'csv':
                    return 'fa-file-alt fa-normal';
                case 'wav':
                case 'mp3':
                    return 'fa-file-audio fa-normal';
                case 'htm':
                case 'html':
                case 'css':
                case 'js':
                case 'rb':
                case 'py':
                case 'cpp':
                case 'r':
                    return 'fa-file-code fa-normal';
                default:
                    return 'fa-file fa-normal';
            }
        }

        return {
            getFontAwesomeIconClass,
        };
    },
]);
