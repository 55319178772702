
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.api_error_handler = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.api_error_handler || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.api_error_handler = {...window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.api_error_handler, ...{
    "unable_to_contact_app_servers": "We were unable to contact our servers.",
    "something_went_wrong": "Something went wrong.  It's not your fault.\n\nOur engineers have been notified of the issue.\n\nClick to return to the dashboard.",
    "you_do_not_have_content_permissions": "You do not have permission to view this content.\n\nPlease click to return to the dashboard.",
    "servers_are_down": "Our servers are currently down.  Engineers are working to fix the issue.\n\nWe apologize for the inconvenience.",
    "you_do_not_have_permission": "You do not have permission to do that. Please click to continue.",
    "login_required": "Login Required",
    "server_error": "Server Error",
    "could_not_find": "We could not find what you were looking for.",
    "could_not_find_return_home": "We could not find what you were looking for.<br/><br/>Please click to return to the dashboard.",
    "something_is_missing": "Something is Missing",
    "something_went_wrong_check_back_later": "Something went wrong. It's not your fault.<br />Our engineers have been notified of the issue.<br />Please check back later."
}
}
    