
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.location_autocomplete = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.location_autocomplete || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.location_autocomplete = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.location_autocomplete, ...{
    "search_for_city": "Search for City ...",
    "address": "Address",
    "unable_to_load": "Warning: unable to load Google Services. You may need a VPN in order to use Quantic from your location.",
    "choose_a_city": "Please choose a city."
}
}
    