import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { type navigationHelper } from 'navigationHelper';
import nakeemaSrc from 'images/photos/nakeema-stefflbauer.png';
import andrewSrc from 'images/photos/andrew-chan.jpg';
import tomSrc from 'images/photos/tom-garvey.jpg';
import { buttonClasses } from './classes';

type StudentSpotlightProps = {
    loadRoute: ReturnType<typeof navigationHelper>['loadRoute'];
};

const SpotlightedStudents = [
    {
        name: 'Andrew Chan',
        company: 'VinFast',
        title: 'Global Charging Manager',
        education: 'Chinese University of Hong Kong',
        imageSrc: andrewSrc,
    },
    {
        name: 'Nakeema Stefflbauer, PhD',
        company: 'Frauenloop',
        title: 'Founder and CEO',
        education: 'Harvard University',
        imageSrc: nakeemaSrc,
    },
    {
        name: 'Tom Garvey',
        company: 'Google',
        title: 'Manager, Strategy and Operations',
        education: 'University of Oxford',
        imageSrc: tomSrc,
    },
] as const;

export const StudentSpotlight: FC<StudentSpotlightProps> = ({ loadRoute }) => {
    const { t } = useTranslation('back_royal');

    return (
        <>
            <h2 className="tw-mb-3.5 tw-ml-2.5 tw-mr-0 tw-mt-0 tw-uppercase tw-text-beige-beyond-dark">
                {t('featured_students.featured_students.student_spotlight')}
            </h2>
            <div className="tw-relative tw-flex tw-h-[425px] tw-flex-col tw-rounded tw-bg-white tw-pt-3.5">
                {SpotlightedStudents.map(({ imageSrc, name, company, title, education }) => (
                    <div
                        className="tw-mb-4 tw-flex ltr:tw-ml-3.5 ltr:tw-mr-5 rtl:tw-ml-5 rtl:tw-mr-3.5 lg:tw-mb-8"
                        key={name}
                    >
                        <img src={imageSrc} alt={name} className="tw-h-20 tw-w-20 tw-rounded-full" />
                        <div className="tw-basis-3/4 tw-pt-0.5 ltr:tw-ml-3.5 rtl:tw-mr-3.5">
                            <p className="tw-mb-1 tw-text-md tw-font-semibold tw-leading-4.5 tw-text-beige-for-text">
                                {name}
                            </p>
                            <p className="tw-mb-1 tw-text-sm tw-font-semibold tw-leading-4.5 tw-text-beige-beyond-dark">
                                {company}, {title}
                            </p>
                            <p className="tw-mb-1 tw-text-sm tw-font-normal tw-leading-4.5 tw-text-beige-beyond-dark">
                                {education}
                            </p>
                        </div>
                    </div>
                ))}
                <button
                    type="button"
                    className={clsx(buttonClasses, 'tw-absolute', 'tw-bottom-0')}
                    onClick={() => {
                        loadRoute('/student-network');
                    }}
                >
                    {t('featured_students.featured_students.preview_network')}
                </button>
            </div>
        </>
    );
};
