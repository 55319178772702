
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.selected_pills = window.Smartly.locales.modules.en.back_royal.front_royal_form.selected_pills || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.selected_pills = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.selected_pills, ...{
    "desc_input": "Use input to add custom {{type}}.",
    "desc": "Drag and drop to reorder. Use input to add custom {{type}}.",
    "up_to_num_input": "Use input to add <b>up to {{num}}</b> {{type}}.",
    "up_to_num": "Drag and drop to reorder. Use input to add <b>up to {{num}}</b> {{type}}.",
    "simple_instructions": "Drag and drop to reorder."
}
}
    