import { angularInjectorProvider } from 'Injector';
import { unsetCurrentUser } from 'Authentication';
import { useNavigationHelper } from 'FrontRoyalAngular';
import { useCallback } from 'react';

export function useRedirectToSignIn() {
    const { loadRoute } = useNavigationHelper();

    const unsetCurrentUserAndRedirectToSignIn = useCallback(() => {
        const $location = angularInjectorProvider.get<ng.ILocationService>('$location');
        const target = $location.url();
        const params = new URLSearchParams({ target });
        const route = `/sign-in?${params.toString()}`;

        // If we don't unset the current user, they could end up on the dashboard and just have the next
        // request fail
        unsetCurrentUser();
        loadRoute(route);
    }, [loadRoute]);

    return {
        unsetCurrentUserAndRedirectToSignIn,
    };
}
