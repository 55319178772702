import angularModule from 'FrontRoyalApiErrorHandler/angularModule/scripts/front_royal_api_error_handler';
import template from 'FrontRoyalApiErrorHandler/angularModule/views/failed_validation.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('apiErrorFailedValidation', [
    '$injector',

    /*

            This directive shows all of the validation errors that
            came back from the server.

            The user can dismiss the reject the original promise
            by clicking the continue button.  That will hide the
            modal, kick off any custom error handling for this
            request, and unfreeze HttpQueue so that further requests
            can be made.

            The original promise will never be resolved, since there
            is no way to fix it.
        */

    $injector => {
        const $sce = $injector.get('$sce');
        const HttpQueue = $injector.get('HttpQueue');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                response: '<',
                reject: '<',
            },
            link(scope) {
                // NOTE: this directive has not yet been translated, since it relies on
                // identifying error messages from server

                scope.buttonText = 'Continue';

                const errorType = scope.response.data?.meta?.error_type;
                let message = scope.response.data.message;

                if (errorType === 'exam_closed') {
                    const contentTranslationHelper = new TranslationHelper('lessons.shared.content_access_helper');
                    scope.formattedMessage = contentTranslationHelper.get('reason_message_lesson_exam_time_run_out');
                    scope.buttonText = contentTranslationHelper.get('reason_title_lesson_exam_time_run_out');
                } else if (message && message.substring(0, 17) === 'Validation failed') {
                    message = message.replace('Validation failed:', '<div><h4>Validation failed</h4><ul><li>');
                    message = message.replace(/,/g, '</li><li>');
                    message = `${message}</li></ul></div>`;

                    scope.formattedMessage = $sce.trustAsHtml(message);
                } else {
                    scope.formattedMessage = message || 'A 406 error occurred with no message.';
                }

                scope.dismiss = () => {
                    scope.reject(scope.response);

                    // it should be possible to make api calls again

                    HttpQueue.unfreezeAfterError(scope.response.config);

                    if (errorType === 'exam_closed') {
                        $rootScope.goHome();
                    }
                };
            },
        };
    },
]);
