import parse, { domToReact, Element, type HTMLReactParserOptions } from 'html-react-parser';
import { Icon, type GlobalIconName } from 'FrontRoyalIcon';
import { AnchorLink } from 'AnchorLink';

type ParseHtmlOptions = {
    onLinkClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href?: string) => void;
};

export const parseHtml = (html: string, { onLinkClick }: ParseHtmlOptions = {}) => {
    const options: HTMLReactParserOptions = {
        // eslint-disable-next-line consistent-return
        replace: domNode => {
            if (domNode instanceof Element) {
                const { type, name, attribs = {}, children } = domNode;
                if (type === 'tag' && name === 'icon') {
                    const { iconname, ...rest } = attribs;
                    return <Icon {...rest} iconName={iconname as GlobalIconName} />;
                }

                // FIXME: AnchorLink will not work outside of react-router, so if we ever need to use parseHtml outside of react-router,
                // we'll need to fix it up somehow.
                if (type === 'tag' && name === 'a' && children) {
                    const { href = '#', class: className, ...rest } = attribs;

                    return (
                        <AnchorLink {...rest} to={href} className={className} onClick={e => onLinkClick?.(e, href)}>
                            {domToReact(children, options)}
                        </AnchorLink>
                    );
                }
            }
        },
    };
    return parse(html, options);
};
