import { Brand } from 'AppBranding';
import angularModule from './front_royal_config_module';

// iguana service wrapper class
// currently only opening up /api/users.json which returns info about the current user
angularModule.factory('Config', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function factory() {
            this.setCollection('config');
            this.alias('Config');
            return {
                segmentioEnabled() {
                    return this.segmentio_disabled !== 'true';
                },
                httpQueueEventsEnabled() {
                    return this.http_queue_events_enabled === 'true';
                },
                forceIdpInitiatedLoginUrlFor(provider) {
                    return this.force_idp_initiated_login_urls[provider];
                },
                defaultPageMetadata() {
                    return this.default_page_metadata;
                },
                disableProgramSwitching() {
                    return this.disable_program_switching === 'true';
                },
                disableNominationsTab() {
                    return this.disable_nominations_tab === 'true';
                },
                chinaRegionMode() {
                    return this.china_region_mode === 'true';
                },
                creditCardPaymentFeeRate() {
                    return this.credit_card_payment_fee_rate;
                },
                gdprAppliesToUser() {
                    return this.gdpr_user === 'true';
                },
                enableLessonHexagonNavigation() {
                    return this.enable_lesson_hexagon_navigation === 'true';
                },
                appEnvType() {
                    const appEnvName = this.app_env_name || '';
                    if (appEnvName.match(/production/i)) {
                        return 'production';
                    }
                    if (appEnvName.match(/staging/i)) {
                        return 'staging';
                    }
                    return 'development';
                },
                isQuantic() {
                    return this.is_quantic === 'true';
                },
                segmentJsWriteKeyForCordova() {
                    return this.forceChinaEndpointRootOnCordova()
                        ? this.segment_china_js_write_key
                        : this.segment_js_write_key_for_request_destination;
                },
                // Cordova initially has the endpoint root hardcoded to quantic.edu.
                // If we detect that a Cordova user is in China, we switch them to using
                // China endpoints. On web, we trust the url that the user is on (in which
                // case endpoint root is derived from the url of the page and the config
                // is baked into preloadedConfig, which was aware of the host when it was written).
                forceChinaEndpointRootOnCordova() {
                    return (
                        this.force_redirect_china_app_domain === 'true' &&
                        this.chinaRegionMode() &&
                        this.appEnvType() !== 'development'
                    );
                },

                // For web we load Segment in ERB with a key based on the request destination (see preloadedConfig and is_quantic_cn?),
                // so we check that key to know if we're using Segment China.
                // For Cordova we load Segment on the client based on where the server said the request came from (see config_controller
                // and china_region_mode?), so we check that same logic to know if we're using Segment China.
                usingSegmentChina() {
                    if (window.CORDOVA) {
                        return this.segmentJsWriteKeyForCordova() === this.segment_china_js_write_key;
                    }

                    return this.segment_js_write_key_for_request_destination === this.segment_china_js_write_key;
                },

                enableQuanticCnImageRewriting() {
                    return this.enable_quantic_cn_image_rewriting === 'true';
                },

                isAlternativeStagingEnvironment() {
                    return this.is_alternative_staging_environment === 'true';
                },
                contentViewsRefreshUpdatedAt() {
                    return this.content_views_refresh_updated_at;
                },
                tutorBotOrigin() {
                    return this.tutor_bot_origin;
                },
                tutorBotCnOrigin() {
                    return this.tutor_bot_cn_origin;
                },
                tutorBotSmartlyOrigin() {
                    return this.tutor_bot_smartly_origin;
                },
                tutorBotDisabled() {
                    return this.disable_tutor_bot === 'true';
                },
                enableReviewPreviousMaterial() {
                    return this.enable_review_previous_material === 'true';
                },
                tutorBotAccessHeaders() {
                    return {
                        'CF-Access-Client-Id': this.cf_access_client_id,
                        'CF-Access-Client-Secret': this.cf_access_client_secret,
                    };
                },
                debugFrameNavPerf() {
                    return this.debug_frame_nav_perf === 'true';
                },
                biosigEnabled() {
                    // See app_config.rb for why we don't need to do a string comparison here.
                    return !!this.biosig_enabled;
                },
                flushEventsDelayMs() {
                    return this.flush_events_delay_ms;
                },
                preRecordedInterviewUrls() {
                    return {
                        [Brand.quantic]: this.pre_recorded_interview_url,
                        [Brand.valar]: this.pre_recorded_interview_url_valar,
                    };
                },
                sendbirdApplicationId() {
                    return this.sendbird_application_id;
                },
                showNonComponentizedFramesInEditor() {
                    return this.show_non_componentized_frames_in_editor === 'true';
                },
                studentDashboardVersion() {
                    return this.student_dashboard_version;
                },
            };
        });
    },
]);
