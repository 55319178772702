import { useEffect, useState } from 'react';
import { convertCurrentUserFromIguana, type CurrentUserIguanaObject } from 'Users';
import { useRootScope } from './useRootScope';

export function useCurrentUserIguanaObject(): CurrentUserIguanaObject | null {
    const $rootScope = useRootScope();
    const [_, setForceUpdate] = useState(0);

    useEffect(() => {
        const removeWatcher = $rootScope.$watchCollection('currentUser', () => {
            setForceUpdate(c => c + 1);
        });

        return removeWatcher;
    }, [$rootScope]);

    return $rootScope.currentUser ?? null;
}

export const useCurrentUser = () => {
    const $rootScope = useRootScope();
    const [currentUser, setCurrentUser] = useState(() => convertCurrentUserFromIguana($rootScope.currentUser ?? null));

    useEffect(() => {
        const removeWatcher = $rootScope.$watchCollection('currentUser', (user: CurrentUserIguanaObject) => {
            setCurrentUser(convertCurrentUserFromIguana(user));
        });

        return removeWatcher;
    }, [$rootScope]);

    return currentUser;
};

// We want to use this version of the hook when we really should be able to guarantee a user exists, i.e in an angular context
// and where we have already guaranteed the user exists in a parent component
export const useRequiredCurrentUser = () => {
    const user = useCurrentUser();

    if (!user) throw new Error('useCurrentUser returned null but user expected');

    return user;
};
