import cacheAngularTemplate from 'cacheAngularTemplate';
import angularModule from '../front_royal_form_module';
import template from '../../views/inputs/input_const_autosuggest.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('inputConstAutosuggest', [
    '$injector',
    function factory($injector) {
        const TranslationHelper = $injector.get('TranslationHelper');
        const isMobileMixin = $injector.get('isMobileMixin');
        const Locale = $injector.get('Locale');
        const safeApply = $injector.get('safeApply');

        return {
            restrict: 'E',
            require: '?^ngModel',
            scope: {
                ngModel: '<',
                translationFile: '<',
                searchConstant: '<',
                locale: '<?',
                placeholderText: '<?',
                isRequired: '<?',
                maxLength: '<?',
                maxItems: '<?',
                disabledCharacters: '<?',
            },
            templateUrl,

            link(scope, _elem, _attrs, formController) {
                scope.maxItems = angular.isDefined(scope.maxItems) ? scope.maxItems : 999;

                //----------------------
                // Initialization
                //----------------------

                // setup translation helper and form validation
                const translationHelper = new TranslationHelper(scope.translationFile);

                // Special handling of remaining characters <p>
                isMobileMixin.onLink(scope);

                // produce a list of values based on provided store factory
                scope.searchableValues = _.flattenDeep(_.values(_.chain($injector.get(scope.searchConstant)).value()))
                    .map(translationKey => ({
                        value: translationKey,
                        translation: translationHelper.get(translationKey),
                    }))
                    .sort((a, b) => a.translation.localeCompare(b.translation, translationHelper.getCurrentLanguage()));

                //----------------------
                // Text Searching
                //----------------------

                function containsText(text) {
                    if (!text) {
                        return false;
                    }

                    return _.chain(scope.ngModel)
                        .map(s => s.text && s.text.toLowerCase())
                        .includes(text.toLowerCase())
                        .value();
                }

                //----------------------
                // Text Sanitizing
                //----------------------

                // This is on scope for testing
                scope.removeDisabledCharacters = input => {
                    if (!input) {
                        return null;
                    }
                    if (!scope.disabledCharacters) {
                        return input;
                    }

                    let disabledCharRegExp;
                    if (_.isArray(scope.disabledCharacters)) {
                        disabledCharRegExp = new RegExp(`[${scope.disabledCharacters.join('')}]`, 'g');
                    }

                    return input.replace(disabledCharRegExp, '');
                };

                //----------------------
                // Selectize methods
                //----------------------

                // put on scope for testing
                scope.addItemToModel = input => {
                    if (
                        input.length > 0 &&
                        input.length <= scope.maxLength &&
                        scope.ngModel.length < scope.maxItems &&
                        !containsText(input)
                    ) {
                        // support new suggestable entries or simple strings
                        scope.ngModel.push({
                            text: input,
                            locale: Locale.activeCode,
                        });
                    }

                    scope.text = null;
                    scope.textLength = 0;
                };

                // so that we can check the reference in the template for $invalid
                scope.formController = formController;

                scope.$watch('textLength', () => {
                    formController.$setValidity('select', !scope.textLength || scope.textLength <= scope.maxLength);
                });

                scope.textSelectizeConfig = {
                    create: true,
                    onItemAdd(input) {
                        scope.addItemToModel(scope.removeDisabledCharacters(input));
                    },
                    onType(input) {
                        scope.textLength = input.length;
                        // there was a slight delay between what is typed and what was showing up in the template
                        safeApply(scope);
                    },
                    onBlur() {
                        // selectize clears its input on blur but not the model
                        scope.text = null;
                        scope.textLength = 0;
                    },
                    maxItems: 1,
                    valueField: 'translation',
                    labelField: 'translation',

                    // We have to turn off 'sortField' here because selectize does not support
                    // Chinese. See comment near `Selectize.defaults.sortField in
                    // FrontRoyal/angularModuleAngular/index.js
                    // We're doing the sorting up above with localeCompare
                    sortField: '$order',
                    searchField: ['translation'],
                    placeholder: scope.placeholderText,
                };
            },
        };
    },
]);
