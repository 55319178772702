import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/inputs/organization_autocomplete.html';
import autoSuggestDropdownTemplate from 'FrontRoyalForm/angularModule/views/inputs/add_an_item_autosuggest_dropdown.html';
import autoSuggestItemTemplate from 'FrontRoyalForm/angularModule/views/inputs/organization_autosuggest_item.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

// Because of the way we wrap the ng-include that uses this in a <script> tag, it works to
// give it a specific name and hardcode that name in the template, but
// doesn't work to save a generated name to a variable, so we pass in a specific
// name for the template as the second argument to cacheAngularTemplate, which is
// not standard practice for us.
cacheAngularTemplate(angularModule, 'organizationAutocomplete/autosuggestDropdown.html', autoSuggestDropdownTemplate);
const autoSuggestItemTemplateUrl = cacheAngularTemplate(angularModule, autoSuggestItemTemplate);

angularModule.directive('organizationAutocomplete', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const Locale = $injector.get('Locale');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                ngModel: '=',
                optionsType: '<',
                locale: '<?',
                tabindex: '<?',
                placeholderText: '<?',
                isRequired: '<?',
                shouldDisable: '<?',
                suggestFilters: '<?',
            },
            link(scope) {
                scope.shouldDisable = scope.shouldDisable || false;
                FormHelper.supportAutoSuggestOptions(scope);

                scope.isRequired = angular.isDefined(scope.isRequired) ? scope.isRequired : true;
                scope.locale = angular.isDefined(scope.locale) ? scope.locale : Locale.activeCode;

                scope.autoSuggestTemplateUrl = autoSuggestItemTemplateUrl;

                scope.getOptionsForOrganizations = searchText =>
                    scope.getOptionsForType(scope.optionsType, searchText, scope.locale, scope.suggestFilters);

                scope.ngModel = scope.ngModel || {
                    text: '',
                    locale: scope.locale,
                };

                scope.onSelect = item => {
                    // Currently, the `country` column is only relevant to educational organizations.
                    if (item.country) {
                        scope.ngModel.country = item.country;
                    }
                };
            },
        };
    },
]);
