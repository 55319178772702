import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/share_buttons.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('shareButtons', [
    '$injector',
    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const ShareService = $injector.get('Navigation.ShareService');
        const SiteMetadata = $injector.get('SiteMetadata');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $window = $injector.get('$window');

        return {
            restrict: 'E',
            scope: {
                titleKey: '@',
                descriptionKey: '@',
                campaignDescription: '@',
                emailTitleKey: '@',
                emailDescriptionKey: '@',
                canonicalUrl: '<',
            },
            templateUrl,
            link(scope) {
                scope.buttons = [
                    {
                        name: 'facebook',
                        icon: 'fab fa-no-block-fb-official',
                    },
                    {
                        name: 'linkedin',
                        icon: 'fab fa-no-block-li',
                    },
                    {
                        name: 'twitter',
                        icon: 'fab fa-no-block-tw',
                    },
                    {
                        name: 'email',
                        icon: 'fas fa-envelope',
                    },
                ];

                const translationHelper = new TranslationHelper('front_royal_form.share_buttons');

                const contentItem = {
                    title: translationHelper.get(scope.titleKey),
                    description: translationHelper.get(scope.descriptionKey),
                    campaignDescription: scope.campaignDescription,
                    email: {
                        title: translationHelper.get(scope.emailTitleKey),
                        description: translationHelper.get(scope.emailDescriptionKey, {
                            canonicalUrl: $window.ENDPOINT_ROOT + scope.canonicalUrl,
                            signature: $rootScope.currentUser.name,
                        }),
                    },
                    entity_metadata: {
                        title: translationHelper.get(scope.titleKey),
                        description: translationHelper.get(scope.descriptionKey),
                        tweet_template: translationHelper.get(scope.descriptionKey),
                        canonical_url: scope.canonicalUrl,
                    },
                };

                scope.share = function share(provider) {
                    contentItem.utmCampaign = provider;
                    ShareService.openShareWindow(
                        provider,
                        SiteMetadata.contentCompletedShareInfo($rootScope.currentUser, contentItem),
                    );
                };
            },
        };
    },
]);
