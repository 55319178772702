import clsx from 'clsx';

export const buttonClasses = clsx([
    'tw-flex',
    'tw-h-7.5',
    'tw-self-center',
    'tw-items-center',
    'tw-mx-auto',
    'tw-mb-[25px]',
    'tw-bg-white',
    'tw-rounded-[50px]',
    'tw-text-xxs',
    'tw-text-beige-for-text',
    'tw-leading-3',
    'tw-uppercase',
    'tw-py-2.5',
    'tw-px-[25px]',
    'tw-shadow-[0_5px_10px_0_rgb(0,0,0,.1)]',
    'tw-border',
    'tw-border-solid',
    'tw-border-beige',
    'tw-font-semibold',
    'tw-transition-shadow',
    'tw-duration-200',
    'tw-ease-in-out',
    'hover:tw-shadow-[0_5px_15px_0_rgb(0,0,0,.2)]',
]);
