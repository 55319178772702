
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.write_text_about = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.write_text_about || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.write_text_about = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.write_text_about, ...{
    "num_remaining": "Max {{maxNum}} ({{num}} remaining)",
    "please_fill_in_english": "Please answer in English",
    "please_complete": "Answers must be between {{minNum}} and {{maxNum}} characters. ({{chars}})"
}
}
    