
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_form = window.Smartly.locales.modules.en.back_royal.front_royal_form || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.add_an_item = window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.add_an_item || {};
window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.add_an_item = {...window.Smartly.locales.modules.en.back_royal.front_royal_form.inputs.add_an_item, ...{
    "add": "Add",
    "add_skill_interest": "add a skill / interest",
    "done": "Done",
    "tip": "<strong>TIP:</strong> Begin each line with an action verb for greatest impact.",
    "examples": "<strong>EXAMPLES:</strong> Led, Built, Grew, Launched, Implemented, Initiated, Managed, Designed, Evaluated, Researched, Advised, Identified, Awarded.",
    "exceeds_max_length": "Exceeds maximum character length"
}
}
    