import angularModule from 'FrontRoyalApiErrorHandler/angularModule/scripts/front_royal_api_error_handler';
import template from 'FrontRoyalApiErrorHandler/angularModule/views/fatal.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import reloadWindow from 'reloadWindow';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('apiErrorFatal', [
    '$injector',

    /*

            This directive shows a message to the user.

            The user can refresh the entire window by clicking
            the continue button.  This will also reject the original
            promise, kicking off any custom error handling.

            The original promise will never be resolved, since there
            is no way to fix it.
        */

    $injector => {
        const EventLogger = $injector.get('EventLogger');
        const FormatsText = $injector.get('FormatsText');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                message: '<',
                reloadPath: '<',
            },
            link(scope) {
                scope.formattedMessage = FormatsText.processMarkdown(scope.message, true);

                scope.continue = () => {
                    // Do not reject the promise, since we never want
                    // the blocking alert modal to go away, even during
                    // the refresh
                    //
                    scope.reload(scope.reloadPath);
                };

                // separate method that can be mocked in tests
                scope.reload = reloadPath => {
                    // Do not use $location.url() because we actually want
                    // the browser to reload, hoping that might help.
                    if (reloadPath) {
                        reloadWindow({ label: 'fatal_dir', path: reloadPath, EventLogger });
                    } else {
                        reloadWindow({ label: 'fatal_dir', path: reloadPath, EventLogger });
                    }
                };
            },
        };
    },
]);
