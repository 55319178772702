/*

    This is a bit of a crazy directive.  It compiles an element in the context
    of the scope in which it was created, but places that element up in the dom
    hierarchy, inside of the app-shell, so that it can be positioned at the bottom
    of the screen.

    When the parent scope that created it is destroyed, the content is cleared
    out of the app-shell.

    Set the footer content by adding something like this to a template:

        <front-royal-footer content-el-selector=".lesson-content-container">
            Some content to be moved into the app-footer, compiled in
            the context of this template.
        </front-royal-footer>

    Warning: ng-if may behave oddly if used inside there

    the attr 'content-el-selector' should be a global
        selector (i.e. we use $(".lesson-content-container")) to find the
        element in this example.  The selector should refer to the element
        that holds the content that will scroll behind the footer.

*/
export default angular.module('FrontRoyal.Footer', []).directive('frontRoyalFooterContent', [
    '$injector',
    function factory($injector) {
        const $timeout = $injector.get('$timeout');
        const $compile = $injector.get('$compile');
        const SuperModel = $injector.get('SuperModel');

        /*
                There will be one global instance of the FrontRoyalFooter class.
                It handles swapping the content that goes in the global footer element.

                One bit of trickiness is that it has to keep history, just for the
                special case of concept completed screens.  In that case, show_frames_player
                sets the footer content, but then the concept_completed modal replaces it.
                When the modal goes away, we need to bring back the original content.

                Any time an instance of the front-royal-footer-content directive is
                created, it will send its content to the global FrontRoyalFooter instance
                for display.  Any time the scope associated with that directive is
                destroyed, it will tell the global FrontRoyalFooter instance to
                destroy the scope.

            */
        const FrontRoyalFooter = SuperModel.subclass(function () {
            // adapted from: http://stackoverflow.com/questions/143815/how-to-determine-using-javascript-if-html-element-has-overflowing-content
            function isOverflowing(el) {
                const curOverflow = el.css('overflow-y');
                if (!curOverflow || curOverflow === 'visible') {
                    el.css('overflow-y', 'hidden');
                }
                const htmlElem = el.get(0);
                const hasOverflow = htmlElem.clientHeight < htmlElem.scrollHeight;
                el.css('overflow-y', curOverflow);
                return hasOverflow;
            }

            Object.defineProperty(this.prototype, '_pageContentIsHidden', {
                get() {
                    const // prevent duplicate selector queries
                        el = $('.app-main-container');

                    const hasOverflow = isOverflowing(el);
                    const combinedHeight = el.scrollTop() + el.height();
                    const isScrolledToBottom = combinedHeight >= el.get(0).scrollHeight;

                    return hasOverflow && !isScrolledToBottom;
                },
            });

            // the content on the page that can scroll up and down behind the footer.
            Object.defineProperty(this.prototype, '_currentPageContentEl', {
                get() {
                    return this._currentFooterContent ? this._currentFooterContent.pageContentEl : undefined;
                },
            });

            return {
                initialize() {
                    this._history = [];

                    // the outer wrapper on the footer, which is hidden when nothing
                    // is in it and shown when something is in it
                    this._initialFooterClasses = $('.front-royal-footer').attr('class');
                },

                setContent(scope, footerContentEl, attrs) {
                    const newFooterContentEntry = {
                        id: scope.$id,
                        footerContentEl,

                        // note: these values pulled from attrs are
                        // not dynamic right now. If the class
                        // value on the front-royal-footer-content element
                        // changes, it will not be reflected here.
                        pageContentEl: $(attrs.contentElSelector),
                        classString: attrs.class,
                    };
                    this._history.push(newFooterContentEntry);
                    this._setCurrentContent(newFooterContentEntry);
                },

                removeContent(scope) {
                    // if this is the current content, remove it from the screen
                    if (this._currentFooterContent && scope.$id === this._currentFooterContent.id) {
                        this._removeFooterContentFromScreen();
                    }

                    this._removeFooterContentFromHistory(scope);

                    // if there is previous footer content whose
                    // scope has not been destroyed, show it
                    const previousFooterContentEntry = _.last(this._history);
                    if (previousFooterContentEntry) {
                        this._setCurrentContent(previousFooterContentEntry);
                    } else {
                        $('.front-royal-footer').hide();
                    }
                },

                _setCurrentContent(footerContentEntry) {
                    this._removeFooterContentFromScreen();

                    // if this gets called twice, we need to cancel the prev timeout or
                    // else we hit an edge case where content gets added twice into the footer
                    if (angular.isDefined(this._setContentTimeout)) {
                        $timeout.cancel(this._setContentTimeout);
                        this._setContentTimeout = undefined;
                    }

                    // we need a timeout after calling empty() on the footer content,
                    // because otherwise click handlers within the content sometimes
                    // get removed.  I don't understand, but I saw this happening when
                    // the chapter_completed screen was popping up at the end of a lesson (worked
                    // fine however, if you refreshed that same page)
                    this._setContentTimeout = $timeout(
                        () => {
                            const footerEl = $('.front-royal-footer');
                            // if, during the timeout, this content was removed from the
                            // history, then do not show it
                            if (!this._history.includes(footerContentEntry)) {
                                return;
                            }
                            this._setContentTimeout = undefined;
                            this._currentFooterContent = footerContentEntry;
                            const classString = this._currentFooterContent.classString;

                            // add any custom css classes to the footer
                            const classes = (classString || '').split(' ');
                            classes.forEach(cl => {
                                footerEl.addClass(cl);
                            });

                            // add a padding to the bottom of the target content
                            // so that it will show up above the footer when
                            // scrolled all the way down
                            this._currentPageContentEl.addClass('front-royal-footer-content');

                            // add footer content to DOM and display, once all styling is complete.
                            footerEl.show();
                            footerEl.find('.content-wrapper').append(this._currentFooterContent.footerContentEl);

                            // We don't need to digest here because all the classes and dom mutations
                            // are being done by hand.  Nothing relies on scope properties
                        },
                        0,
                        false,
                    );
                },

                _removeFooterContentFromScreen() {
                    const footerEl = $('.front-royal-footer');
                    const footerWrapper = footerEl.find('.content-wrapper');

                    // do not use jquery empty() or remove() here, because we
                    // do not want the scope to be destroyed.  The scope will be
                    // destroyed eventually so long as we bring this footer
                    // back from the history and later remove it normally
                    if (footerWrapper[0]) {
                        footerWrapper[0].innerHTML = '';
                    }
                    footerEl.attr('class', this._initialFooterClasses);
                },

                _removeFooterContentFromHistory(scope) {
                    const history = this._history;
                    for (let i = 0; i < history.length; i++) {
                        if (history[i].id === scope.$id) {
                            history.splice(i, 1);
                            break;
                        }
                    }
                },
            };
        });

        const globalFooter = new FrontRoyalFooter();

        // the link function for the front-royal-footer-content directive
        const link = (clonedElem, scope, elem, attrs) => {
            // when a front-royal-footer-content is included in a template,
            // 1. create a childScope (non-isolate),
            // 2. compile the provided html against that new
            //      scope (see compile function below
            //      where clonedElem is created for more
            //      details about that)
            // 3. place the newly compiled element up in the app-shell
            const newScope = scope.$new();
            $compile(clonedElem)(newScope, newElement => {
                globalFooter.setContent(newScope, newElement, attrs);
            });

            // cleanup on destroy
            scope.$on('$destroy', () => {
                globalFooter.removeContent(newScope);
            });
        };

        return {
            restrict: 'E',
            replace: false,
            compile(elem) {
                // clone the element so that we can keep
                // a copy of the un-compiled content
                const clonedElem = $('<div></div>');
                clonedElem.append(elem.children().remove());
                return link.bind(undefined, clonedElem);
            },
        };
    },
]);
