/**
 * useAnimationCompletion
 *
 * Manages animation state to ensure it completes its current iteration before stopping. An example use case
 * is an element that animates on hover. If a user hovers over the element and then quickly moves the cursor
 * away, without this hook the element would stop animating and abruptly return to its initial state. With this
 * hook, the element will complete its current animation cycle before stopping. Silky smooth.
 * See an example of this hook in action in the FileDropzone component.
 *
 * Options:
 * - duration: Duration of one animation cycle in milliseconds
 *
 * Returns an object with:
 * - isAnimating: Current animation state
 * - startAnimation: Function to start the animation
 * - stopAnimation: Function to stop the animation after current cycle completes
 *
 * Usage:
 * const { isAnimating, startAnimation, stopAnimation } = useAnimationCompletion({ duration: 2000 });
 */

import { useState, useCallback, useRef, useEffect } from 'react';

interface AnimationCompletionOptions {
    duration: number; // Animation duration in milliseconds
}

export const useAnimationCompletion = ({ duration }: AnimationCompletionOptions) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const animationStartTimeRef = useRef<number | null>(null);
    const animationFrameRef = useRef<number | null>(null);
    const stopTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const startAnimation = useCallback(() => {
        // Cancel any pending stop
        if (stopTimeoutRef.current) {
            clearTimeout(stopTimeoutRef.current);
            stopTimeoutRef.current = null;
        }

        if (!isAnimating) {
            setIsAnimating(true);
            animationStartTimeRef.current = performance.now();
        }
    }, [isAnimating]);

    const stopAnimation = useCallback(() => {
        if (isAnimating) {
            const currentTime = performance.now();
            const elapsedTime = currentTime - (animationStartTimeRef.current || 0);
            const remainingTime = duration - (elapsedTime % duration);

            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }

            animationFrameRef.current = requestAnimationFrame(() => {
                stopTimeoutRef.current = setTimeout(() => {
                    setIsAnimating(false);
                    animationStartTimeRef.current = null;
                    stopTimeoutRef.current = null;
                }, remainingTime);
            });
        }
    }, [isAnimating, duration]);

    useEffect(
        () => () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
            if (stopTimeoutRef.current) {
                clearTimeout(stopTimeoutRef.current);
            }
        },
        [],
    );

    return { isAnimating, startAnimation, stopAnimation };
};
