import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import moment from 'moment-timezone';
import template from 'FrontRoyalForm/angularModule/views/inputs/choose_a_date.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('chooseADate', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                allowCurrent: '<?',
                allowFuture: '<?',
                monthTabindex: '<?',
                yearTabindex: '<?',
                currentTabindex: '<?',
                maxDate: '<?',
                minDate: '<?',
                dayStrategy: '@?',
            },
            require: '?^ngModel',
            templateUrl,

            link(scope, elem, attrs, modelController) {
                //---------------------------
                // Initialization
                //---------------------------

                scope.checkboxProxy = {};

                scope.monthOptions = [];
                const translatedMonthNames = moment.monthsShort();

                for (let i = 0; i < 12; i++) {
                    scope.monthOptions.push({
                        id: i,
                        name: translatedMonthNames[i],
                    });
                }

                scope.yearOptions = [];
                const maxYear = scope.allowFuture ? new Date().getUTCFullYear() + 2 : new Date().getUTCFullYear();
                for (let j = maxYear; j >= 1940; j--) {
                    scope.yearOptions.push({
                        id: j,
                        name: j,
                    });
                }

                // Initialize the selects
                if (scope.ngModel) {
                    const tempDate = new Date(scope.ngModel);
                    scope.selectedMonthOption = {
                        id: tempDate.getUTCMonth(),
                        name: tempDate.getUTCMonth() + 1,
                    };
                    scope.selectedYearOption = {
                        id: tempDate.getUTCFullYear(),
                        name: tempDate.getUTCFullYear(),
                    };
                } else if (scope.ngModel === null) {
                    scope.current = true;
                }

                // Add support for custom form validation
                FormHelper.supportNullableDateValidation(scope, attrs, modelController);

                //---------------------------
                // Input Handling
                //---------------------------

                scope.toggleCurrent = () => {
                    if (scope.current) {
                        scope.ngModel = null; // backend model shows that null represents a current date
                        scope.updateValidity();

                        if (scope.maxDate) {
                            modelController.$setValidity('maxDate', true);
                        }

                        if (scope.minDate) {
                            modelController.$setValidity('minDate', true);
                        }
                    } else {
                        scope.updateDate();
                    }
                };

                scope.updateDate = () => {
                    if (!scope.selectedMonthOption || !scope.selectedYearOption) {
                        return;
                    }

                    if (scope.dayStrategy === 'beginningOfMonth') {
                        scope.ngModel = Date.UTC(scope.selectedYearOption.id, scope.selectedMonthOption.id, 1);
                    } else if (scope.dayStrategy === 'endOfMonth') {
                        scope.ngModel = moment(Date.UTC(scope.selectedYearOption.id, scope.selectedMonthOption.id, 28))
                            .utc()
                            .endOf('month')
                            .toDate();
                    } else {
                        scope.ngModel = Date.UTC(scope.selectedYearOption.id, scope.selectedMonthOption.id);
                    }

                    if (scope.maxDate) {
                        modelController.$setValidity('maxDate', scope.ngModel < scope.maxDate);
                    }

                    if (scope.minDate) {
                        modelController.$setValidity('minDate', scope.ngModel > scope.minDate);
                    }

                    scope.updateValidity();
                };

                scope.$watch('maxDate', () => {
                    if (scope.maxDate === null) {
                        modelController.$setValidity('maxDate', true);
                    } else if (scope.maxDate === undefined) {
                        // do nothing
                    } else {
                        scope.updateDate();
                    }
                });

                scope.$watch('minDate', () => {
                    if (scope.minDate === null) {
                        modelController.$setValidity('minDate', true);
                    } else if (scope.minDate === undefined) {
                        // do nothing
                    } else {
                        scope.updateDate();
                    }
                });
            },
        };
    },
]);
