
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.featured_students = window.Smartly.locales.modules.en.back_royal.featured_students || {};
window.Smartly.locales.modules.en.back_royal.featured_students.featured_students = window.Smartly.locales.modules.en.back_royal.featured_students.featured_students || {};
window.Smartly.locales.modules.en.back_royal.featured_students.featured_students = {...window.Smartly.locales.modules.en.back_royal.featured_students.featured_students, ...{
    "preview_network": "preview the network",
    "student_spotlight": "student spotlight",
    "meet_your_class": "meet your class",
    "view_classmates": "view all classmates",
    "tooltip_message": "We think you might be interested in connecting with these classmates."
}
}
    