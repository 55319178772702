import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/invalid_fields_links.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('invalidFieldsLinks', [
    '$injector',
    function factory($injector) {
        const scrollHelper = $injector.get('scrollHelper');

        return {
            restrict: 'E',
            scope: {
                invalidFields: '<',
                errorMessageKey: '<?',
                findElement: '<?',
            },
            templateUrl,
            link(scope) {
                scope.getLabelText = invalidFields => {
                    const fieldModels = [];
                    const ngModelsToLinkToHeading = ['careerProfile.program_type', 'skillsValid'];

                    invalidFields.forEach((field, index) => {
                        const element = field.$$element;
                        const ngModel = element.attr('ng-model');
                        let label;
                        let labelText;

                        // do not use elements that:
                        // - are duplicate ng-models such as checkboxes or radios
                        // - are directives with ".ng-invalid" and thusly have a child input of ".ng-invalid"
                        // - are hidden with CSS such as duplicate fields that are for mobile only but NOT selectize and NOT a hidden input
                        // - are a child of a directive hidden by CSS such as those that are for mobile only
                        if (
                            (_.includes(fieldModels, ngModel) && (element.is(':checkbox') || element.is(':radio'))) ||
                            element.find('.ng-invalid')[0] ||
                            (element.css('display') === 'none' &&
                                !element.is('selectize') &&
                                element.attr('type') !== 'hidden') ||
                            element.parent().css('display') === 'none'
                        ) {
                            invalidFields.splice(index, 1);
                        } else {
                            // get the label
                            if (_.includes(ngModelsToLinkToHeading, ngModel)) {
                                label = $('[name="application-header-text"]');

                                // if we're in the admin, grab the edit profile button instead
                                if (!label.length) {
                                    label = $('.edit-profile-btn-group-container button.active');
                                }
                            } else if (element.is(':radio') || element.is(':checkbox') || element.is(':text')) {
                                label = element.closest('.form-group').find('.control-title');

                                // if it's a single checkbox with no control-title, get the adjacent span
                                if (!label.length) {
                                    label = element.next('span');
                                }
                            }

                            if (!label || !label.length) {
                                // if user input an optional(not required) field, but the input value
                                // does not meet the requirements(see form_helper.js -> invalidLanguage()),
                                // need to set the findElement = 'label' to make sure the field title can be shown to user.
                                label = element.closest('.form-group').find(scope.findElement || 'label.required');

                                if (!label.length) {
                                    labelText = element.attr('placeholder');

                                    // maybe it's a hidden field without an adjacent label
                                    if (!labelText && element.attr('type') === 'hidden') {
                                        label = element.prev('.invalid-warning');
                                    }

                                    // maybe it has a paragraph that describes it
                                    if (
                                        (labelText && element.is('add-an-item')) ||
                                        (!labelText && element.is('select') && element.siblings('p'))
                                    ) {
                                        labelText = element.siblings('p').text();
                                    }

                                    // maybe it's a select without a descriptive paragraph
                                    if (!labelText && element.is('select')) {
                                        labelText = element.find('option:disabled:eq(0)').text();
                                    }

                                    // maybe it's a selectize
                                    if (!labelText && element.is('selectize')) {
                                        labelText = element.selectize()[0].selectize.$control_input.attr('placeholder');
                                    }

                                    // set the labelText
                                    invalidFields[index].labelText = labelText;
                                }
                            }

                            if (label.length) {
                                // set the label
                                invalidFields[index].label = label;
                            }
                        }

                        // after all comparisons, push the ng-model
                        if (ngModel) {
                            fieldModels.push(ngModel);
                        }
                    });
                };

                scope.sortInvalidFields = invalidFields => {
                    // sort them based on their position on the page
                    invalidFields.sort((a, b) => {
                        let aElem = a.$$element;
                        let bElem = b.$$element;

                        // 'selectize' is display: none, so define an element we can see
                        if (aElem.is('selectize')) {
                            aElem = aElem.siblings('.selectize-control');
                        }

                        // same as above
                        if (bElem.is('selectize')) {
                            bElem = bElem.siblings('.selectize-control');
                        }

                        // hidden fields that aren't selectize need a visible element
                        if (aElem.css('display') === 'none') {
                            aElem = a.label || aElem.parents('.form-group');
                        }

                        // same as above
                        if (bElem.css('display') === 'none') {
                            bElem = b.label || bElem.parents('.form-group');
                        }

                        // start comparing tops
                        const aTop = aElem.offset().top;
                        const bTop = bElem.offset().top;

                        if (aTop < bTop) {
                            return -1;
                        }
                        if (aTop > bTop) {
                            return 1;
                        }
                        // if they are the same top, compare left
                        return aElem.offset().left - bElem.offset().left;
                    });
                };

                scope.$watchCollection('invalidFields', () => {
                    scope.getLabelText(scope.invalidFields);
                    scope.invalidFieldsProxy = [...scope.invalidFields];
                    scope.sortInvalidFields(scope.invalidFieldsProxy);
                });

                scope.scrollToField = field => {
                    let element = field.label || field.$$element;

                    // 'selectize' is display: none, so define an element we can see
                    if (element.is('selectize')) {
                        element = element.siblings('.selectize-control');
                    }

                    scrollHelper.scrollToElement(element, true, -10);
                };

                scope.labelText = field => (field.label && field.label.text()) || field.labelText;
            },
        };
    },
]);
