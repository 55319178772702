/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { careerProfilesApi } from 'CareerProfiles';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { type navigationHelper } from 'navigationHelper';
import defaultAvatarSrc from 'vectors/profile_photo_default.svg';
import { buttonClasses } from './classes';

const { useGetRecommendedClassmatesQuery } = careerProfilesApi;

type MeetYourClassProps = {
    loadRoute: ReturnType<typeof navigationHelper>['loadRoute'];
};

export const MeetYourClass: FC<MeetYourClassProps> = ({ loadRoute }) => {
    const { data, isLoading } = useGetRecommendedClassmatesQuery();
    const { t } = useTranslation('back_royal');

    return (
        <>
            <h2 className="tw-mb-3.5 tw-ml-2.5 tw-mr-0 tw-mt-0 tw-uppercase tw-text-beige-beyond-dark">
                {t('featured_students.featured_students.meet_your_class')}
            </h2>
            <div className="tw-relative tw-flex tw-h-[425px] tw-flex-col tw-justify-between tw-rounded tw-bg-white tw-px-3 tw-pt-2.5">
                {isLoading ? (
                    <FrontRoyalSpinner className="no-delay tw-mx-auto tw-my-[180px]" />
                ) : (
                    <>
                        <div>
                            {data?.map(({ jobTitle, name, userId, avatarUrl, company }) => (
                                <div
                                    className="tw-mb-1.5 tw-flex tw-cursor-pointer tw-gap-3 tw-p-1 hover:tw-rounded hover:tw-bg-black/[.03]"
                                    key={userId}
                                    onClick={() => {
                                        loadRoute(`/student-network?ids=${userId}`);
                                    }}
                                >
                                    <img
                                        src={avatarUrl || defaultAvatarSrc}
                                        alt={name || 'user avatar'}
                                        className="tw-h-[43px] tw-w-[43px] tw-rounded tw-object-cover"
                                    />
                                    <div className="tw-flex tw-flex-initial tw-basis-[222px] tw-flex-col lg:tw-basis-[270px]">
                                        <p className="tw-mb-0.5 tw-pt-0.5 tw-text-sm tw-font-semibold tw-capitalize tw-leading-4.5 tw-text-beige-for-text">
                                            {name}
                                        </p>
                                        {jobTitle ? (
                                            <p className="tw-m-0 tw-text-xxs tw-leading-3 tw-text-beige-beyond-dark">
                                                {company ? `${company}, ${jobTitle}` : jobTitle}
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="tw-relative tw-bottom-0">
                            <Tooltip
                                title={t('featured_students.featured_students.tooltip_message')}
                                arrow
                                placement="top"
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-tooltip': {
                                            font: 'normal 12px ProximaNovaSoft',
                                        },
                                    },
                                }}
                            >
                                <i className="fa-light fa-circle-info tw-absolute tw-left-3.5 tw-top-[7px] tw-flex tw-text-beige-middark" />
                            </Tooltip>
                            <button
                                type="button"
                                className={clsx(buttonClasses, 'tw-mt-0')}
                                onClick={() => {
                                    loadRoute('/student-network?myClass');
                                }}
                            >
                                {t('featured_students.featured_students.view_classmates')}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
