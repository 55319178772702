import angularModule from 'FrontRoyalConfig/angularModule/scripts/front_royal_config_module';

angularModule.factory('ConfigInterceptor', [
    '$injector',

    $injector => ({
        response(response) {
            let messages;

            // this try catch just catchess null refs
            try {
                messages = response.data.meta.push_messages.config;
            } catch (e) {
                return response;
            }

            if (messages) {
                try {
                    $injector.get('ConfigFactory').pushUpdatedPropertiesOntoConfig(messages);
                } catch (e) {
                    return response;
                }
            }

            return response;
        },
    }),
]);

angularModule.config([
    '$httpProvider',
    $httpProvider => {
        $httpProvider.interceptors.push('ConfigInterceptor');
    },
]);
