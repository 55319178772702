import angularModule from 'FrontRoyalApiErrorHandler/angularModule/scripts/front_royal_api_error_handler';
import template from 'FrontRoyalApiErrorHandler/angularModule/views/internal_server_error.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('notFoundError', [
    '$injector',

    $injector => {
        const $sce = $injector.get('$sce');
        const HttpQueue = $injector.get('HttpQueue');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $rootScope = $injector.get('$rootScope');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                response: '<',
                reject: '<',
                message: '<',
            },
            link(scope) {
                const translationHelper = new TranslationHelper('front_royal_api_error_handler.api_error_handler');

                if (!scope.message) {
                    scope.message = translationHelper.get('could_not_find_return_home');
                }

                scope.formattedMessage = $sce.trustAsHtml(scope.message);

                scope.dismiss = () => {
                    scope.reject(scope.response);

                    // it should be possible to make api calls again
                    HttpQueue.unfreezeAfterError(scope.response.config);

                    $rootScope.goHome();
                };
            },
        };
    },
]);
