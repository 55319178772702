import { targetBrandConfig } from 'AppBranding';
import { useConfig } from 'FrontRoyalConfig';
import { useMemo } from 'react';
import { useCurrentUser } from './useCurrentUser';

export function useTargetBrandConfig() {
    const currentUser = useCurrentUser();
    const config = useConfig();
    return useMemo(() => targetBrandConfig(currentUser, config), [currentUser, config]);
}
