import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/inputs/validate_number.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('validateNumber', [
    '$injector',
    function factory() {
        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                inputName: '@',
                min: '<',
                max: '<',
                placeholder: '<?',
                allowFloat: '<?',
                allowZero: '<?',
                isValid: '=?', // send back validation
            },
            require: '?^ngModel',
            templateUrl,

            link(scope, elem, attrs, formController) {
                scope.isValid = angular.isDefined(scope.isValid) ? scope.isValid : null;

                scope.form = formController.$$parentForm;

                scope.$watch('ngModel', () => {
                    if (!scope.form) {
                        return;
                    }

                    const validationRegEx = scope.allowFloat ? /^-?\d*.?\d*$/ : /^-?\d*$/;

                    if (
                        scope.ngModel === null ||
                        (validationRegEx.test(scope.ngModel) &&
                            ((!scope.allowZero &&
                                scope.ngModel !== 0 &&
                                scope.ngModel >= scope.min &&
                                scope.ngModel <= scope.max) ||
                                (scope.allowZero && scope.ngModel >= scope.min && scope.ngModel <= scope.max)))
                    ) {
                        scope.isValid = true;
                        scope.form[scope.inputName].$setValidity('number', scope.isValid);
                    } else {
                        scope.isValid = false;
                        scope.form[scope.inputName].$setValidity('number', scope.isValid);
                    }
                });
            },
        };
    },
]);
