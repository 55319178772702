import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import moment from 'moment-timezone';
import * as userAgentHelper from 'userAgentHelper';
import template from 'FrontRoyalForm/angularModule/views/inputs/date_with_fallback.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

/*
    Regardless of what timezone the user resides in, we don't want to convert the date that the user
    entered into the input to UTC or any other timezone. In other words, the date that they entered
    should always be repesented in their timezone. It's important that this directive does not convert
    the `ngModel`, `proxy.date`, or `proxy.dateString` values. Similarly, we don't want the `min` and
    `max` isolate scope attributes to be in UTC because we want to make sure that we're consistent
    in our date comparisons so we're comparing the user's local timezone to the user's local timezone
    and not the user's local timezone to UTC. See https://trello.com/c/aPTPHTIX for more info.
*/
angularModule.directive('dateWithFallback', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                inputDateSupported: '<?', // for tests
                viewValue: '=', // sends back value even when invalid
                min: '<?', // YYYYMMDD (NOTE: should not be UTC)
                max: '<?', // YYYYMMDD (NOTE: should not be UTC)
                isDisabled: '<?',
            },
            require: '?^ngModel',
            templateUrl,

            link(scope, _elem, attrs, modelController) {
                FormHelper.supportDirtyState(scope, modelController);
                scope.isRequired = attrs.required;

                scope.proxy = {};

                // Check if type=date is supported. See http://stackoverflow.com/a/10199306/1747491
                if (_.isUndefined(scope.inputDateSupported)) {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'date');
                    const notADateValue = 'not-a-date';
                    input.setAttribute('value', notADateValue);

                    // force all mobile(iOS, Android, mobile and tablet) to use the fallback input since their native datepicker UI
                    // has caused some confusion for some users (see https://trello.com/c/pO1rwzY3 and https://trello.com/c/kM1Uzi2c)
                    if (
                        userAgentHelper.isiOSDevice() ||
                        userAgentHelper.isAndroidDevice() ||
                        userAgentHelper.isMobileOrTabletDevice()
                    ) {
                        scope.inputDateSupported = false;
                    } else {
                        scope.inputDateSupported = input.value !== notADateValue;
                    }
                }

                scope.$watch('ngModel', () => {
                    // set proxy.date or proxy.dateString depending on browser's support of type=date
                    if (scope.ngModel) {
                        if (scope.inputDateSupported) {
                            /*
                                    NOTE: parsing of date strings with the Date constructor (and Date.parse, they are equivalent)
                                    is strongly discouraged due to browser differences and inconsistencies. Support for RFC 2822
                                    format strings is by convention only. Support for ISO 8601 formats differs in that date-only
                                    strings (e.g. "1970-01-01") are treated as UTC, not local.
                                    See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date)
                                    To workaround this, we use moment in conjunction with toDate() so the date is represented
                                    in local time.
                                */
                            scope.proxy.date = moment(scope.ngModel).toDate();
                        } else {
                            scope.proxy.dateString = moment(scope.ngModel).format('MM/DD/YYYY');
                        }
                        setViewValue(moment(scope.ngModel));
                    }
                });

                // put on scope so we can test directly
                scope.isValid = momentDate => {
                    if (!momentDate.isValid()) {
                        return false;
                    }

                    // always return true so that the check in setDate and parseDateString passes
                    // when no min or max is specified
                    if (!scope.min && !scope.max) {
                        return true;
                    }

                    const formattedDate = momentDate.format('YYYYMMDD');
                    let isValid = true;

                    if (scope.min && scope.max) {
                        isValid = formattedDate >= scope.min && formattedDate <= scope.max;
                    } else if (scope.min && !scope.max) {
                        isValid = formattedDate >= scope.min;
                    } else if (!scope.min && scope.max) {
                        isValid = formattedDate <= scope.max;
                    }

                    // set validity
                    modelController.$setValidity('minMax', isValid);
                    return isValid;
                };

                scope.setDate = date => {
                    const momentDate = date ? moment(date) : undefined;
                    setViewValueAndNgModel(date, momentDate);
                };

                scope.parseDateString = dateString => {
                    const momentDate = dateString ? moment(dateString, 'MMDDYYYY') : undefined;
                    setViewValueAndNgModel(dateString, momentDate);
                };

                function setViewValueAndNgModel(dateOrDateString, momentDate) {
                    setViewValue(momentDate);

                    // I don't think the ng-change fires until the date is valid but I left
                    // this in anyway just in case another browser behaves differently or something
                    if (dateOrDateString && scope.isValid(momentDate)) {
                        scope.ngModel = momentDate.format('YYYY/MM/DD');
                    } else {
                        scope.ngModel = null;
                    }
                }

                function setViewValue(momentDate) {
                    scope.viewValue = momentDate ? momentDate.format('YYYYMMDD') : undefined; // valid ISO formate
                }
            },
        };
    },
]);
