import { type HTMLAttributes, type DetailedHTMLProps, type ReactNode, type FC, useState, useEffect } from 'react';

type Props = { children: ReactNode; open: boolean } & DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
> & { duration?: number };

export const Fade: FC<Props> = ({ children, open, duration = 150, ...rest }) => {
    const [visible, setVisible] = useState(open);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (open && !visible) {
            setVisible(true);
        } else if (!open && visible) {
            timeoutId = setTimeout(() => setVisible(false), duration);
        }

        return () => clearTimeout(timeoutId);
    }, [duration, open, visible]);

    return (
        <div
            style={{
                transitionTimingFunction: 'linear',
                transitionProperty: 'opacity',
                transitionDuration: `${duration}ms`,
                opacity: open ? 1 : 0,
            }}
            {...rest}
        >
            {visible && children}
        </div>
    );
};
