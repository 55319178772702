import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/selected_pills.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('selectedPills', [
    '$injector',
    function factory() {
        return {
            restrict: 'E',
            templateUrl,
            scope: {
                ngModel: '=', // ngModel's reference can change because the sortable directive changes it under the hood
                type: '<',
                maxPills: '<?',
                simpleInstructions: '<?',
            },
            link(scope) {
                scope.proxy = {};
                scope.$watch('ngModel', ngModel => {
                    scope.proxy.ngModel = ngModel;
                });

                scope.$watch('proxy.ngModel', ngModel => {
                    scope.ngModel = ngModel;
                });

                scope.deletePill = index => {
                    scope.ngModel.splice(index, 1);
                };

                scope.lessThanOnePillsDescKey = scope.maxPills
                    ? 'front_royal_form.selected_pills.up_to_num_input'
                    : 'front_royal_form.selected_pills.desc_input';
                scope.multiplePillsDescKey = scope.maxPills
                    ? 'front_royal_form.selected_pills.up_to_num'
                    : 'front_royal_form.selected_pills.desc';
            },
        };
    },
]);
