import { ProgramFamily } from 'Program';
import * as Yup from 'yup';
import { type FormConfig } from './FormConfigs.types';

const basicInfoFormConfig = {
    stepName: 'basic_info',
    validationSchema: Yup.object({
        birthdate: Yup.string().required(),
        name: Yup.string().required(),
        nickname: Yup.string().required(),
        place_details: Yup.object().required(),
        place_id: Yup.string().required(),
        survey_highest_level_completed_education_description: Yup.string().required(),
        survey_most_recent_role_description: Yup.string().required(),
        survey_years_full_time_experience: Yup.string().required(),
    }),
};

const educationFormConfig = {
    stepName: 'education',
    validationSchema: Yup.object({
        // If the user has indicated that they are not a native English speaker, we require they answer
        // whether or not they've earned an accredited degree in a predominantly English speaking institution.
        earned_accredited_degree_in_english: Yup.boolean().when('native_english_speaker', {
            is: false,
            then: Yup.boolean().required(),
            // This is nullable because we explicitly set it to `null` when/if the user indicates they're
            // a native English speaker.
            otherwise: Yup.boolean().nullable().notRequired(),
        }),
        // When the user has indicated they've completed more than a high school education, we require that they
        // input at least one formal education experience.
        education_experiences: Yup.array().when('survey_highest_level_completed_education_description', {
            is: (survey_highest_level_completed_education_description: string) =>
                survey_highest_level_completed_education_description !== 'high_school',
            then: Yup.array()
                .of(
                    Yup.object({
                        educational_organization: Yup.object().required(),
                        degree: Yup.string().nullable(),
                        graduation_year: Yup.number().required(),
                        major: Yup.string().required(),
                        start_year: Yup.number().when('degree', {
                            is: (value: string) => !!value,
                            then: Yup.number().required(),
                            otherwise: Yup.number().nullable().notRequired(),
                        }),
                    }),
                )
                .test((education_experiences, { createError, path }) => {
                    const hasAtLeastOneFormalEducationExperience = education_experiences?.find(
                        education_experience => education_experience.degree,
                    );

                    if (hasAtLeastOneFormalEducationExperience) return true;

                    return createError({
                        path,
                        type: 'education_experiences',
                        message: 'Must have at least one formal education experience',
                    });
                }),
            otherwise: Yup.array().notRequired(),
        }),
        // When the user has indicated that they've only completed a high school education, we require them
        // to check a checkbox indicating they have no "formal" education.
        has_no_formal_education: Yup.boolean().when('survey_highest_level_completed_education_description', {
            is: (survey_highest_level_completed_education_description: string) =>
                survey_highest_level_completed_education_description === 'high_school',
            then: Yup.boolean().required(),
            otherwise: Yup.boolean().notRequired(),
        }),
        native_english_speaker: Yup.boolean().required(),
    }),
};

const workFormConfig = {
    stepName: 'work',
    validationSchema: Yup.object({
        employer_funding_eligibility: Yup.string().required(),
        work_experiences: Yup.array()
            .of(
                Yup.object({
                    employment_type: Yup.string().required(),
                    featured: Yup.boolean().notRequired(),
                    job_title: Yup.string().required(),
                    professional_organization: Yup.object().required(),
                    responsibilities: Yup.array().of(Yup.string().required()).min(1).required(),
                    start_date: Yup.number().required(),
                }),
            )
            .test((work_experiences, { createError, path }) => {
                const hasExactlyOneFeaturedWorkExperience =
                    work_experiences?.filter(work_experience => work_experience.featured).length === 1;
                const hasAtLeastOneFullTimeWorkExperience = work_experiences?.find(
                    work_experience => work_experience.employment_type === 'full_time',
                );

                if (hasExactlyOneFeaturedWorkExperience && hasAtLeastOneFullTimeWorkExperience) return true;

                return createError({
                    path,
                    type: 'work_experiences',
                    message: 'Must have exactly one featured and at least one full time work experience',
                });
            }),
    }),
};

const tuitionAndScholarshipsFormConfig = {
    stepName: 'tuition_and_scholarships',
    validationSchema: Yup.object({
        declineScholarshipConsideration: Yup.boolean().required(),
        // If the user has not declined scholarship consideration, we required they select at least
        // one scholarship they're interested in.
        scholarshipLabelsInterestedIn: Yup.array().when('declineScholarshipConsideration', {
            is: false,
            then: Yup.array().of(Yup.string().required()).min(1).required(),
            otherwise: Yup.array().notRequired(),
        }),
        // If the user selects the need-based scholarship, we require they answer shortAnswerScholarship.
        shortAnswerScholarship: Yup.string().when('scholarshipLabelsInterestedIn', {
            is: (scholarshipLabelsInterestedIn: string[]) => scholarshipLabelsInterestedIn.includes('need'),
            then: Yup.string().required(),
            otherwise: Yup.string().nullable(),
        }),
    }),
};

const submitFormConfig = {
    stepName: 'submit_application',
    inputs:
        // After Cycle 66 Decision Date, 2024-11-06 4am
        Date.now() > 1730883600000
            ? {
                  earlyAdmissionOptIn: {
                      required: true,
                  },
              }
            : ({} as FormConfig['inputs']),
};

export const ApplicationFormConfigsByProgramFamily: Record<
    Extract<
        ProgramFamily,
        | ProgramFamily.master_of_business_administration
        | ProgramFamily.master_of_science_in_business_analytics
        | ProgramFamily.master_of_science_in_software_engineering
    >,
    FormConfig[]
> = {
    [ProgramFamily.master_of_business_administration]: [
        basicInfoFormConfig,
        educationFormConfig,
        workFormConfig,
        {
            inputs: {
                shortAnswerWhyPursuing: {
                    localeKey: 'short_answer_why_pursuing_master_of_business_administration',
                    maxCharLength: 450,
                    required: true,
                },
                shortAnswerUseSkillsToAdvance: {
                    localeKey: 'short_answer_use_skills_to_advance',
                    maxCharLength: 450,
                    required: true,
                },
                shortAnswerPriorExperience: {
                    localeKey: 'short_answer_prior_experience_master_of_business_administrations',
                    maxCharLength: 450,
                    required: true,
                },
                anythingElseToTellUs: {
                    localeKey: 'anything_else_to_tell_us',
                    maxCharLength: 500,
                    required: false,
                },
            },
            stepName: 'application_questions',
            validationSchema: Yup.object({
                how_did_you_hear_about_us: Yup.string().required(),
                shortAnswerPriorExperience: Yup.string().required(),
                shortAnswerUseSkillsToAdvance: Yup.string().required(),
                shortAnswerWhyPursuing: Yup.string().required(),
            }),
        },
        tuitionAndScholarshipsFormConfig,
        {
            ...submitFormConfig,
            inputs: {
                earlyAdmissionOptIn: {
                    required: true,
                },
            },
        },
    ],
    [ProgramFamily.master_of_science_in_business_analytics]: [
        basicInfoFormConfig,
        educationFormConfig,
        workFormConfig,
        {
            inputs: {
                shortAnswerWhyPursuing: {
                    localeKey: 'short_answer_why_pursuing_master_of_science_in_business_analytics',
                    maxCharLength: 450,
                    required: true,
                },
                shortAnswerUseSkillsToAdvance: {
                    localeKey: 'short_answer_use_skills_to_advance',
                    maxCharLength: 450,
                    required: true,
                },
                shortAnswerPriorExperience: {
                    localeKey: 'short_answer_prior_experience_master_of_science_in_business_analytics',
                    maxCharLength: 450,
                    required: true,
                },
                anythingElseToTellUs: {
                    localeKey: 'anything_else_to_tell_us',
                    maxCharLength: 500,
                    required: false,
                },
            },
            stepName: 'application_questions',
            validationSchema: Yup.object({
                how_did_you_hear_about_us: Yup.string().required(),
                shortAnswerPriorExperience: Yup.string().required(),
                shortAnswerUseSkillsToAdvance: Yup.string().required(),
                shortAnswerWhyPursuing: Yup.string().required(),
            }),
        },
        tuitionAndScholarshipsFormConfig,
        submitFormConfig,
    ],
    [ProgramFamily.master_of_science_in_software_engineering]: [
        basicInfoFormConfig,
        educationFormConfig,
        workFormConfig,
        {
            inputs: {
                shortAnswerWhyPursuing: {
                    localeKey: 'short_answer_why_pursuing_master_of_science_in_software_engineering',
                    maxCharLength: 450,
                    required: true,
                },
                shortAnswerUseSkillsToAdvance: {
                    localeKey: 'short_answer_use_skills_to_advance',
                    maxCharLength: 450,
                    required: true,
                },
                shortAnswerPriorExperience: {
                    localeKey: 'short_answer_prior_experience_master_of_science_in_software_engineering',
                    maxCharLength: 450,
                    required: true,
                },
                anythingElseToTellUs: {
                    localeKey: 'anything_else_to_tell_us',
                    maxCharLength: 500,
                    required: false,
                },
            },
            stepName: 'application_questions',
            validationSchema: Yup.object({
                how_did_you_hear_about_us: Yup.string().required(),
                shortAnswerPriorExperience: Yup.string().required(),
                shortAnswerUseSkillsToAdvance: Yup.string().required(),
                shortAnswerWhyPursuing: Yup.string().required(),
            }),
        },
        tuitionAndScholarshipsFormConfig,
        submitFormConfig,
    ],
};

// Configs for forms that are shown to the user in order to complete their student profile.
// Note there is some overlap here with ApplicationFormConfigsByProgramFamily - specifically the education and work forms.
// It might make sense for this to be in its own module, but would need to figure out a good way to share the education
// and work form configs. Probably more trouble than it's worth.
export const StudentProfileFormConfigs = [
    basicInfoFormConfig,
    {
        stepName: 'more_about_you',
        validationSchema: Yup.object({
            avatar_url: Yup.string().required(),
            awards_and_interests: Yup.array()
                .of(
                    Yup.object({
                        text: Yup.string().required(),
                    }),
                )
                .min(2)
                .required(),
            top_motivations: Yup.array().of(Yup.string().required()).min(3).required(),
            top_personal_descriptors: Yup.array().of(Yup.string().required()).min(3).required(),
            top_workplace_strengths: Yup.array().of(Yup.string().required()).min(3).required(),
        }),
    },
    educationFormConfig,
    workFormConfig,
    { stepName: 'demographics' },
    {
        stepName: 'student_network_settings',
        requiresVisit: true,
        validationSchema: Yup.object({
            personal_fact: Yup.string().required(),
            pref_student_network_privacy: Yup.string().required(),
        }),
    },
];
