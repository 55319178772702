import angularModule from 'FormatsText/angularModule/scripts/formats_text';

angularModule.directive('markdown', [
    '$injector',
    $injector => {
        const FormatsText = $injector.get('FormatsText');
        const $compile = $injector.get('$compile');

        return (scope, element, attrs) => {
            FormatsText.withMarkdown(scope);

            scope.$watch(
                scope => scope.$eval(attrs.markdown), // watch the 'compile' expression for changes
                value => {
                    const html = value ? scope.formatText(value) : '';
                    // when the 'compile' expression changes
                    // assign it into the current DOM
                    element.html(html);

                    // compile the new DOM and link it to the current
                    // scope.
                    // NOTE: we only compile .childNodes so that
                    // we don't get into infinite loop compiling ourselves
                    $compile(element.contents())(scope);
                },
            );
        };
    },
]);
