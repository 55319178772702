import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/inputs/select_with_other.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import dropdownHelper from 'DropdownHelper';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('selectWithOther', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                optionKeys: '<?',
                optionLabels: '<?',
                selectPlaceholder: '@',
                otherPlaceholder: '@',
                selectizeMode: '<?',
                inputClasses: '<?',
            },
            require: '?^ngModel',
            templateUrl,

            link(scope, _elem, attrs, modelController) {
                FormHelper.supportDirtyState(scope, modelController);
                scope.isRequired = attrs.required;

                // Add 'other' if not passed in
                const optionsCollection = scope.optionKeys || scope.optionLabels;
                if (!_.includes(optionsCollection, 'other')) {
                    optionsCollection.push('other');
                }

                const translationHelper = new TranslationHelper('careers.field_options');

                if (scope.optionKeys) {
                    // Build options from the keys
                    scope.options = scope.optionKeys
                        .map(translationKey => ({
                            value: translationKey,
                            label: translationHelper.get(translationKey),
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label, translationHelper.getCurrentLanguage()));
                } else if (scope.optionLabels) {
                    // Build options from the labels
                    scope.options = scope.optionLabels
                        .map(optionLabel => ({
                            value: optionLabel,
                            label: optionLabel,
                        }))
                        .sort((a, b) => a.label.localeCompare(b.label, translationHelper.getCurrentLanguage()));
                } else {
                    throw new Error('You must supply either an optionKeys or optionLabels collection');
                }
                dropdownHelper.moveOtherToBottom(scope.options);

                scope.proxy = {};

                const found = _.find(_.map(scope.options, 'value'), value => scope.ngModel === value);
                if (found) {
                    // If the model is one of the options then use that
                    scope.proxy.select = found;
                } else if (scope.ngModel) {
                    // Otherwise if the model is set but not to one of the options then assume Other
                    scope.proxy.select = 'other';
                }

                scope.$watch('proxy.select', (newVal, oldVal) => {
                    // If the select has been changed and the value is not Other then set the
                    // model to that value
                    if (newVal && newVal !== 'other') {
                        scope.ngModel = newVal;
                    }

                    // If the select has been changed and the value is Other then reset the model
                    else if (newVal && newVal === 'other' && oldVal !== 'other') {
                        scope.ngModel = undefined;
                    }

                    // Determine if we should show the Other input
                    scope.showOther = scope.proxy.select === 'other';
                });
            },
        };
    },
]);
