import moment from 'moment-timezone';

export default function convertTimestamp(name) {
    return {
        [name]: {
            fromForm: values => values[name] && values[name].valueOf() / 1000,
            toForm: values => (values[name] ? moment(values[name] * 1000) : moment()),
        },
    };
}
