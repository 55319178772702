import angularModule from 'Feedback/angularModule/scripts/feedback_module';

angularModule.factory('FeedbackModal', [
    '$injector',

    $injector => {
        const DialogModal = $injector.get('DialogModal');
        const TranslationHelper = $injector.get('TranslationHelper');
        const translationHelper = new TranslationHelper('feedback.feedback_modal');

        return {
            launchFeedback() {
                // Pop up the selected directive in a modal
                const modalOptions = {
                    content: '<div><feedback-form></feedback-form></div>',
                    classes: ['feedback-form-modal'],
                    title: translationHelper.get('give_feedback'),
                    hideCloseButton: false,
                    scope: {},
                    animated: true,
                };

                DialogModal.alert(modalOptions);
            },
        };
    },
]);
