import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/inputs/copy_text.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('copyText', [
    '$injector',
    function factory($injector) {
        const $timeout = $injector.get('$timeout');

        return {
            restrict: 'E',
            scope: {
                value: '<',
                buttonText: '@',
            },
            templateUrl,
            link(scope) {
                scope.buttonKey = `front_royal_form.inputs.copy_text.${scope.buttonText}`;

                scope.selectText = () => {
                    angular.element('[name=copyValue]').select();
                };

                scope.copyText = () => {
                    scope.selectText();
                    document.execCommand('copy');
                    document.getSelection().removeAllRanges();
                    angular.element('[name=copyValue]').blur();

                    scope.buttonKey = 'front_royal_form.inputs.copy_text.copied';

                    $timeout(2000).then(() => {
                        scope.buttonKey = `front_royal_form.inputs.copy_text.${scope.buttonText}`;
                    });
                };
            },
        };
    },
]);
