import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/inputs/add_an_item.html';
import autoSuggestDropdownTemplate from 'FrontRoyalForm/angularModule/views/inputs/add_an_item_autosuggest_dropdown.html';
import autoSuggestItemTemplate from 'FrontRoyalForm/angularModule/views/inputs/add_an_item_autosuggest_item.html';
import 'FrontRoyalUiBootstrap/popover';
import cacheAngularTemplate from 'cacheAngularTemplate';

import checkboxCheckedBlue from 'vectors/checkbox_checked_blue.svg';
import arrowUpBeigeRounded from 'vectors/arrow_up_beige_rounded.svg';
import arrowDownBeigeRounded from 'vectors/arrow_down_beige_rounded.svg';
import trashcanBeige from 'vectors/trashcan_beige.svg';

const templateUrl = cacheAngularTemplate(angularModule, template);
const autoSuggestItemTemplateUrl = cacheAngularTemplate(angularModule, autoSuggestItemTemplate);

// See comment near autoSuggestDropdownTemplate in organization_autocomplete_dir.js.
// We have the same issue here
cacheAngularTemplate(angularModule, 'addAnItem/autosuggestDropdown.html', autoSuggestDropdownTemplate);

angularModule.directive('addAnItem', [
    '$injector',
    function factory($injector) {
        const FormHelper = $injector.get('FormHelper');
        const TranslationHelper = $injector.get('TranslationHelper');
        const $timeout = $injector.get('$timeout');
        const isMobileMixin = $injector.get('isMobileMixin');
        const Locale = $injector.get('Locale');

        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                placeholder: '<?',
                addText: '<?',
                doneText: '<?',
                min: '<?',
                max: '<?',
                maxLength: '<?',
                minLength: '<?',
                inPlaceEdit: '<?',
                inputTabindex: '<?',
                showErrors: '<?',
                showTooltip: '<?',
                autoSuggest: '<?',
                autoSuggestHandler: '<?',
                autoSuggestTemplateUrl: '<?',
                disableEditItem: '<?',
            },
            require: '?^ngModel',
            templateUrl,

            link(scope, elem, attrs, modelController) {
                scope.checkboxCheckedBlue = checkboxCheckedBlue;
                scope.arrowUpBeigeRounded = arrowUpBeigeRounded;
                scope.arrowDownBeigeRounded = arrowDownBeigeRounded;
                scope.trashcanBeige = trashcanBeige;

                //---------------------------
                // Initialization
                //---------------------------

                if (scope.autoSuggest && !scope.autoSuggestHandler) {
                    throw new Error('Cannot operate in auto-suggest mode without autoSuggestHandler being provided.');
                }

                const translationHelper = new TranslationHelper('front_royal_form.inputs.add_an_item');

                // defaults
                scope.itemToBeAdded = null;
                scope.min = scope.min || 0;
                scope.showErrors = scope.showErrors || false;
                scope.maxLength = scope.maxLength || 75;
                scope.minLength = scope.minLength || 0;
                scope.placeholder = scope.placeholder || translationHelper.get('add_skill_interest');
                scope.addText = scope.addText || translationHelper.get('add');
                scope.doneText = scope.doneText || translationHelper.get('done');
                scope.autoSuggestTemplateUrl = scope.autoSuggestTemplateUrl || autoSuggestItemTemplateUrl;

                // Initialize all items to not being edited
                function rebuildProxyTrackers() {
                    scope.editingTracker = [];
                    scope.itemProxy = [];

                    if (scope.ngModel) {
                        scope.ngModel.forEach((item, i) => {
                            scope.editingTracker.push(false);
                            scope.itemProxy.push(_.clone(item));
                            scope.onTextareaChange(i);
                        });
                    }
                }

                function rebuildInvalidTracker() {
                    scope.invalidTracker = [];

                    if (scope.ngModel) {
                        for (let i = 0; i < scope.ngModel.length; i++) {
                            scope.invalidTracker.push(false);
                        }
                    }
                }

                // Add support for custom form validation and dirtying
                FormHelper.supportDirtyState(scope, modelController);
                FormHelper.supportCollectionSizeValidation(scope, attrs, modelController);
                FormHelper.supportsOrderableItems(scope);

                // Special handling of + / Add button
                isMobileMixin.onLink(scope);

                // If the user auto-completes their entry by pressing TAB or selecting an auto-suggested
                // option and the text becomes greater than the character maxlength, we can get into a
                // funky situation. If any amount of the text (except all of it) is then deleted from the
                // text field, newValue becomes 'undefined', which is not an accurate reflection of what's
                // actually in the input field, but the user is still allowed to add the item even though
                // the text value is undefined. To prevent this, we reset scope.itemToBeAdded.
                scope.$watch('itemToBeAdded', (newValue, oldValue) => {
                    if (newValue === undefined && oldValue) {
                        scope.itemToBeAdded = $('input[name="item-to-be-added"]').val();
                    }
                    scope.exceedsMaxLength = !!(newValue && scope.maxLength && newValue.length > scope.maxLength);
                });

                //---------------------------
                // Collection CRUD
                //---------------------------

                scope.$watchCollection('ngModel', () => {
                    rebuildProxyTrackers();
                    rebuildInvalidTracker();
                });

                scope.isEditingItem = index => scope.editingTracker[index];

                scope.setEditingItem = (isEditing, index) => {
                    if (scope.inPlaceEdit) {
                        rebuildInvalidTracker();
                        rebuildProxyTrackers();
                        scope.editingTracker[index] = isEditing;

                        // re-focus at end of line
                        $timeout(() => {
                            const el = elem.find('.item textarea').get(index);
                            angular.element(el).focus();
                            if (typeof el.selectionStart === 'number') {
                                el.selectionEnd = el.value.length;
                                el.selectionStart = el.value.length;
                            }
                        });
                    }
                };

                scope.isInvalidItem = index => scope.invalidTracker[index];

                scope.setInvalidItem = (isValid, index) => {
                    if (scope.inPlaceEdit) {
                        rebuildInvalidTracker();
                        scope.invalidTracker[index] = isValid;

                        // re-focus at end of line
                        $timeout(() => {
                            const el = elem.find('.item textarea').get(index);
                            angular.element(el).focus();
                            if (typeof el.selectionStart === 'number') {
                                el.selectionStart = el.value.length;
                                el.selectionEnd = el.value.length;
                            }
                        });
                    }
                };

                scope.isLanguageInvalid = () => FormHelper.invalidLanguage(scope.itemToBeAdded);

                scope.addItem = () => {
                    if (!scope.ngModel) {
                        scope.ngModel = [];
                    }

                    if (
                        scope.itemToBeAdded &&
                        scope.itemToBeAdded.length <= scope.maxLength &&
                        !scope.containsText(scope.itemToBeAdded) &&
                        !scope.isLanguageInvalid()
                    ) {
                        // support new suggestable entries or simple strings
                        if (scope.autoSuggest) {
                            scope.ngModel.push({
                                text: scope.itemToBeAdded,
                                locale: Locale.activeCode,
                            });
                        } else {
                            scope.ngModel.push(scope.itemToBeAdded);
                        }

                        scope.itemToBeAdded = null;
                        rebuildProxyTrackers();

                        scope.updateDirty();
                        scope.updateValidity();
                    }
                };

                scope.containsText = text => {
                    const existingTexts = scope.autoSuggest ? _.map(scope.ngModel, 'text') : scope.ngModel;
                    return _.includes(existingTexts, text);
                };

                scope.removeItem = index => {
                    scope.ngModel.splice(index, 1);
                    rebuildProxyTrackers();
                    scope.updateDirty();
                    scope.updateValidity();
                };

                scope.updateItem = (newItem, index) => {
                    const containsText = scope.autoSuggest
                        ? scope.containsText(newItem.text)
                        : scope.containsText(newItem);
                    const isSameItem = scope.autoSuggest
                        ? scope.ngModel[index].text === newItem.text
                        : scope.ngModel[index] === newItem;

                    // if the ngModel contains this item already but we're not actually trying to update
                    // that item in particular (click to change text, then click button to update without
                    // changing the text), then set the item to invalid
                    if (containsText && !isSameItem) {
                        scope.setInvalidItem(true, index);
                    } else {
                        scope.ngModel[index] = newItem;
                        scope.updateDirty();
                        scope.setEditingItem(false, index);
                        scope.setInvalidItem(false, index);
                    }
                };

                scope.onTextareaChange = index => {
                    $timeout(() => {
                        const el = elem.find('textarea').get(index);
                        if (el) {
                            el.style.height = 0;
                            const newHeight = el.scrollHeight > 46 ? el.scrollHeight : 46;
                            el.style.height = `${newHeight.toString()}px`;
                        }
                    });
                };

                // go ahead and kick off the initial proxy rebuild
                rebuildProxyTrackers();

                scope.shouldDisableEditItem = index =>
                    scope.disableEditItem ? scope.disableEditItem(scope.ngModel[index]) : false;

                //---------------------------
                // Tooltips
                //---------------------------

                scope.toggleTip = state => {
                    scope.addInputFocused = state;
                };
            },
        };
    },
]);
