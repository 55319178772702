import * as userAgentHelper from 'userAgentHelper';
import 'TimeoutHelpers/angularModule';

export default angular.module('FixViewportOnBlur', ['timeoutHelpers']).factory('FixViewportOnBlur', [
    '$injector',

    $injector => {
        const scopeTimeout = $injector.get('scopeTimeout');

        function fixViewport() {
            window.scrollTo(0, 0);
        }

        function isBrowserSusceptible() {
            return userAgentHelper.isFacebookBrowser() || userAgentHelper.isInstagramBrowser();
        }

        function installBlurListeners(elem) {
            // don't install if unnecessary
            if (isBrowserSusceptible()) {
                $(elem)
                    .find('select, input, textarea')
                    .on('blur', () => {
                        fixViewport();
                    });
            }
        }

        function installBlurListenersOnLink(scope, elem, timeout) {
            timeout = timeout || 500;
            scopeTimeout(
                scope,
                () => {
                    installBlurListeners(elem);
                },
                timeout,
            );
        }

        return {
            fixViewport,
            isBrowserSusceptible,
            installBlurListeners,
            installBlurListenersOnLink,
        };
    },
]);
