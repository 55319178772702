import angularModule from 'FrontRoyalApiErrorHandler/angularModule/scripts/front_royal_api_error_handler';
import template from 'FrontRoyalApiErrorHandler/angularModule/views/internal_server_error.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('apiErrorInternalServerError', [
    '$injector',

    /*

            This directive shows the internal server error that
            came back from the server.

            The user can dismiss the reject the original promise
            by clicking the continue button.  That will hide the
            modal and unfreeze HttpQueue so that further requests
            can be made.

        */

    $injector => {
        const $sce = $injector.get('$sce');
        const HttpQueue = $injector.get('HttpQueue');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                response: '<',
                reject: '<',
                message: '<',
            },
            link(scope) {
                const translationHelper = new TranslationHelper('front_royal_api_error_handler.internal_server_error');

                if (!scope.message) {
                    const message = scope.response.data.message;
                    scope.message = translationHelper.get('unexpected_error_x', {
                        message,
                    });
                }

                scope.formattedMessage = $sce.trustAsHtml(scope.message);

                scope.dismiss = () => {
                    scope.reject(scope.response);

                    // it should be possible to make api calls again
                    HttpQueue.unfreezeAfterError(scope.response.config);
                };
            },
        };
    },
]);
