
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.internal_server_error = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.internal_server_error || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.internal_server_error = {...window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.internal_server_error, ...{
    "continue": "Continue",
    "unexpected_error_x": "Unexpected error from server: {{message}}. Please try again."
}
}
    