import { DisconnectedError } from 'DisconnectedError';
import 'Authentication/angularModule';
import 'DialogModal/angularModule';
import 'ErrorLogging/angularModule';
import 'FormatsText/angularModule';
import 'FrontRoyalSpinner/angularModule';
import hideSplashScreen from 'hideSplashScreen';
import 'InstitutionalSubdomain/angularModule';
import 'Oreo/angularModule';
import 'PrioritizedInterceptors/angularModule';
import 'Translation/angularModule';
import 'EventLogger/angularModule';

const ApiErrorHandlerModule = angular.module('FrontRoyal.ApiErrorHandler', [
    'Translation',
    'prioritizedInterceptors',
    'DialogModal',
    'EventLogger',
    'FormatsText',
    'FrontRoyalSpinner',
    'FrontRoyal.ErrorLogService',
    'FrontRoyal.Authentication',
    'institutionalSubdomain',
    'FrontRoyal.Oreo',
]);

/*

    ApiErrorHandler intercepts errors for any request that matches '/api/'

    When an error is intercepted, some directive will be displayed in a modal window,
    giving the user information about the error and telling the user what to do next.

    To find out which errors will be handled by which directives, see the 'rules' property below.

*/
ApiErrorHandlerModule.config([
    'PrioritizedInterceptorsProvider',
    PrioritizedInterceptorsProvider => {
        // Use a low priority so that this will be the last interceptor run
        // on the response
        PrioritizedInterceptorsProvider.addInterceptor(-1000, [
            '$injector',

            $injector => {
                const ApiErrorHandler = $injector.get('ApiErrorHandler');

                return {
                    responseError(response) {
                        return ApiErrorHandler.onResponseError(response);
                    },
                };
            },
        ]);
    },
]);

ApiErrorHandlerModule.factory('ApiErrorHandler', [
    '$injector',
    $injector => {
        const $q = $injector.get('$q');
        const $rootScope = $injector.get('$rootScope');
        const ErrorLogService = $injector.get('ErrorLogService');
        const $location = $injector.get('$location');
        const HttpQueue = $injector.get('HttpQueue');
        const offlineModeManager = $injector.get('offlineModeManager');
        const $window = $injector.get('$window');
        const frontRoyalStore = $injector.get('frontRoyalStore');

        function getRules(translationHelper) {
            const gatewayErrorOptions = {
                directive: 'api-error-disconnected',
                isDisconnectedError: true,

                // FIXME: maybe it would be good to be able to enter offline mode
                // once the user starts getting 503s and 504s, or when requests get
                // slow/unreliable, but there is no way right now for OfflineModeManager
                // to turn on offline mode if NetworkConnection.online is true, so I'm not sure
                // how we would manage it
                allowEnteringOfflineMode: false,
                modalClass: 'disconnected-modal',
                scope: {
                    message: translationHelper.get('unable_to_contact_app_servers'),
                },
            };

            const disconnectedErrorOptions = {
                directive: 'api-error-disconnected',
                isDisconnectedError: true,
                allowEnteringOfflineMode: true,
                modalClass: 'disconnected-modal',
                animated: true,
            };

            const disconnectedErrorOptionsWithNoOfflineMode = {
                ...disconnectedErrorOptions,
                allowEnteringOfflineMode: false,
            };
            const disallowOfflineMode = {
                // offline
                0: disconnectedErrorOptionsWithNoOfflineMode,

                // timeout: https://github.com/angular/angular.js/pull/8756
                '-1': disconnectedErrorOptionsWithNoOfflineMode,
            };
            const allowOfflineMode = {
                // offline
                0: disconnectedErrorOptions,

                // timeout: https://github.com/angular/angular.js/pull/8756
                '-1': disconnectedErrorOptions,
            };
            const internal404Error = {
                404: {
                    directive: 'api-error-internal-server-error',
                    notifySentry: false,
                    alternateTitle: translationHelper.get('something_is_missing'),
                    scope: {
                        message: translationHelper.get('could_not_find'),
                    },
                },
            };

            const rules = {
                default: {
                    ...allowOfflineMode,

                    // any error not listed below
                    default: {
                        directive: 'api-error-fatal',
                        scope: {
                            message: translationHelper.get('something_went_wrong'),
                        },
                        notifySentry: true,
                        animated: true,
                        modalClass: '',
                    },

                    // bad gateway
                    502: gatewayErrorOptions,

                    // gateway timeout
                    504: gatewayErrorOptions,

                    not_logged_in: {
                        directive: 'api-error-logged-out',
                    },

                    unauthorized: {
                        directive: 'api-error-fatal',
                        scope: {
                            message: translationHelper.get('you_do_not_have_content_permissions'),
                            notifySentry: false,
                            reloadPath: $rootScope.homePath,
                        },
                    },

                    503: {
                        directive: 'api-error-fatal',
                        scope: {
                            message: translationHelper.get('servers_are_down'),
                        },
                        notifySentry: false,
                    },

                    409: {
                        passthrough: true,
                    },

                    404: {
                        alternateTitle: translationHelper.get('something_is_missing'),
                        directive: 'not-found-error',
                    },

                    406: {
                        directive: 'api-error-failed-validation',
                    },
                },

                editor: {
                    ...disallowOfflineMode,
                    ...internal404Error,

                    // validation error in the editor
                    406: {
                        directive: 'api-error-failed-validation',
                    },

                    500: {
                        directive: 'api-error-internal-server-error',
                        notifySentry: true,
                    },

                    unauthorized: {
                        directive: 'api-error-internal-server-error', // probably the wrong name for the directive now, but it has the right behavior
                        scope: {
                            message: translationHelper.get('you_do_not_have_permission'),
                        },
                        notifySentry: false,
                    },
                },
            };

            // use the more descriptive API handlers for admin / reports sites
            rules.reports = {
                ...internal404Error,
                unauthorized: rules.editor.unauthorized,
                406: rules.editor['406'],
            };

            rules.admin = {
                ...rules.reports,
                ...disallowOfflineMode,
            };

            return rules;
        }

        const ApiErrorHandler = {
            // response is something like:
            // {
            //     data: 'RuntimeError at /api/lesson_streams.json↵=========…ebrick/server.rb:295:in `block in start_thread ... ',
            //     status: 500,
            //     headers: function,
            //     config: Object,
            //     statusText: "Internal Server Error"
            // }
            onResponseError(response) {
                if (
                    !response ||
                    !response.config ||
                    !response.config.url.includes('/api') ||
                    response.config.url.includes('/api/auth')
                ) {
                    return $q.reject(response);
                }

                // see ErrorLogService
                response.handledByFrontRoyalApiErrorHandler = true;

                /*
                    If you want to skip the default error handling,
                    set the skip option like this:

                    scope.user.save(meta, {
                        'FrontRoyal.ApiErrorHandler': {
                            skip: true
                        }
                    }).catch(function(response){
                        // custom error handling
                    });

                    If you want to do some custom handling and still
                    get the default popup, you could
                    call

                        response.config['FrontRoyal.ApiErrorHandler'].skip = false;
                        ApiErrorHandler.onResponseError(response);

                    inside of the custom handler.

                    NOTE!!!!!
                    Since the request failed, the HttpQueue is still going
                    to be frozen.  You need to call HttpQueue.unfreezeAfterError(response.config)
                    in your handler if you want to unfreeze it.
                */
                const requestOverrides = response.config['FrontRoyal.ApiErrorHandler'] || {};
                if (requestOverrides.skip) {
                    return $q.reject(response);
                }

                let ruleKey = response.status;
                if (response.status === 401) {
                    ruleKey = response.data && response.data.not_logged_in ? 'not_logged_in' : 'unauthorized';
                }
                return this._switchToOfflineModeOrHandleError(ruleKey, response, requestOverrides);
            },

            showFatalError(response, message) {
                const options = this.rules.default.default;
                if (message) {
                    // be careful to clone the object
                    options.scope = angular.extend({}, options.scope, {
                        message,
                    });
                }

                this._showModal(response, options);
            },

            _hideModal() {
                $('.server-error-modal').modal('hide');
            },

            _switchToOfflineModeOrHandleError(ruleKey, response, requestOverrides) {
                const site = $rootScope.site();
                const rulesForSite = this.rules[site] || {};
                let options =
                    rulesForSite[ruleKey] ||
                    rulesForSite.default ||
                    this.rules.default[ruleKey] ||
                    this.rules.default.default;

                options = _.clone(options); // we modify options in some cases below

                // When background requests fail due to a network error, we
                // don't want to do anything in the UI.  We allow the errors to
                // bubble up in case some state needs to be updated after a failed
                // request (most likely so that we can retry the request again in the
                // future).
                // Anytime we're flushing the store, we can treat that as a background
                // request.  If it fails, the user does not need to know.  That data is still
                // in the store and will be flushed when we come back online.
                if (
                    options.isDisconnectedError &&
                    (requestOverrides.background || frontRoyalStore.treatAsBackgroundRequest(response.config))
                ) {
                    HttpQueue.unfreezeAfterError(response.config);
                    return $q.reject(new DisconnectedError(response));
                }

                // If a request fails due to a network error, we can
                // try to switch to offline mode.  If we do switch to offline mode,
                // then the offlineModeManager will send the user to the dashboard.
                // In this case we let the request hang
                // and never resolve.  This might not be ideal, but we've been been assuming
                // up until now that http request errors never bubble back up to the place
                // where they were initiated, so this prevents us from having to add
                // error handling in every place where we make a request.  If you DO need
                // to know about a request failing because of a network error, look into
                // OfflineModeManager#rejectInOfflineMode
                //
                // If we can't switch to offline mode (for example because the user does not
                // have the store enabled, or this browser doesn't support offline mode),
                // then we go on, and the disconnected error modal will appear.
                let inOfflineModePromise = $q.resolve();
                if (options.allowEnteringOfflineMode) {
                    inOfflineModePromise = offlineModeManager
                        .showOfflineModalAfterDisconnectedError(response)
                        .then(inOfflineMode => {
                            if (inOfflineMode) {
                                HttpQueue.unfreezeAfterError(response.config);
                                return 'SWITCHED_TO_OFFLINE_MODE';
                            }
                            return undefined;
                        });
                }

                return inOfflineModePromise.then(switchedToOfflineMode => {
                    if (switchedToOfflineMode) {
                        // Return a promise that never resolves.
                        // See comment above for an explanation of why
                        return $q(() => {});
                    }

                    return this._handleError(ruleKey, response, requestOverrides, options);
                });
            },

            _handleError(ruleKey, response, requestOverrides, options) {
                let promise;

                if (ruleKey === 'not_logged_in') {
                    return this._handleLoggedOut(response, options, requestOverrides);
                }

                if (options.redirect) {
                    $location.url(options.redirect);
                    promise = $q((_, reject) => {
                        reject();
                        HttpQueue.unfreezeAfterError(response.config);
                    });
                    return promise;
                }

                if (options.passthrough) {
                    promise = $q((_, reject) => {
                        HttpQueue.unfreezeAfterError(response.config);
                        reject(response);
                    });
                    return promise;
                }

                // special handling for when connections fail while the app has not
                // yet been entirely bootstrapped / redirected past splash in Cordova
                if (
                    $window.CORDOVA &&
                    options.directive === 'api-error-disconnected' &&
                    !$rootScope.networkBootstrapCompleted
                ) {
                    hideSplashScreen($injector);
                    // go here if not initially directed here already
                    $location.path('/disconnected-mobile-init');
                    $rootScope.initiallyDisconnected = true;
                    options.modalClass = 'disconnected-modal-not-initialized';
                    options.animated = false;
                }

                return this._showModal(response, options);
            },

            _handleLoggedOut(response, options, requestOverrides) {
                options.alternateTitle = this.translationHelper.get('login_required');

                if (!!requestOverrides && requestOverrides.redirectOnLoggedOut) {
                    HttpQueue.unfreezeAfterError(response.config);

                    const url = $location.url();
                    const loc = $location.url('/sign-in');
                    loc.search({
                        target: url,
                    });

                    // Since this throws errors, requests that use redirectOnLoggedOut
                    // will need to handle these errors
                    throw response;
                }

                return this._showModal(response, options);
            },

            _logServerError(response, eventPayload) {
                const ignorableErrors = [
                    530, // cloudflare dns issue
                ];
                if (_.includes(ignorableErrors, response.status)) {
                    return;
                }
                // Log the error to getSentry and EventLogger
                const match = response.config.url.match(/api\/\w+/);
                const truncatedUrl = match ? match[0] : response.config.url;
                const message = `Received status code ${response.status} on a ${response.config.method} to ${truncatedUrl}`;

                ErrorLogService.notify(
                    new Error(message),
                    undefined,
                    Object.assign(eventPayload, {
                        fingerprint: [match ? truncatedUrl : 'unknownUrl', response.config.method, response.status],
                    }),
                );
            },

            _showModal(response, options) {
                const EventLogger = $injector.get('EventLogger');
                const directive = options.directive;
                const modalClass = options.modalClass;
                let scopeOptions = options.scope;
                const animated = options.animated;
                const notifySentry = options.notifySentry;

                let path = 'unknown';
                try {
                    const url = response.config.url;
                    path = url.split('?')[0];
                } catch (e) {
                    angular.noop();
                }

                const eventPayload = {
                    label: path,
                    server_error: {
                        path,
                        status: response.status,
                        statusText: response.statusText,
                        message: response.data && response.data.message,
                        config: {
                            url: response.config.url,
                            method: response.config.method,
                            httpQueue: response.config.httpQueue,
                        },
                    },
                };
                if (notifySentry) {
                    this._logServerError(response, eventPayload);
                } else {
                    EventLogger.log('error', eventPayload);
                }

                // Decide which directive to use to handle the error and
                // set any config specific to this error
                scopeOptions = scopeOptions || {};
                let resolve;
                let reject;
                const promise = $q((_resolve, _reject) => {
                    resolve = _resolve;
                    reject = _reject;
                });

                const errorType = response.data?.meta?.error_type;

                if (options.directive === 'api-error-failed-validation' && errorType === 'exam_closed') {
                    options.hideTitle = true;
                }

                // Pop up the selected directive in a modal
                const modalOptions = {
                    title: options.hideTitle
                        ? null
                        : options.alternateTitle || this.translationHelper.get('server_error'),
                    content: `<${directive} button-text="buttonText" response="response" resolve="resolve" reject="reject" message="message" reload-path="reloadPath"></${directive}>`,
                    classes: ['server-error-modal', modalClass],
                    hideCloseButton: true,
                    animated,
                    scope: angular.extend(
                        {
                            resolve,
                            reject,
                            response,
                        },
                        scopeOptions,
                    ),
                };

                // Hide the modal once the directive has decided what to do with it
                promise.then(this._hideModal, this._hideModal);

                // Not sure why I have to lazy-load this module
                $injector.get('DialogModal').alert(modalOptions);
                return promise;
            },
        };

        // moving this to a property that is lazy-instantiated
        // to prevenut circular dependency errors
        Object.defineProperty(ApiErrorHandler, 'rules', {
            get() {
                this.$$rules = this.$$rules || getRules(this.translationHelper);
                return this.$$rules;
            },
        });

        Object.defineProperty(ApiErrorHandler, 'translationHelper', {
            get() {
                if (!this.$$translationHelper) {
                    const TranslationHelper = $injector.get('TranslationHelper');
                    this.$$translationHelper = new TranslationHelper('front_royal_api_error_handler.api_error_handler');
                }
                return this.$$translationHelper;
            },
        });

        return ApiErrorHandler;
    },
]);

export default ApiErrorHandlerModule;
