import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';
import template from 'FrontRoyalForm/angularModule/views/inputs/salary.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('salary', [
    '$injector',

    function factory() {
        return {
            scope: {
                ngModel: '=',
                base: '<?',
                openToDiscussion: '=?',
            },
            restrict: 'E',
            templateUrl,

            link(scope, elem) {
                Object.defineProperty(scope, 'openToDiscussion', {
                    get() {
                        // set to checked if ngModel is undefined (null value is
                        // okay) OR if the value is null, but neither the checkbox
                        // or the input has been used. This way, we can pass a
                        // null value initially and have openToDiscussion checked,
                        // but pass a null value later (such as when you backspace
                        // out the base value) and not have it checked
                        return (
                            scope.ngModel === undefined ||
                            (scope.ngModel === null &&
                                elem.find('input[name=openToDiscussion]').hasClass('ng-pristine') &&
                                elem.find('input[name=salary]').hasClass('ng-pristine'))
                        );
                    },
                    set(val) {
                        if (val) {
                            scope.ngModel = undefined;
                        } else {
                            scope.ngModel = scope.ngModel || scope.base || 85000;
                        }
                    },
                });
            },
        };
    },
]);
