import { type ErrorHandlingComponent, type RetryFunction, errorHandlingComponentsForTopLevelComponents } from '..';
import angularModule from './frontRoyalErrorBoundaryModule';

type ErrorHandlers = Record<keyof typeof errorHandlingComponentsForTopLevelComponents, ErrorHandlingComponent>;
type Scope = angular.IScope &
    ErrorHandlers & {
        onErrorResolved: () => void;
        retry: RetryFunction;
    };

angularModule.directive('showTopLevelError', [
    '$injector',

    function factory() {
        return {
            restrict: 'E',
            template: `
                <front-royal-error-boundary-react-component
                    error="error"
                    reset-error-boundary="onErrorResolved"
                    retry="retry"
                    other="other"
                    disconnected="disconnected"
                    not-logged-in="notLoggedIn"
                    not-permitted="notPermitted"
                ></front-royal-error-boundary-react-component>
            `,
            scope: {
                error: '<',
                defaultErrorActions: '<',
                onErrorResolved: '<',
                retry: '<',
            },
            link(scope: Scope) {
                // This is defined in a bit of an odd way, but it makes it so that if another
                // option is added to topLevelErrorActions, it will trigger a typescript error here.
                // Hopefully this helps us to remember that we have to add it to the template above.
                const handledErrorTypes: ErrorHandlers = {
                    other: errorHandlingComponentsForTopLevelComponents.other,
                    disconnected: errorHandlingComponentsForTopLevelComponents.disconnected,
                    notLoggedIn: errorHandlingComponentsForTopLevelComponents.notLoggedIn,
                    notPermitted: errorHandlingComponentsForTopLevelComponents.notPermitted,
                };
                Object.keys(handledErrorTypes).forEach(key => {
                    const errorType = key as keyof ErrorHandlers;
                    scope[errorType] = handledErrorTypes[errorType];
                });
            },
        };
    },
]);
