import ClientStorage from 'ClientStorage';
import { type CurrentUserIguanaObject, getProgramApplication } from 'Users';
import { type CareerProfileIguanaObject } from 'CareerProfiles';
import { type ProgramFamilyFormDatum, getProgramFamilyFormDatum } from 'ProgramFamilyFormData';
import { type FormConfig } from 'FormConfigs';

// The application forms are backed by two data models:
// CareerProfile for everything shared between forms for different program families, and
// ProgramFamilyApplication for everything that is distinct for each program family.
type CombinedFormModel = CareerProfileIguanaObject & ProgramFamilyFormDatum;

const getCombinedFormModel = (
    user: CurrentUserIguanaObject,
    careerProfile?: CareerProfileIguanaObject,
    programFamilyFormDatum?: ProgramFamilyFormDatum,
) => {
    // If a careerProfile was not explicitly passed, as is the case in EditCareerProfileHelper.getCompletionPercentage
    // when it passes a proxy that we track completion on, derive it from the user.
    const _careerProfile = careerProfile || user.career_profile;

    // If a programFamilyFormDatum was not explicitly passed, as is the case in EditCareerProfileHelper.getCompletionPercentage
    // when it passes a proxy that we track completion on, derive it from the user.
    const _programFamilyFormDatum =
        programFamilyFormDatum ||
        (user.relevantCohort && getProgramFamilyFormDatum(user, user.relevantCohort.programFamily)) ||
        {};

    // The forms in FormConfigs are backed by two data models - CareerProfile and ProgramFamilyFormDatum.
    // Some forms write to only one of those models, while other forms can write to both of them, so in order for us
    // to check completeness against the form's validationSchema, we need to combine them into a single object.
    return {
        ..._careerProfile,
        ..._programFamilyFormDatum,
    } as CombinedFormModel;
};

const formIsComplete = (formConfig: FormConfig, combinedFormModel: CombinedFormModel) => {
    // If the form has a validationSchema, the form is complete if the source model
    // passes said validationSchema.
    if (formConfig.validationSchema) {
        try {
            return formConfig.validationSchema.validateSync(combinedFormModel);
        } catch (e) {
            // `validateSync` will raise if the source doesn't pass validations.
            // FIXME: would be nice to ultimately wire this into the forms themselves
            // to display errors to the end user, replacing our current `invalidFields`
            // implementation in angular.js
            return false;
        }
    }

    // If the form doesn't have a validationSchema, it's technically complete
    return true;
};

const getFormProgress = (
    user: CurrentUserIguanaObject,
    formConfig: FormConfig,
    combinedFormModel: CombinedFormModel,
): string => {
    // We mark the submit_application step as incomplete if they haven't submitted
    // their application yet to create the appearance that submitting their application
    // is a required action when completing their application.
    if (formConfig.stepName === 'submit_application') {
        if (getProgramApplication(user)) return 'complete';
        return 'incomplete';
    }

    if (formIsComplete(formConfig, combinedFormModel)) {
        // a special case where a required field could be prefilled by us or if they've
        // completed the step and have cleared their localStorage (e.g. in Network,
        // pref_student_network_privacy is prefilled by us).
        // So if this step is complete, we show a check mark as the default, which is a more
        // accurate UI
        if (formConfig.requiresVisit && !ClientStorage.getItem(`saved_${formConfig.stepName}`)) return 'none';
        return 'complete';
    }
    return 'incomplete';
};

export const getFormsProgressMap = (
    user: CurrentUserIguanaObject,
    formConfigs: FormConfig[],
    careerProfile?: CareerProfileIguanaObject,
    programFamilyFormDatum?: ProgramFamilyFormDatum,
) => {
    const formProgressMap: { [key: string]: string } = {};
    const combinedFormModel = getCombinedFormModel(user, careerProfile, programFamilyFormDatum);
    formConfigs.forEach((formConfig: FormConfig) => {
        formProgressMap[formConfig.stepName] = getFormProgress(user, formConfig, combinedFormModel);
    });
    return formProgressMap;
};

export const getFormsCompletePercent = (
    user: CurrentUserIguanaObject,
    formConfigs: FormConfig[],
    careerProfile?: CareerProfileIguanaObject,
    programFamilyFormDatum?: ProgramFamilyFormDatum,
) => {
    // Exclude any forms without a `validationSchema`, meaning the form isn't required, from the
    // complete percent calculation.
    const requiredFormConfigs = formConfigs.filter(formConfig => formConfig.validationSchema);
    // If there are no required form configs, return 100. In certain instances, namely Exec Ed,
    // users visit /settings/application_status to register for a program that does not support
    // applicationFormConfigs.
    if (requiredFormConfigs.length === 0) return 100;

    const formProgressMap = getFormsProgressMap(user, requiredFormConfigs, careerProfile, programFamilyFormDatum);
    const complete = Object.values(formProgressMap).filter(val => val === 'complete').length;
    return Math.round((complete / requiredFormConfigs.length) * 100);
};

export const getFirstIncompleteForm = (user: CurrentUserIguanaObject, formConfigs: FormConfig[]) => {
    const combinedFormModel = getCombinedFormModel(user);
    return formConfigs.find(formConfig => getFormProgress(user, formConfig, combinedFormModel) === 'incomplete');
};
