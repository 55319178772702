import angularModule from 'FrontRoyalForm/angularModule/scripts/front_royal_form_module';

angularModule.factory('FormHelper', [
    '$injector',
    function factory($injector) {
        const isMobile = $injector.get('isMobile');
        const scopeTimeout = $injector.get('scopeTimeout');
        const $q = $injector.get('$q');
        const Locale = $injector.get('Locale');

        // Cleans validity state on form component $destroy
        function supportDestroyValidity(scope) {
            scope.$on('$destroy', () => {
                scope.updateValidity(true);
            });
        }

        // Useful for when the component needs to validate as `required` attribute changes
        function supportsObservableRequired(scope, attrs) {
            if (attrs.ngRequired) {
                attrs.$observe('required', () => {
                    scope.updateValidity();
                });
            }
        }

        function setCurrentForm(scope, formController) {
            // provide access to the current formController on the shared scope
            // see also: edit_career_profile_dir.js
            scope.$emit('formHelperCurrentForm', formController);
        }

        return {
            // Mixin for common form-related tasks
            supportForm(scope, formController) {
                scope.atLeastOneSelected = options => !options.some(option => !!option);

                scope.validateBeforeSubmit = this.validateBeforeSubmit.bind(this, formController);

                if (isMobile()) {
                    // handles selective toggling of selectize input, and
                    // disabling of autcomplete which can be disruptive here
                    scopeTimeout(
                        scope,
                        () => {
                            const formElem = angular.element(`form[name=${formController.$name}]`);
                            formElem.attr('autocomplete', 'off');
                        },
                        100,
                    );
                }
                setCurrentForm(scope, formController);
            },

            setCurrentForm,

            // Adds support for formController dirtying
            supportDirtyState(scope, modelController) {
                scope.updateDirty = () => {
                    if (!modelController) {
                        return;
                    }
                    modelController.$setDirty();
                };
            },

            // Adds support for generic collection-size validation
            // Dependencies: scope.min / scope.max / scope.ngModel values
            supportCollectionSizeValidation(scope, attrs, modelController) {
                scope.updateValidity = forcedState => {
                    if (!modelController) {
                        return;
                    }

                    if (angular.isDefined(forcedState)) {
                        modelController.$setValidity('required-range', forcedState);
                        return;
                    }

                    const isRequired = attrs.required;

                    const isValid =
                        !isRequired ||
                        (scope.ngModel &&
                            scope.ngModel.length >= scope.min &&
                            (!scope.max || scope.ngModel.length <= scope.max));

                    modelController.$setValidity('required-range', !!isValid); // ensure we don't pass undefined anyhow
                };

                // set initial model state
                scope.updateValidity();

                // handle destroys
                supportDestroyValidity(scope);

                // handle ng-required validation
                supportsObservableRequired(scope, attrs);
            },

            // Adds support for nullable (current) date validation
            // Dependencies: scope.ngModel / ?scope.allowCurrent values
            supportNullableDateValidation(scope, attrs, modelController) {
                scope.updateValidity = forcedState => {
                    if (!modelController) {
                        return;
                    }

                    if (angular.isDefined(forcedState)) {
                        modelController.$setValidity('required', forcedState);
                        return;
                    }

                    const isRequired = attrs.required; // null = current
                    const isValid = !isRequired || angular.isDefined(scope.ngModel);

                    modelController.$setValidity('required', !!isValid); // ensure we don't pass undefined anyhow
                };

                // set initial model state
                scope.updateValidity();

                // handle destroys
                supportDestroyValidity(scope);

                // handle ng-required validation
                supportsObservableRequired(scope, attrs);
            },

            // Adds support for re-oderable list (ngModel) operations
            supportsOrderableItems(scope) {
                scope.canMove = (currentIndex, movement) => {
                    const collection = scope.ngModel;
                    const desiredIndex = currentIndex + movement;
                    return collection && desiredIndex > -1 && desiredIndex < collection.length;
                };

                scope.moveOne = (currentIndex, movement) => {
                    const newIndex = currentIndex + movement;
                    const collection = scope.ngModel;

                    if (Math.abs(movement) > 1) {
                        throw new Error('This function only allows movement of one');
                    }

                    // return if trying to move the item outside the collection bounds
                    if (newIndex > collection.length - 1 || newIndex < 0) {
                        return;
                    }

                    // get the item at the position where the moving item will be placed
                    const item = collection[newIndex];

                    // move the item
                    collection[newIndex] = collection[currentIndex];

                    // replace the original item at this spot
                    collection[currentIndex] = item;

                    scope.updateDirty();
                };
            },

            // Adds support for any sort of arbitrary ngModel interactions
            supportsFauxInput(scope, attrs, modelController) {
                scope.updateValidity = forcedState => {
                    if (!modelController) {
                        return;
                    }

                    if (angular.isDefined(forcedState)) {
                        modelController.$setValidity('required', forcedState);
                        return;
                    }

                    const isRequired = attrs.required;
                    const requiredValid = !isRequired || scope.ngModel;
                    let maxlengthValid = true;

                    if (attrs.maxlength) {
                        const maxlength = parseInt(attrs.maxlength, 10);
                        maxlengthValid = !scope.ngModel || scope.ngModel.length <= maxlength;
                    }

                    const isValid = !!(requiredValid && maxlengthValid);

                    modelController.$setValidity('required', isValid);
                };

                // set initial model state
                scope.updateValidity();

                // handle destroys
                supportDestroyValidity(scope);

                // handle ng-required validation
                supportsObservableRequired(scope, attrs);
            },

            supportAutoSuggestOptions(scope) {
                const $http = $injector.get('$http');

                scope.getOptionsForType = (
                    type,
                    searchText,
                    locale,
                    suggestFilters = { suggest: true },
                    limit = 10,
                ) => {
                    // trigram index won't even be able to extract without a minumum number of characters
                    if (searchText.length < 3) {
                        return $q.when([]);
                    }

                    const filters = {
                        type,
                        in_locale_or_en: locale || Locale.activeCode,
                        search_text: searchText,
                        ...suggestFilters,
                    };

                    // skipping iguana instantiation for perf reasons
                    return $http
                        .get(`${window.ENDPOINT_ROOT}/api/auto_suggest_options.json`, {
                            params: {
                                limit,
                                filters,
                            },
                            // using the JQLike serializer prevents encoding errors on ';' (and other chars?)
                            paramSerializer: '$httpParamSerializerJQLike',
                        })
                        .then(response => response.data.contents[`${type}_options`]);
                };
            },

            /*
                    Adds checkbox helper functions to the scope for handling operations on checkbox groups and creates faux
                    models for each checkbox input element to bind its respective ngModel to.
                    @param scope - The scope of the directive that needs to support checkboxes
                    @param baseModel - The object to bind checkbox values to (e.g. a career profile)
                            NOTE: The reference to the baseModel that's originally passed in is cached for further use inside
                            of this function, so don't blow away the baseModel reference after you've used this function.
                            If the baseModel reference gets reset, checkbox groups may fail to update the collection on the
                            new baseModel reference. This may result in behavior where the UI will continue to update, but
                            the underlying collection attached to the baseModel won't update properly. See https://trello.com/c/IHdL8dsS
                    @param propsValuesMap - An object whose properties are the property names on the baseModel that need
                        to support checkboxes. Each property in propsValuesMap should be mapped to an array containing the
                        property's respective valid values.
                    @param propsRequiredFieldsMap - An object whose properties are the property names on the baseModel that
                        require a minimum number of checkboxes in their respective group to be checked. Each property in
                        propsRequiredFieldsMap should be mapped to an object that has a 'min' property set to the number of
                        minimum required values.
                    @param propsDefaultValuesMap - An object whose properties are the property names on the baseModel that
                        support a default value upon initialization if the baseModel property is empty. Each property in
                        propsDefaultValuesMap should be mapped to its default value
                    @param propsDominantValuesMap - An object whose properties are the property names on the baseModel that
                        support a "dominant" value. Each property in propsDominantValuesMap should be mapped to its dominant
                        value. The dominant value is mutually exclusive from all other values in the checkbox group i.e. if
                        dominant value's checkbox becomes checked, all other checkboxes in the group become unchecked and
                        vice versa.
                */
            supportCheckboxGroups(
                scope,
                baseModel,
                propsValuesMap,
                propsRequiredFieldsMap,
                propsDefaultValuesMap,
                propsDominantValuesMap,
            ) {
                scope.checkboxGroupsFauxModels = {}; // this is put on the scope for easy access so each input has a valid faux model to bind its ngModel directive to
                baseModel = baseModel || {};
                propsRequiredFieldsMap = propsRequiredFieldsMap || {};
                propsDefaultValuesMap = propsDefaultValuesMap || {};
                propsDominantValuesMap = propsDominantValuesMap || {};

                _.forEach(propsValuesMap, (values, prop) => {
                    const defaultValue = propsDefaultValuesMap[prop];
                    // initialize the checkbox group with a default value if supplied and if the baseModel collection is empty
                    baseModel[prop] =
                        defaultValue && (!baseModel[prop] || baseModel[prop].length === 0)
                            ? [].concat(defaultValue)
                            : baseModel[prop];

                    // Create a faux model for each value so angular's ngModel directive can bind to it properly.
                    // If ngModel doesn't have a property to bind to, it won't trigger its ngDirty handling logic,
                    // which won't register the form as dirty.
                    scope.checkboxGroupsFauxModels[prop] = {};
                    _.forEach(values, value => {
                        scope.checkboxGroupsFauxModels[prop][value] = _.includes(baseModel[prop], value);
                    });
                });

                scope.checkboxIsChecked = (prop, value) => scope.checkboxGroupsFauxModels[prop][value];

                // The dominantValue param represents the value of the checkbox that, if checked, should take precedence over
                // all of the other checkboxes in the group. In other words, if the dominant checkbox the checkbox being toggled,
                // all other values in the array should be removed and the dominant checkbox value should be added as the sole
                // element in the collection. If the dominant value is included in the collection but a different checkbox in
                // the group was toggled, the dominant value gets removed from the collection and the other value gets added.
                scope.toggleCheckbox = (prop, value) => {
                    const collection = baseModel[prop];
                    const valueIndex = collection.indexOf(value);
                    const dominantValue = propsDominantValuesMap[prop];

                    if (dominantValue) {
                        const dominantCheckboxIsChecked = scope.checkboxIsChecked(prop, dominantValue);

                        // if the dominantValue's checkbox is in the checked state and a different
                        // checkbox in the group was checked, remove the dominantValue from the
                        // collection and ensure ifs faux model gets set to false
                        if (dominantCheckboxIsChecked && dominantValue !== value) {
                            const dominantValueIndex = collection.indexOf(dominantValue);
                            collection.splice(dominantValueIndex, 1);
                            scope.checkboxGroupsFauxModels[prop][dominantValue] = false;
                        }
                        // if the dominantValue checkbox was just checked, remove all of the elements
                        // in the collection in preparation for it being added as the only value and
                        // ensure that the faux models for the each non-dominant value for the property
                        else if (dominantValue === value) {
                            collection.splice(0, collection.length);
                            _.forEach(propsValuesMap[prop], val => {
                                if (val !== dominantValue) {
                                    scope.checkboxGroupsFauxModels[prop][val] = false;
                                }
                            });
                        }
                    }

                    // if the value checkbox was checked, add it to the collection
                    // and update the faux model to true
                    if (valueIndex < 0) {
                        collection.push(value);
                        scope.checkboxGroupsFauxModels[prop][value] = true;
                    }
                    // otherwise, remove it from the collection and update the faux
                    // model to false
                    else {
                        collection.splice(valueIndex, 1);
                        scope.checkboxGroupsFauxModels[prop][value] = false;
                    }
                };

                scope.checkboxRequired = prop => {
                    const collection = baseModel[prop];
                    const min = propsRequiredFieldsMap[prop] && propsRequiredFieldsMap[prop].min;
                    return min ? !(collection && collection.length >= min) : false;
                };

                scope.resetCheckboxSelections = prop => {
                    const collection = baseModel[prop];

                    Object.keys(scope.checkboxGroupsFauxModels[prop]).forEach(key => {
                        // Remove from baseModel[prop]
                        const keyIndex = collection.indexOf(key);
                        collection.splice(keyIndex, 1);

                        // Set the corresponding value in the faux model to false.
                        scope.checkboxGroupsFauxModels[prop][key] = false;
                    });
                };
            },

            // provides some sane selectize defaults - auto-blur, single-item, ready for normalized objects
            getSelectizeConfigDefaults() {
                return {
                    create: false,
                    maxItems: 1,
                    sortField: 'sort',
                    valueField: 'value',
                    labelField: 'label',
                    searchField: 'label',
                    onItemAdd() {
                        this.blur();
                    },
                };
            },

            /*
                    This is useful for when you are trying to create a form where the user
                    does not see validation styling until after they click the submit button,
                    AND you are not using multi-step-container.

                    * In your link() function, call FormHelper.supportForm(scope.myForm);
                    * In you submit button, add ng-click="validateBeforeSubmit($event)"
                    * In your form, add ng-submit="onSubmit()"
                */
            validateBeforeSubmit(form, $event) {
                form.$setSubmitted(true);

                if (!form.$valid) {
                    $event.preventDefault(); // do not submit the form
                    return $q.reject('invalid');
                }
                return $q.when();
            },

            isNonEnglish() {
                return Locale.preferredCode !== 'en';
            },

            // see https://github.com/quanticedu/back_royal/pull/9264#discussion_r827310956
            invalidLanguage(text) {
                // \u4E00-\u9FA5 - Chinese, \uFE30-\uFFA0 - Chinese symbols
                const reg = /[\u4E00-\u9FA5\uFE30-\uFFA0]/g;
                return text && reg.test(text) && text.match(reg).length > text.length / 2;
            },
        };
    },
]);
