
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.logged_out = window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.logged_out || {};
window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.logged_out = {...window.Smartly.locales.modules.en.back_royal.front_royal_api_error_handler.logged_out, ...{
    "login_with_saml": "You have been logged out. <a ng-click=\"loginWithSaml()\">Click here</a> to log in again"
}
}
    