import angularModule from 'Feedback/angularModule/scripts/feedback_module';
import template from 'Feedback/angularModule/views/feedback_sidebar.html';
import cacheAngularTemplate from 'cacheAngularTemplate';
import { setupBrandScopeProperties } from 'AppBranding';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('feedbackSidebar', [
    '$injector',

    function factory($injector) {
        const FeedbackModal = $injector.get('FeedbackModal');
        const $rootScope = $injector.get('$rootScope');
        const offlineModeManager = $injector.get('offlineModeManager');

        return {
            scope: {},
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                setupBrandScopeProperties($injector, scope, ['supportsFeedbackSidebar', 'brandStyleClass']);

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'hideSidebar', {
                    get() {
                        return (
                            !scope.currentUser ||
                            !scope.supportsFeedbackSidebar ||
                            scope.currentUser.isNotJoiningProgramOrHasBeenExpelled ||
                            offlineModeManager.inOfflineMode
                        );
                    },
                });

                scope.launchFeedback = () => {
                    FeedbackModal.launchFeedback();
                };
            },
        };
    },
]);
